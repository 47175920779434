<template>
  <VueMultiselect track-by="uuid"
                  label="name"
                  placeholder="Select team or group"
                  selected-label=""
                  select-label=""
                  deselect-label=""
                  v-model="group"
                  :allowEmpty="allowEmpty"
                  :options="groups"
                  :loading="loading || showLoader"
                  :disabled="loading || showLoader"
                  :multiple="multiple"
                  @remove="onRemove"
                  @select="onSelect">
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.name }} </span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import { ref } from 'vue'
import VueMultiselect from 'vue-multiselect'
import {useGroupStore} from '@/stores/groups'
export default {
  name: 'groups-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: [Object, Array]
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    excludedGroup: {
      type: Object
    },
    showLoader: {
      type: Boolean,
      default: false
    },
  },

  setup () {
    const store = useGroupStore()
    const loading = ref(false)
    const getList = () => {
      loading.value = true
      store.getGroups().then(() => {
        loading.value = false
      })
    }

    return {
      store,
      loading,
      getList
    }
  },

  computed: {
    groups() {

      if (this.excludedGroup && this.excludedGroup.uuid) {
        return this.store.groups.filter(item => item.uuid !== this.excludedGroup.uuid)
      }


      return this.store.groups
    }
  },

  data () {
    return {
      group: this.value
    }
  },
  methods: {
    onSelect (data) {
      this.$nextTick(function(){
        this.$emit('onSelect', data)
      })
    },
    onRemove (option) {
      this.$emit('remove', option.uuid)
    }
  },

  mounted(){
    if (this.store.groups.length < 1) {
      this.getList()
    }
  },

  watch: {
    value: {
      deep: true,
      handler(){
        this.group = this.value
      }
    },

    group: {
      deep: true,
      handler(){
        this.$emit('change', this.group)
      }
    },
  }
}
</script>
