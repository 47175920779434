<template>
  <b-button variant="primary"
            type="submit"
            size="sm"
            :disabled="loading || !isLoggedIn"
            @click="handLogin">
    <v-icon left>mdi-email</v-icon>
    Login with email and password
  </b-button>
</template>

<script>
import {defineComponent} from 'vue'
import facebook from '@/plugins/helpers/facebook'
import {useAuthStore} from '@/stores/auth'
export default defineComponent({
  name: 'EmailLogin',

  setup () {
    const authStore = useAuthStore()

    return {
      authStore
    }
  },

  computed: {
    isLoggedIn () {
      return this.authStore.user && this.authStore.user.social_provider.provider !== null && this.authStore.user.social_provider.provider_id !== null
    }
  },

  data () {
    return {
      loading: false
    }
  },


  methods: {
    async handLogin() {
      this.$emit('login')
      return false;
    }
  }
})
</script>
