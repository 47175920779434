<template>
  <v-data-table
      loading-text="Please wait..."
      height="55vh"
      class="elevation-1"
      :show-select="false"
      :single-select="false"
      :loading="loading"
      :fixed-header="true"
      :headers="columns"
      :items="employees"
      :items-per-page="10"
      :hide-default-header="false"
      :sort-by="['last_name']"
      :sort-desc="[false, true]">
    <template v-slot:top>
      <div class="p-3">
        <b-row>
          <b-col sm="12" md="6"></b-col>
          <b-col sm="12"
                 md="6"
                 class="d-flex justify-content-end">

            <div class="w-50">
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <font-awesome-icon icon="search" />
                  </div>
                </div>
                <input placeholder="Search employee..."
                       type="text"
                       class="form-control"
                       v-model="searchText"
                       @input="onSearchInputChange"/>
              </div>
            </div>

            <div class="ml-2 dropdown-filter-wrapper"
                 v-if="showFilter">
              <b-dropdown toggle-class="p-0"
                          variant="primary"
                          ref="filterDropdown"
                          size="sm"
                          block
                          no-caret right
                          @show="isDropdownClosable = false"
                          @hide="handleHide">
                <div slot="button-content">
                  <div class="icon-wrapper icon-wrapper-alt rounded-circle ml-2">
                    <font-awesome-icon icon="filter" class="mr-2"/> Filter
                  </div>
                </div>
                <EmployeeFilter
                    @apply="onApply"
                    @reset="onReset"
                    @close="onClose"
                />
              </b-dropdown>
            </div>
            <b-button variant="outline-warning"
                      class="ml-2"
                      :disabled="!isFilterApplied || loading"
                      v-if="showFilter"
                      @click="onClear">
              Clear Filter
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-slot:item.name="{ item }">
      <AvatarComponent :employee="item"></AvatarComponent>
      <b-link :to="{ name: 'team-profile', params: { id: item.uuid } }">
        {{ item.last_name }}, {{ item.first_name }}
      </b-link>
    </template>
    <template v-slot:item.email="{ item }">
      {{ item.email }}
    </template>
    <template v-slot:item.phone="{ item }">
      {{ item.metafields.phone.value }}
    </template>
    <template v-slot:item.location="{ item }">
      <span v-html="locationResolver(item.locations)"></span>
    </template>
    <template v-slot:item.group="{ item }">
      <span v-html="groupResolver(item.groups)"></span>
    </template>
    <template v-if="showActionColumns"
              v-slot:item.actions="{ item }">
      <b-dropdown class="mx-1"
                  variant="white"
                  right
                  text="menu"
                  no-caret>
        <b-dropdown-item :to="{ name: 'team-profile', params: { id: item.uuid } }">
          <font-awesome-icon icon="eye" class="mr-2"/>
          View profile
        </b-dropdown-item>
        <b-dropdown-item @click="onStartChat(item)">
          <font-awesome-icon icon="comments" class="mr-2"/>
          Start a chat
        </b-dropdown-item>
        <b-dropdown-item
            @click="onRemove(item)">
          <font-awesome-icon icon="trash" class="mr-2"/>
          Remove employee
        </b-dropdown-item>
        <template #button-content>
          <font-awesome-icon icon="ellipsis-vertical"/>
        </template>
      </b-dropdown>
    </template>
  </v-data-table>
</template>

<script>
import _ from 'lodash'
import { team } from '@/api/v1'
import { useEmployeeStore } from '@/stores/employee'
import { useAuthStore } from '@/stores/auth'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import EmployeeFilter from '@/components/employee/Filter'
import AvatarComponent from '@/components/employee/AvatarComponent'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments } from '@fortawesome/free-solid-svg-icons'
library.add(faComments)
export default {
  name: 'EmployeeTable',

  components: {
    FontAwesomeIcon,
    EmployeeFilter,
    AvatarComponent
  },

  props: {
    showFilter: {
      type: Boolean,
      default: true
    },
    showActionColumns: {
      type: Boolean,
      default: true
    }
  },

  setup(){
    const store = useEmployeeStore()
    const authStore = useAuthStore()
    return {
      store,
      authStore
    }
  },

  data: () => {
    return {
      loading: false,
      searchText: '',
      abortController : null,
      isDropdownClosable: true,
      employees: [],
      columns: [
        // {
        //   name: 'id',
        //   label: '#',
        //   sortable: false
        // },
        {
          name: 'name',
          text: 'Name',
          sortable: true,
          value: 'name'
        },
        {
          name: 'email',
          text: 'Email',
          sortable: true,
          value: 'email'
        },
        {
          name: 'phone',
          text: 'Phone #',
          sortable: false,
          value: 'phone'
        },
        {
          text: 'Location',
          sortable: false,
          value: 'location'
        },
        {
          text: 'Team',
          sortable: false,
          value: 'group'
        },
        {
          value: 'actions',
          text: '',
          sortable: false
        }
      ],
      isFilterApplied: false,
    }
  },

  methods: {
    loadList() {
      this.loading = true
      this.store.getEmployees(this.abortController.signal).then(() => {
        this.loading = false
      })
    },
    onSearchInputChange: _.debounce(function () {
      this.employees = this.store.employees.filter(item =>
        item.first_name.toLowerCase().includes(this.searchText.toLowerCase()) || item.last_name.toLowerCase().includes(this.searchText.toLowerCase()) )
    }, 1000),
    onApply(filter) {
      this.loading = true
      this.isFilterApplied = true
      this.onClose()
      this.store.listParams.location = filter.location ? filter.location.uuid : null
      this.store.listParams.skill = filter.skill ? filter.skill.uuid : null
      this.store.listParams.group = filter.group ? filter.group.uuid : null
      this.store.listParams.created = filter.created
      this.store.employees = []
      this.store.getEmployees(this.abortController.signal).then(() => {
        this.loading = false
      })
    },
    onClear() {
      this.loading = true
      this.store.filters.current = _.cloneDeep(this.store.filters.default)
      this.store.listParams = { ...this.store.listParams, ...this.store.filters.default }
      this.store.employees = []
      this.store.getEmployees(this.abortController.signal).then(res => {
        this.loading = false
      })
    },
    onClose() {
      this.isDropdownClosable = true
      this.$refs.filterDropdown.hide()
    },
    onReset() {
      if (this.isFilterApplied) {
        this.isFilterApplied = false
      }
    },
    handleHide(event) {
      if (!this.isDropdownClosable) {
        event.preventDefault()
      }
    },
    getImportTemplate () {
      team.getImportTemplate()
    },
    locationResolver(locations) {
      let h = ''
      if (locations.length > 0) {
        locations.forEach((item) => {
          h+= '<span class="badge badge-primary mr-2">'+ item.name +'</span>'
        })
      }

      return h
    },
    groupResolver(data) {
      let h = ''
      if (data.length > 0) {
        data.forEach((item) => {
          h+= '<span class="badge badge-primary mr-2">'+ item.name +'</span>'
        })
      }

      return h
    },
    async onRemove (employee) {
      const confirm = await this.$dialog.confirm({ text: 'Do you wish to remove this employee?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.showOverlay = true
        this.store.deleteEmployee(employee.uuid).then(res => {
          if (res) {
            if (res.data.status === 'success') {
              this.store.employees = res.data.users
              this.$toast.success('Employee removed successfully.')
            } else {
              this.$toast.error(res.data.message)
            }
          }
        }).catch(err => {
          this.$toast.error(err && err.message ? err.message : 'Something went wrong.')
        }).finally(() => {
          this.showOverlay = false
        })
      }
    },
    onStartChat (employee) {
      this.store.employee = employee
      this.$router.push({ name: 'new-user-assessment', params: { id: employee.uuid } })
    }
  },
  watch: {
    'store.employees': {
      deep: true,
      handler: function() {
        this.employees = this.store.employees
      }
    }
  },

  mounted() {
    this.abortController = new AbortController()
    if (this.store.employees.length < 1 || this.store.created) {
      this.loadList()
    } else {
      this.employees = this.store.employees
    }
  }
}
</script>

<style lang="scss">
.dropdown-filter-wrapper {
  margin-top: 0;
  height: 10px;

  .icon-wrapper {
    width: 70px;
    height: 36px !important;
  }

  .dropdown-menu {
    min-width: 25rem !important;
  }
}
</style>
