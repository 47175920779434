<template>
  <div>
    <b-row>
      <b-col sm="12"
             md="3"
             class="text-center"
             v-for="(goal) in formattedGoals"
             :key="goal.uuid">
          <b-overlay :show="deletionId === goal.id"
                     rounded="sm">
            <DreamCard :goal="goal"
                       :showDetails="showDetails"
                       :showLike="showLike"
                       :showActionButtons="showActionButtons"
                       @select="onSelect"
                       @delete="onDelete"
                       @edit="onEdit" />
            <template #overlay>
              <b-spinner variant="primary" label="Spinning"></b-spinner>
            </template>
          </b-overlay>
      </b-col>
    </b-row>
    <ImageBox :images="formattedGoals"
              :index="index"
              :bgcolor="bgColor"
              @close="index = null"/>
  </div>
</template>

<script>
import ImageBox from 'vue-image-box'
import DreamCard from '@/components/dream-board/card-component'

export default {
  name: 'List',
  components: {
    ImageBox,
    DreamCard
  },
  props: {
    goals: {
      required: true
    },
    showLike: {
      type: Boolean,
      default: true
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },

  computed:{
    formattedGoals(){
      if (!this.goals) {
        return []
      }

      return this.goals.map(item => {
        return {
          ...item,
          imageUrl: item.media,
          thumbUrl: item.media,
          caption: item.description, user: this.employee ? this.employee : item.user }
      })
    }
  },

  data(){
    return {
      index: null,
      bgColor: 'rgba(51, 51, 51, .9)',
      loading: false,
      deletionId: null
    }
  },

  methods: {
    onSelect: function(id) {
      this.index = this.formattedGoals.findIndex(item => item.uuid === id)
    },
    onEdit (goal){
      this.$emit('edit', goal)
    },
    onDelete (goal) {
      this.$emit('delete', goal)
    }
  },
}
</script>