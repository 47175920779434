<template>
  <VueMultiselect track-by="id"
                  label="name"
                  placeholder="Select role"
                  selected-label=""
                  select-label=""
                  deselect-label=""
                  v-model="role"
                  :allowEmpty="false"
                  :options="store.roles"
                  :loading="store.isLoadingRoles"
                  :disabled="store.isLoadingRoles || disabled"
                  @select="onSelect">
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.name }} </span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import {useAuthStore} from '@/stores/auth'

export default {
  name: 'role-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: [Object, null]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const store = useAuthStore()

    return {
      store
    }
  },

  data () {
    return {
      role: this.value
    }
  },
  methods: {
    onSelect (data) {
      this.$emit('onSelect', data)
    }
  },
  mounted(){
    if (this.store.roles.length < 1) {
      this.store.getRoles()
    }
  },
  watch: {
    'value': {
      deep: true,
      handler: function(value){
        this.role = value
      }
    }
  }
}
</script>
