<template>
    <VueMultiselect track-by="id"
                    label="first_name"
                    placeholder="Select an employee"
                    selected-label=""
                    select-label=""
                    deselect-label="x"
                    v-model="employee"
                    :loading="store.fetchingEmployees"
                    :disabled="store.fetchingEmployees || isDisabled"
                    :options="employees"
                    @remove="onRemove"
                    @select="onSelect">
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.first_name }} {{ props.option.last_name }}</span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.first_name }} {{ props.option.last_name }}</span>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { useEmployeeStore } from '@/stores/employee'
import {useAuthStore} from '@/stores/auth'

export default {
  name: 'employee-selector',
  components: { VueMultiselect },

  props:{
    excludeCurrentUser: {
      type: Boolean,
      default: false
    },
    employeeId:{
      type: [String, null],
      default: null
    }
  },

  setup () {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()
    return {
      store: employeeStore,
      auth: authStore
    }
  },

  data () {
    return {
      employee: null,
      employees: [],
      isDisabled: false
    }
  },
  methods: {
    onSelect (employee) {
      this.$emit('onSelect', employee)
    },
    onRemove () {
      this.$emit('onRemove')
    },
    setSelected() {
      this.isDisabled = false
      if (this.employeeId) {
        this.employee = this.store.employees.find(data => data.uuid === this.employeeId)
        this.isDisabled = true
      }
    }
  },
  mounted() {
    if (this.store.employees.length < 1) {
      this.store.listParams.exclude_current_user = this.excludeCurrentUser
      this.store.getEmployees().then(() => {
        this.employees = this.store.employees
        this.setSelected()
      })
    } else {
      if (this.excludeCurrentUser) {
        this.employees = this.store.employees.filter(item => item.uuid !== this.auth.user.uuid)
      } else {
        this.employees = this.store.employees
      }

      this.setSelected()
    }
  }
}
</script>
