import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useJobRoleStore = defineStore({
  id: 'jobRole',
  persist: false,
  state: () => ({
    role: null,
    roles: [],
    loading: false,
    creating: false,
    fetching: false,
    reset: false
  }),
  getters:{

  },
  actions: {
    getRoles (params, signal) {
      this.fetching = true
      return tenant.team.jobRoles.list(params, signal).then(res => {
        this.roles = res.data
        this.fetching = false
        return res
      }).catch(err => {
        this.fetching = false
        console.log(err)
        return null
      })
    },
    create (params, signal) {
      return tenant.team.jobRoles.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    massCreate (params, signal) {
      return tenant.team.jobRoles.massStore(params, signal)
    },
    update (id, params, signal) {
      return tenant.team.jobRoles.update(id, params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
  }
})
