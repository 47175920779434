<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  class="text-white mr-2"
                  size="sm"
                  @click="showFormDialog = true">
          <font-awesome-icon class="mr-2" icon="plus" />Add Goal
        </b-button>

        <b-button type="button"
                  variant="primary"
                  class="text-white"
                  size="sm"
                  @click="showQuarterlyFormDialog = true">
          <font-awesome-icon class="mr-2" icon="plus" />Add Quarterly Goal
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
        <div class="app-inner-layout__content">

          <v-data-table
              loading-text="Loading... Please wait"
              height="55vh"
              class="elevation-1"
              :loading="loading"
              :fixed-header="true"
              :headers="columns"
              :items="store.objectives"
              :items-per-page="10"
              :hide-default-header="false"
              :sort-by="['title']"
              :sort-desc="[false, true]">
            <template v-slot:top>
              <div class="p-3">
                <b-row>
                  <b-col sm="12" md="6"></b-col>
                  <b-col sm="12"
                         md="6"
                         class="d-flex justify-content-end">

                    <div class="w-50">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <font-awesome-icon icon="search" />
                          </div>
                        </div>
                        <input placeholder="Search goal..."
                               type="text"
                               class="form-control"
                               v-model="searchText"
                               @input="onSearch"/>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:item.title="{ item }">
              {{ item.title }}
            </template>
            <template v-slot:item.description="{ item }">
              {{ item.description === 'null' ? '' : item.description }}
            </template>
            <template v-slot:item.duration="{ item }">
              {{ item.duration }} {{ typeResolver(item) }}
            </template>
            <template v-slot:item.start_date="{ item }">
              {{ item.start_date | moment('MMM DD, YYYY') }}
            </template>
            <template v-slot:item.end_date="{ item }">
              {{ item.end_date | moment('MMM DD, YYYY') }}
            </template>
            <template v-slot:item.component="{ item }">
              {{ item.component ? item.component.name: 'Company Wide'  }}
            </template>
            <template v-slot:item.actions="{ item }">
              <b-dropdown class="mx-1"
                          variant="white"
                          right
                          text="menu"
                          no-caret>
                <b-dropdown-item @click="onEdit(item)">
                  <font-awesome-icon icon="pencil" class="mr-2"/>
                  Edit
                </b-dropdown-item>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </template>
              </b-dropdown>
            </template>
          </v-data-table>
          <b-modal :title="(selectedGoal ? 'Edit' : 'Add') + ' Goal'"
                   hide-footer
                   hide-header-close
                   no-close-on-esc
                   no-close-on-backdrop
                   v-model="showFormDialog"
                   @hide="showFormDialog = false; selectedGoal = null;">
            <GoalEntryForm v-model="selectedGoal"
                           @onCancel="showFormDialog = false"
                           @onUpdated="onUpdated"
                           @onCreated="onCreated" />
          </b-modal>

          <b-modal :title="(selectedGoal ? 'Edit' : 'Add') + ' Quarterly Goal'"
                   size="xl"
                   hide-footer
                   hide-header-close
                   no-close-on-esc
                   no-close-on-backdrop
                   v-model="showQuarterlyFormDialog"
                   @hide="showQuarterlyFormDialog = false; selectedGoal = null;">
            <QuarterlyGoalEntryForm v-model="selectedGoal"
                           @onCancel="showQuarterlyFormDialog = false"
                           @created="onQuarterlyGoalsCreated" />
          </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faPlus, faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faStar, faPlus,faEllipsisV, faPencil)

import GoalEntryForm from '@/components/goals/GoalEntryForm'
import QuarterlyGoalEntryForm from '@/components/goals/QuarterlyGoalEntryForm'
import {useObjectivesStore} from '@/stores/objectives'
import _ from 'lodash'

import * as GoalTypes from '@/constants/goal-types'

export default {
  name: 'List',
  components: {
    FontAwesomeIcon,
    PageTitle,
    GoalEntryForm,
    QuarterlyGoalEntryForm
  },
  setup(){
    const store = useObjectivesStore()

    return {
      store
    }
  },
  data () {
    return {
      GoalTypes,
      heading: 'Goals',
      subheading:
          'Team and company goals.',
      icon: 'pe-7s-date icon-gradient bg-tempting-azure',
      columns: [
        // {
        //   name: 'id',
        //   label: '#',
        //   sortable: false
        // },
        {
          value: 'title',
          text: 'Title',
          sortable: false
        },
        {
          value: 'description',
          text: 'Description',
          sortable: false
        },
        {
          value: 'duration',
          text: 'Duration/Type',
          sortable: false
        },
        {
          value: 'start_date',
          text: 'Start Date',
          sortable: false
        },
        {
          value: 'end_date',
          text: 'End Date',
          sortable: false
        },
        {
          value: 'component',
          text: 'Team',
          sortable: false
        },
        {
          value: 'actions',
          text: '',
          sortable: false
        }
      ],
      loading: false,
      isLoadingMore: false,
      showFormDialog: false,
      showQuarterlyFormDialog: false,
      selectedGoal: null,
      searchText: null
    }
  },
  methods: {
    typeResolver(data) {
      switch (true) {
      case parseInt(data.type) === GoalTypes.WEEKLY:
        return 'Weeks'
      case parseInt(data.type) === GoalTypes.MONTHLY:
        return 'Months'
      case parseInt(data.type) === GoalTypes.QUARTERLY:
        return 'Quarter ('+ data.data.label +')'
      case parseInt(data.type) === GoalTypes.YEARLY:
        return 'Years'
      case parseInt(data.type) === GoalTypes.DAILY:
      default:
        return 'Days'
      }
    },
    onCreated(data){
      this.showFormDialog = false
      this.store.objectives.push(data)
    },

    onQuarterlyGoalsCreated(data){
      this.showQuarterlyFormDialog = false
      this.store.objectives = [...this.store.objectives, ...data]
    },
    onUpdated(data){
      const index = this.store.objectives.findIndex(item => item.uuid === data.uuid)
      if (index >= 0) {
        this.store.objectives[index] = data
      }
      this.showFormDialog = false
    },
    onEdit(data){
      this.selectedGoal = data
      this.showFormDialog = true
    },
    onSearch: _.debounce(function() {
      this.loading = true
      this.store.objectives = []
      this.store.getList({ search_text: this.searchText }).then(() => {
        this.loading = false
      })
    }, 1000)
  },

  mounted () {
    this.loading = true
    this.store.objectives = []
    this.store.getList().then(() => {
      this.loading = false
    })
  }
}
</script>
