<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon"></page-title>


    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-row>
          <b-col sm="12" md="4">
            <div class="main-card mb-3 card">
              <div class="card-body">
                  <DreamBoardForm :employee="employeeStore.employee"></DreamBoardForm>
              </div>
            </div>
          </b-col>

          <b-col sm="12" md="8">
            <div class="main-card mb-3 card">
              <div class="card-body">
                <GoalsList v-if="goals.length > 0"
                           :show-like="false"
                           :goals="goals"/>
                <div class="text-center"
                     v-else>
                  <span>You don't have anything in your dream board yet.</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import DreamBoardForm from '@/components/dream-board/Form'
import GoalsList from '@/components/dream-board/List'
import {useEmployeeStore} from '@/stores/employee'

export default {
  name: 'DreamBoard',

  components: {
    PageTitle,
    DreamBoardForm,
    GoalsList
  },

  setup(){
    const employeeStore = useEmployeeStore()

    return {
      employeeStore
    }
  },

  computed: {
    goals(){
      if (!this.employeeStore.employee.goals) {
        return []
      }

      return this.employeeStore.employee.goals.map(item => {
        return {...item, imageUrl: item.media, thumbUrl: item.media, caption: item.description, user: this.employee }
      })
    }
  },

  data: () => ({
    heading: 'Your Dream Board',
    subheading:
        'This is your dream or goals board.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    loading: false,
    searchText: '',
    abortController : null,
  }),

  mounted(){
    if (!this.employeeStore.employee) {
      this.employeeStore.getEmployee(this.$route.params.id)
    }
  }
}
</script>
