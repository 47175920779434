<template>
  <div class="main-card mb-3 card">
    <div class="card-header d-flex justify-content-between bg-white">
      <span>Birthdays</span>
    </div>
    <b-overlay :show="loading"
               rounded="sm">
      <div class="card-body">
        <VuePerfectScrollbar class="scrollbar-container">
          <div class="birthday-scroll-area">
            <div v-if="store.previousBirthdays.length > 0">
              <h6 class="text-muted text-uppercase font-size-md font-weight-normal">Previous</h6>
              <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                <li class="list-group-item"
                    v-for="birthday in store.previousBirthdays"
                    :key="birthday.user.uuid">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <AvatarComponent :employee="birthday.user"></AvatarComponent>
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{ birthday.user.first_name }} {{ birthday.user.last_name }}</div>
                        <div class="widget-subheading">{{ getFormattedDate(birthday.values) }}</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div v-if="store.todaysBirthdays.length > 0">
              <h6 class="text-muted text-uppercase font-size-md font-weight-normal">Today</h6>
              <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                <li class="list-group-item"
                    v-for="birthday in store.todaysBirthdays"
                    :key="birthday.user.uuid">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <AvatarComponent :employee="birthday.user"></AvatarComponent>
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{ birthday.user.first_name }} {{ birthday.user.last_name }}</div>
                        <div class="widget-subheading">{{ getFormattedDate(birthday.values) }}</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div v-if="store.upcomingBirthdays.length > 0">
              <h6 class="text-muted text-uppercase font-size-md font-weight-normal">Upcoming</h6>
              <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                <li class="list-group-item"
                    v-for="birthday in store.upcomingBirthdays"
                    :key="birthday.user.uuid">
                  <div class="widget-content p-0">
                    <div class="widget-content-wrapper">
                      <div class="widget-content-left mr-3">
                        <AvatarComponent :employee="birthday.user"></AvatarComponent>
                      </div>
                      <div class="widget-content-left">
                        <div class="widget-heading">{{ birthday.user.first_name }} {{ birthday.user.last_name }}</div>
                        <div class="widget-subheading">{{ getFormattedDate(birthday.values) }}</div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div v-if="store.previousBirthdays.length < 1 && store.todaysBirthdays.length < 1 && store.upcomingBirthdays.length < 1 && !loading">
                Unable to find birthdays data..
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <br/>
          Fetching birthdays data...
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import AvatarComponent from '@/components/employee/AvatarComponent'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {useEmployeeStore} from '@/stores/employee'

export default {
  name: 'BirthdaysComponent',

  components: {
    AvatarComponent,
    VuePerfectScrollbar
  },

  setup(){
    const store = useEmployeeStore()

    return {
      store
    }
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    loadList() {
      this.loading = true
      this.store.birthdays = []
      this.store.getBirthdays().then(() => {
        this.loading = false
      })
    },

    getFormattedDate(date) {
      return window.moment(date).format('MMM DD')
    }
  },

  mounted() {
    //if (this.store.birthdays.length < 1) {
    this.loadList()
    //}
  }
}
</script>


<style lang="scss" scoped>
.birthday-scroll-area {
  height: auto;
  max-height: 400px;
  min-height: 40px;
}
</style>
