<template>
  <div>
    <b-row class="mb-4">
      <!-- text and button -->
      <b-col
          sm="12"
          order-sm="1"
          order="2"
          class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <div>
          <b-img v-if="goal.data && goal.data.image"
                 class="mb-2"
                 fluid
                 :src="goal.data.image"></b-img>
          <b-card-text class="font-weight-bold mb-5">
            {{ goal.title }} - {{ goal.duration + ' ' + typeResolver(goal) }}
          </b-card-text>
          <b-card-text class="font-small-2 mb-2">
            {{ goal.description !== 'null' ? goal.description : '' }}
          </b-card-text>
        </div>
      </b-col>
    </b-row>
    <!-- progress bar -->
    <b-row class="mt-2">
      <b-col
          cols="12"
          class="mb-2"
      >
        <div class="d-flex justify-content-between">
          <b-card-text class="mb-0 fsize-1">
            {{ goal.start_date | moment('MMM DD, YYYY') }}
          </b-card-text>
          <b-card-text class="mb-0 fsize-1">
            {{ goal.end_date | moment('MMM DD, YYYY') }}
          </b-card-text>
        </div>
        <b-progress :max="totalDaysCount">
          <b-progress-bar variant="primary" :value="usedDaysCount"></b-progress-bar>
          <b-progress-bar variant="danger" :value="remainingDaysCount" :label="`${remainingDaysCount} Days Left`"></b-progress-bar>
        </b-progress>
      </b-col>
<!--      <b-col-->
<!--          cols="12"-->
<!--          class="mb-2"-->
<!--      >-->
<!--        <b-card-text class="mb-0 fsize-1">-->
<!--          Progress-->
<!--        </b-card-text>-->
<!--        <b-progress-->
<!--            value="60"-->
<!--            max="100"-->
<!--            height="6px"-->
<!--            variant="warning"-->
<!--        />-->
<!--      </b-col>-->
    </b-row>
  </div>
</template>

<script>
import * as GoalTypes from '@/constants/goal-types'

export default {
  name: 'GoalsWidget',

  props: {
    goal: {
      type: Object,
      required: true
    }
  },

  computed:{
    totalDaysCount(){
      const start = window.moment(this.goal.start_date)
      const end = window.moment(this.goal.end_date)
      return end.diff(start,'days')
    },
    usedDaysCount(){
      const start = window.moment(this.goal.start_date)
      if (window.moment().isAfter(start)) {
        return window.moment().diff(start,'days')
      }

      return 0
    },
    remainingDaysCount(){
      return this.totalDaysCount - this.usedDaysCount
    }
  },
  methods: {
    typeResolver(data) {
      switch (true) {
      case parseInt(data.type) === GoalTypes.WEEKLY:
        return 'Weeks'
      case parseInt(data.type) === GoalTypes.MONTHLY:
        return 'Months'
      case parseInt(data.type) === GoalTypes.QUARTERLY:
        return 'Quarter ('+ data.data.label +')'
      case parseInt(data.type) === GoalTypes.YEARLY:
        return 'Years'
      case parseInt(data.type) === GoalTypes.DAILY:
      default:
        return 'Days'
      }
    }
  }
}
</script>
