<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  class="text-white"
                  size="sm"
                  @click="$router.push({ name: 'assessment' })">
          <font-awesome-icon class="mr-2" icon="arrow-left" />Back
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-overlay class="table-more-rows-spinner text-center mt-10"
                   :show="isLoading"
                   rounded="sm">
          <b-row class="text-left">
            <b-col md="3">
              <div class="main-card mb-3 card"
                   v-if="assessment">
                <div class="card-body">
                  <b-form-group label="Title">
                    <p>{{ assessment.title }}</p>
                  </b-form-group>
                  <b-form-group label="Chat for">
                    <p>{{ assessment.assessment_for.first_name }} {{ assessment.assessment_for.last_name }}</p>
                  </b-form-group>

                  <b-form-group label="Chat by">
                    <p class="mb-0">{{ assessment.assessment_from.first_name }} {{ assessment.assessment_from.last_name }}</p>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <p class="mt-0">
                          <small
                              class="text-muted"
                              v-bind="attrs"
                              v-on="on">
                            {{ assessment.created_at | dateTimePassed }}
                          </small>
                        </p>
                      </template>
                      <small>{{ assessment.created_at | fixRelativeDatetimeFormat }}</small>
                    </v-tooltip>
                  </b-form-group>

                  <b-form-group label="Template">
                    <p>{{ assessment.template.name }}</p>
                  </b-form-group>
                </div>
              </div>
            </b-col>
            <b-col md="9">
              <div class="main-card mb-3 card"
                   v-if="assessment">
                <div class="card-body" v-for="question in questions" :key="question.id">
                  <p class="font-weight-bold">{{ question.item }}</p>
                  <p class="mb-0 font-italic">{{ question.answer }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
          <template #overlay>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <br/>
            Fetching chat data...
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import {useAssessmentStore} from '@/stores/assessment'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import {dateTimePassed, fixRelativeDatetimeFormat} from '@/plugins/filters/datetime.filters'

library.add(faArrowLeft)
export default {
  name: 'AssessmentDetails',
  methods: {fixRelativeDatetimeFormat, dateTimePassed},

  components: {
    PageTitle,
    FontAwesomeIcon
  },

  computed: {
    questions () {
      if (!this.assessment) {
        return []
      }

      return JSON.parse(this.assessment.data)
    }
  },

  setup () {
    const assessmentStore = useAssessmentStore()

    return {
      assessmentStore
    }
  },

  data: () => ({
    heading: 'Chat',
    subheading: 'Chat conversations with colleagues or team mates.',
    icon: 'pe-7s-chat icon-gradient bg-mixed-hopes',

    isMobileOpen: false,
    columns: [
      {
        name: 'id',
        label: '#',
        sortable: false
      },
      {
        name: 'chat_by',
        label: 'Chat by',
        sortable: false
      },
      {
        name: 'chat_to',
        label: 'Chat to',
        sortable: false
      },
      {
        name: 'conducted',
        label: 'Conducted',
        sortable: false
      }
    ],
    row: 10,
    assessment: null,
    isLoading: false,
  }),
  mounted () {
    this.isLoading = true
    this.assessmentStore.getAssessment(this.$route.params.id).then(res => {
      this.assessment = res.data
      this.isLoading = false
    })
  }
}
</script>
