<template>
  <div class="mb-3 card text-white card-body bg-warning" v-if="store.todaysBirthdays.length > 0">
    <div class="text-center">
      <FontAwesomeIcon icon="cake-candles" class="fa-5x"></FontAwesomeIcon>
      <div v-if="isLoggedInUsersBirthday">
        <p class="mt-3">
          Hey, it's your birthday.
          <br/>
          Wishing you the happiest and the brightest day ever!
        </p>

        <p class="mt-5"
           v-if="todaysOtherCelebrants.length > 0">
          We also have other people celebrating their birthday today!
        </p>
      </div>

      <p class="mt-3" v-else>Heads up! We got some birthday celebrants today.</p>


      <div class="d-flex justify-content-center"
           v-if="todaysOtherCelebrants.length > 0">
        <div class="widget-content p-0 mr-5"
             v-for="birthday in store.todaysBirthdays.filter(birthday => birthday.user.uuid !== authStore.user.uuid)"
             :key="birthday.user.uuid">
        <div class="widget-content-wrapper">
          <div class="widget-content-left mr-3">
            <AvatarComponent :employee="birthday.user"></AvatarComponent>
          </div>
          <div class="widget-content-left">
            <div class="widget-heading">{{ birthday.user.first_name }} {{ birthday.user.last_name }}</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCakeCandles
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useEmployeeStore} from '@/stores/employee'

library.add(faCakeCandles)

import AvatarComponent from '@/components/employee/AvatarComponent'
import {useAuthStore} from '@/stores/auth'
export default {
  name: 'TodaysBirthdayComponent',

  components: {
    FontAwesomeIcon,
    AvatarComponent
  },

  setup() {
    const store = useEmployeeStore()
    const authStore = useAuthStore()
    return {
      store,
      authStore
    }
  },

  computed: {
    isLoggedInUsersBirthday() {
      return this.store.todaysBirthdays.some(birthday => birthday.user.uuid === this.authStore.user.uuid)
    },
    todaysOtherCelebrants() {
      return this.store.todaysBirthdays.filter(birthday => birthday.user.uuid !== this.authStore.user.uuid)
    }
  },
}
</script>
