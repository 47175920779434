<template>
  <div>
    <div class="nav-justified">
        <div class="text-uppercase font-weight-bold ml-4 mr-4">Filter</div>
        <div class="scroll-gradient">
          <div class="scroll-area-lg">
            <VuePerfectScrollbar class="scrollbar-container">
              <div class="mt-1">
                <div>
                  <b-nav-item  v-b-toggle.chat-by class="text-left">Chat by</b-nav-item>
                  <b-collapse id="chat-by" class="mt-2" visible>
                    <b-card class="border-0 box-shadow-none" no-body>
                      <b-card-body class="pt-0">
                        <b-form-group>
                          <EmployeeSelector @onSelect="onChatBySelected"
                                            @onRemove="store.filters.current.chat_by = null" />
                        </b-form-group>
                      </b-card-body>
                    </b-card>
                  </b-collapse>
                </div>
                <hr class="mt-0 mb-0"/>
                <div>
                  <b-nav-item  v-b-toggle.chat-for class="text-left text--black" >Chat for</b-nav-item>
                  <b-collapse id="chat-for" class="mt-2" visible>
                  <b-card class="border-0 box-shadow-none" no-body>
                    <b-card-body class="pt-0">
                      <b-form-group>
                      <EmployeeSelector @onSelect="onChatForSelected"
                                        @onRemove="store.filters.current.chat_for = null" />
                      </b-form-group>
                    </b-card-body>
                  </b-card>
                </b-collapse>
                </div>
                <hr class="mt-0 mb-0"/>
                <b-nav-item  v-b-toggle.conducted_last class="text-left">Conducted last</b-nav-item>
                <b-collapse id="conducted_last" class="mt-2" visible>
                  <b-card class="border-0 box-shadow-none" no-body>
                    <b-card-body class="pt-0">
                      <DatePicker placeholder="Select date"
                                  style="width: 100%;"
                                  class="w-100"
                                  v-model="store.filters.current.conductedLast"
                                  format="YYYY-MM-DD"
                                  type="date"
                                  range
                                  clearable
                                  :editable="true"
                                  :disabled-date="(date) => date >= maxDate"
                                  :formatter="formatter"/>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </div>
            </VuePerfectScrollbar>
          </div>
        </div>
        <ul class="nav flex-column">
          <li class="nav-item-divider nav-item mt-0"></li>
          <li class="nav-item-btn nav-item d-flex justify-content-between">
            <div>
              <button class="btn-shadow btn-wid btn btn-success btn-sm mr-1"
                      :disabled="!store.filterHasChanges"
                      @click="onApply">Apply</button>
              <button class="btn-shadow btn-wid btn btn-outline-primary btn-sm mr-1"
                      :disabled="!store.filterHasChanges"
                      @click="onResetFilter">Reset</button>
            </div>

            <button class="btn-shadow btn-wide btn btn-outline-danger btn-sm"
                    @click="onClose">Close</button>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmployeeSelector from '@/components/selectors/employee-selector'
import DatePicker from 'vue2-datepicker'

import { library} from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faCog, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useEmployeeStore} from '@/stores/employee'
import {useAssessmentStore} from '@/stores/assessment'
import _ from 'lodash'

library.add(faArrowUp, faCog, faFilter)

export default {
  name: 'AssessmentFilter',
  components: {
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    'font-awesome-icon': FontAwesomeIcon,
    DatePicker,
    EmployeeSelector
  },
  setup(){
    const employeeStore = useEmployeeStore()
    const store = useAssessmentStore()

    return {
      employeeStore,
      store
    }
  },
  computed:{
    maxDate () {
      return window.moment()._d
    },
    isDisabled () {
      return this.isGettingEmployee
    },
    employees () {
      return this.employeeStore.employees
    }
  },
  data () {
    return {
      formatter: {
        stringify: (date) => {
          return date ? window.moment(date).format('LL') : ''
        },
      },
      isGettingEmployee: false,
      conductedDateRange: {
        start: window.moment()._d,
        end: window.moment()._d,
      }
    }
  },
  methods: {
    onApply(){
      this.$emit('apply', this.store.filters.current)
    },

    onChatBySelected(data){
      this.store.filters.current.chat_by = data|| null
    },
    onChatForSelected(data){
      this.store.filters.current.chat_for = data|| null
    },
    onClose () {
      this.$emit('close')
    },

    onResetFilter() {
      this.store.filters.current = _.cloneDeep(this.store.filters.default)
      this.$emit('reset')
    }
  }
}
</script>

