import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useGroupStore = defineStore({
  id: 'groups',
  persist: false,
  state: () => ({
    group: null,
    groups: [],
    fetching: false,
    fetchingOrgChart: false,
    chartData: []
  }),
  getters:{

  },
  actions: {
    deepInsert(target, data) {
      if (typeof target === 'object') {
        for (const key in target) {

          if (target['uuid'] === data['parent']['uuid']) {
            if (!target['children']) {
              target['children'] = []
            }
            target['children'].push(data)
            return false
          }
          this.deepInsert(target[key], data)
        }
      }
    },
    getGroups (params, signal) {
      this.fetching = true
      return tenant.team.groups.list(params, signal).then(res => {
        this.groups = res.data
        this.fetching = false
        return res
      }).catch(err => {
        console.log(err)
        this.fetching = false
        return null
      })
    },
    getOrgChartData (params, signal) {
      this.fetchingOrgChart = true
      return tenant.team.groups.getOrgChart(params, signal).then(res => {
        this.chartData = res.data.data
        this.fetchingOrgChart = false
        return res
      }).catch(err => {
        console.log(err)
        this.fetchingOrgChart = false
        return null
      })
    },
    update(id, params){
      return tenant.team.groups.update(id, params)
    },
    delete(id){
      return tenant.team.groups.delete(id)
    },
    massCreate(params) {
      return tenant.team.groups.massStore(params)
    }
  }
})
