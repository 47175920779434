import Vue from 'vue'
Vue.config.devtools = true
Vue.config.silent = true
import VueCompositionAPI from '@vue/composition-api'
import Router from 'vue-router'
import pinia from '@/stores'

import App from './App.vue'
import './boot/axios'
import './boot/moment'
import bootFilter from '@/boot/filters'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vuetify from './plugins/vuetify'
import Default from './components/layout/Main'

import VueToastr from 'vue-toastr'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import vueUnderscore from 'vue-underscore'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from 'vee-validate'

extend('url', {
  validate(value) {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
    }

    return false
  },
  message: 'This value must be a valid URL',
})

import en from 'vee-validate/dist/locale/en.json'
import * as rules from 'vee-validate/dist/rules'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import facebook from '@/plugins/helpers/facebook'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('en', en)

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
Vue.use(VueLodash, { lodash: lodash })

// Install components globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

import Dialog from 'bootstrap-vue-dialog'
import {useAuthStore} from '@/stores/auth'

import VueTree from '@ssthouse/vue-tree-chart'
Vue.component('vue-tree', VueTree)

import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})

// import VueProgressBar from 'vue-progressbar'
// Vue.use(VueProgressBar, {
//   color: 'rgb(45, 18, 233)',
//   failedColor: 'red',
//   height: '4px'
// })

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueCompositionAPI)
Vue.use(Router)
Vue.use(VueToastr)
Vue.use(Dialog)
Vue.use(vueUnderscore)

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true
})

Vue.use(require('vue-moment'))

Vue.component('default-layout', Default)
Vue.config.productionTip = false

bootFilter({ Vue })

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,

  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'app.myhermes.io', /^\//],
    }),
    new Sentry.Integrations.Breadcrumbs({ console: false })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
})

facebook.init().then(startApp)

function startApp () {
  new Vue({
    el: '#app',
    router,
    vuetify,
    render: h => h(App),
    pinia,
    // created () {
    //   const authStore = useAuthStore()
    //   const data = JSON.parse(localStorage.getItem('user'))
    //   if (data) {
    //     authStore.user = data
    //   }
    // }
  })
}
