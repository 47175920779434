import { defineStore } from 'pinia'
import {tenant} from '@/api/v1'
import Avatar from '@/assets/images/avatars/default.png'

export const useEmployeeStore = defineStore({
  id: 'employee',
  state: () => ({
    employee: null,
    employees: [],
    organization:[],
    jobRoles:[],
    listParams: {
      search_text: null,
      page: null,
      exclude_current_user: false,
      sort_by: 'last_name',
      sort_order: 'asc',
      relations: ['assessment', 'user_relations'],
      location: null,
      skill: null,
      job_role: null,
      group: null,
      created: null,
    },
    filters: {
      default: {
        search_text: null,
        exclude_current_user: false,
        location: null,
        skill: null,
        job_role: null,
        group: null,
        created: null,
      },
      current: {
        search_text: null,
        exclude_current_user: false,
        location: null,
        skill: null,
        job_role: null,
        group: null,
        created: null,
      }
    },
    goals: null,
    created: false,
    creating: false,
    fetchingEmployees: false,
    birthdays: [],
    paginationData: {
      page: 1,
      per_page: 15,
      total: 0
    }
  }),
  getters:{
    avatar(state) {
      if (!state.employee ||
        !state.employee.metafields ||
        !state.employee.metafields.profile_photo ||
        !state.employee.metafields.profile_photo.value) {
        return Avatar
      }

      return state.employee.metafields.profile_photo.value
    },
    filterHasChanges (state) {
      return JSON.stringify(state.filters.default) !== JSON.stringify(state.filters.current)
    },
    previousBirthdays(state) {
      const today = new Date()
      const currentYear = today.getFullYear()
      today.setHours(0,0,0,0)

      return state.birthdays
        .filter(item => {
          const bDate = new Date(item.values)
          bDate.setFullYear(currentYear)
          return bDate < today
        }).sort((a, b) => Number(new Date(a.values).setFullYear(currentYear)) - Number(new Date(b.values).setFullYear(currentYear)))
    },
    todaysBirthdays(state) {
      const currentYear = (new Date()).getFullYear()
      return state.birthdays.filter(item => {
        const bDate = new Date(item.values)
        bDate.setFullYear(currentYear)
        return bDate.toDateString() === (new Date()).toDateString()
      })
    },
    upcomingBirthdays(state) {
      const currentYear = (new Date()).getFullYear()
      return state.birthdays.filter(item => {
        const bDate = new Date(item.values)
        bDate.setFullYear(currentYear)
        return bDate > new Date()
      }).sort((a, b) => Number(new Date(a.values).setFullYear(currentYear)) - Number(new Date(b.values).setFullYear(currentYear)))
    }
  },
  actions: {
    setEmployee(employee) {
      this.employee = employee
    },
    getEmployee (id, params, signal) {
      return tenant.team.get(id,{ params: params, signal: signal }).then(res => {
        this.employee = res.data
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    storeEmployee (params, signal) {
      return tenant.team.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    massStoreEmployee (params, signal) {
      return tenant.team.massStore(params, signal)
    },
    updateEmployee (id, params, signal) {
      return tenant.team.update(id, params, signal).then(res => {
        this.employee = res.data
        return res
      }).catch(err => {
        console.log(err)
        this.$toast.error('An error was encountered while processing your request. Please try again.', {
          hideProgressBar: true,
          icon: false
        })
        return null
      })
    },
    deleteEmployee (id, params, signal) {
      return tenant.team.delete(id, params, signal)
    },
    uploadProfilePhoto (id, formData, signal) {
      return tenant.team.profilePhotoUpload(id, formData, signal).then(res => {
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateEmployees (employee) {
      const index = this.employees.findIndex(item => item.uuid === employee.uuid)
      if (index >= 0) {
        this.employees[index] = employee
      }
    },
    getEmployees (signal) {
      this.fetchingEmployees = true
      return tenant.team.list({ params: this.listParams, signal: signal }).then(res => {
        this.employees = res.data.data
        this.paginationData = res.data.meta
        this.fetchingEmployees = false
        return res
      }).catch(err => {
        console.log(err)
        this.fetchingEmployees = false
        return []
      })
    },
    getOrganization (params, signal) {
      return tenant.organization.get(params, signal).then(res => {
        this.organization = res.data
        return res
      }).catch(err => {
        console.log(err)
        return []
      })
    },
    storeGoal(formData, signal){
      return tenant.team.goals.store(formData, signal).then(res => {
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateGoal(id, formData, signal){
      return tenant.team.goals.update(id, formData, signal).then(res => {
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    getGoals () {
      return tenant.team.goals.list().then(res => {
        this.goals = res.data
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateRole(id, params){
      return tenant.team.updateRole(id, params)
    },
    updateSkills(id, params){
      return tenant.team.updateSkills(id, params)
    },
    updateJobRole(id, params){
      return tenant.team.updateJobRole(id, params)
    },
    updateLocation(id, params){
      return tenant.team.updateLocation(id, params)
    },
    updateTeams(id, params){
      return tenant.team.updateTeams(id, params)
    },
    updatePassword(id, params){
      return tenant.team.updatePassword(id, params)
    },
    getBirthdays() {
      return tenant.team.getBirthdays().then(res => {
        this.birthdays = res.data.birthdays
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    getAssessments (id, params, signal) {
      return tenant.team.assessments.get(id, params)
    },
  }
})
