<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon">
      <template slot="actions">
        <b-link type="button"
                class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white"
                :to="{ name: 'assessment-create-template' }">
          <font-awesome-icon class="mr-2" icon="plus" />New Template
        </b-link>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <v-data-table
            loading-text="Loading... Please wait"
            height="55vh"
            class="elevation-1"
            :loading="loading"
            :fixed-header="true"
            :headers="columns"
            :items="templates"
            :items-per-page="10"
            :hide-default-header="false"
            :sort-by="['last_name']"
            :sort-desc="[false, true]">
          <template v-slot:top>
            <div class="p-3">
              <b-row>
                <b-col sm="12" md="6"></b-col>
                <b-col sm="12"
                       md="6"
                       class="d-flex justify-content-end">

                  <div class="w-50">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <font-awesome-icon icon="search" />
                        </div>
                      </div>
                      <input placeholder="Search template..."
                             type="text"
                             class="form-control"
                             v-model="searchText"
                             :disabled="loading"
                             @input="onSearchInputChange"/>
                    </div>
                  </div>

                </b-col>
              </b-row>
            </div>
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ item.created_at | moment('MMM DD, YYYY') }}
          </template>

          <template v-slot:item.owner="{ item }">
            {{ item.owner.first_name}} {{ item.owner.last_name }}
          </template>

          <template v-slot:item.actions="{ item }">
            <b-dropdown class="mx-1"
                        variant="white"
                        right
                        text="menu"
                        no-caret>
              <b-dropdown-item @click="onEditTemplate(item)">
                <font-awesome-icon icon="pen-to-square" class="mr-2"/>
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click="onShowTemplate(item)">
                <font-awesome-icon icon="eye" class="mr-2"/>
                View
              </b-dropdown-item>
              <template #button-content>
                <font-awesome-icon icon="ellipsis-vertical"/>
              </template>
            </b-dropdown>
          </template>
        </v-data-table>

        <template-view-modal v-if="this.template"
                             :template="template"
                             :show="showViewModal"
                             @hide="onViewModalHide"/>
      </div>
    </div>
  </div>
</template>

<script>
import { useAssessmentStore } from '@/stores/assessment'
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faPlus, faEllipsisV, faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import TemplateViewModal from '@/components/assessment/TemplateViewModal'
import _ from 'lodash'

library.add(faStar, faPlus,faEllipsisV, faEye, faPenToSquare)
export default {
  name: 'Templates',
  components: {
    PageTitle,
    'font-awesome-icon': FontAwesomeIcon,
    TemplateViewModal
  },
  setup(){
    const store = useAssessmentStore()

    return {
      store
    }
  },
  data: () => ({
    heading: 'Templates',
    subheading:
        'Chat templates.',
    icon: 'pe-7s-comment icon-gradient bg-tempting-azure',
    columns: [
      // {
      //   name: 'checkbox',
      //   sortable: false
      // },
      // {
      //   name: 'id',
      //   label: '#',
      //   sortable: false
      // },
      {
        value: 'name',
        text: 'Name',
        sortable: false
      },
      {
        value: 'description',
        text: 'Description',
        sortable: false
      },
      {
        value: 'owner',
        text: 'Created By',
        sortable: false
      },
      {
        value: 'created_at',
        text: 'Date Created',
        sortable: false
      },
      {
        value: 'actions',
        text: '',
        sortable: false
      }
    ],
    templates: [],
    template: null,
    loading: false,
    showViewModal: false,
    searchText: null
  }),
  methods: {
    onEditTemplate(template) {
      this.store.template = template
      this.$router.push({ name: 'assessment-edit-template', params: { id: template.uuid } })
    },
    onShowTemplate(template) {
      this.template = template
      this.showViewModal = true
    },
    onViewModalHide() {
      this.showViewModal = false
    },
    onSearchInputChange: _.debounce(function (){
      this.templates = this.store.templates.filter(item =>
        item.name.toLowerCase().includes(this.searchText.toLowerCase()) || (item.description && item.description.toLowerCase().includes(this.searchText.toLowerCase())) )
    }, 1000),
  },
  created () {
    if (this.store.templates.length > 0) {
      this.templates = this.store.templates
      return
    }

    this.loading = true
    this.store.getTemplates().then(() => {
      this.templates = this.store.templates
      this.loading = false
    })
  }
}
</script>
