<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">
      <template slot="actions">
        <b-button size="sm"
                  type="button"
                  variant="primary"
                  class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white mr-2"
                  :disabled="loading || store.creating"
                  @click="onValidate">
          <font-awesome-icon
              class="mr-2"
              icon="check"
              v-if="!loading" />
          <b-spinner label="Loading..."
                     small
                     v-if="loading"></b-spinner>
          {{ loading ? ' Please wait...' : 'Save' }}
        </b-button>

        <b-link type="button"
                class="btn-shadow d-inline-flex align-items-center btn btn-danger text-white btn-sm"
                :disabled="loading || store.creating"
                @click="$router.go(-1)">
          <font-awesome-icon class="mr-2" icon="xmark" />Cancel
        </b-link>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-overlay class="table-more-rows-spinner mt-10"
                   :show="loading"
                   rounded="sm">
          <div class="row mt-4 mh-v-10">
            <div class="col-md-12">
              <b-card>
                <b-card-body>
                  <MassRegistrationForm ref="registrationForm"
                                        @onCreated="onCreated"></MassRegistrationForm>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <template #overlay>
            <div class="text-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
              <br/>
              Saving data...
            </div>
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import MassRegistrationForm from '@/components/employee/MassRegistrationForm'
import PageTitle from '@/components/layout/PageTitle'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useJobRoleStore} from '@/stores/jobRole'
import {useLocationStore} from '@/stores/location'
import {useAuthStore} from '@/stores/auth'
import {useGroupStore} from '@/stores/groups'
import {useEmployeeStore} from '@/stores/employee'

library.add(faPlus, faTimes)

export default {
  name: 'MassRegistration',

  components: {
    FontAwesomeIcon,
    PageTitle,
    MassRegistrationForm
  },

  setup(){
    const jobRoleStore = useJobRoleStore()
    const locationStore = useLocationStore()
    const authStore = useAuthStore()
    const groupStore = useGroupStore()
    const store = useEmployeeStore()
    return {
      jobRoleStore,
      locationStore,
      authStore,
      groupStore,
      store
    }
  },



  data: () => ({
    heading: 'Team',
    subheading:
        'Team mass registration.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    loading: false,
  }),

  methods: {
    async onValidate () {
      await this.$refs.registrationForm.onValidate()
    },
    onCreated() {
      this.$router.push({ name : 'team' })
    }
  }
}
</script>
