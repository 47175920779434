<template>
  <b-button variant="primary"
            type="submit"
            size="sm"
            :disabled="loading || isLoggedIn || disabled"
            @click="handLogin">
    <v-icon left>mdi-facebook</v-icon>
    {{ !isLoggedIn ? 'Login' : 'Connected' }} with Facebook
  </b-button>
</template>

<script>
import {defineComponent} from 'vue'
import facebook from '@/plugins/helpers/facebook'
import {useAuthStore} from '@/stores/auth'
import * as storage from '@/plugins/helpers/storage'

export default defineComponent({
  name: 'FacebookLogin',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const authStore = useAuthStore()

    return {
      authStore
    }
  },

  computed: {
    isLoggedIn () {
      return this.authStore.user && this.authStore.user.social_provider.provider === 'facebook' && this.authStore.user.social_provider.provider_id
    }
  },

  data () {
    return {
      loading: false
    }
  },

  methods: {
    async handLogin() {
      const _this = this
      window.FB.login(function(response) {
        if (response.status === 'connected') {
          _this.$emit('connected', response.authResponse)
        } else {
          console.log('error')
        }
      });
      return false;
    },
    getStatus () {
      window.FB.getLoginStatus(function(response) {
        console.log(response)
      });
    },
    getUserInfo () {
      const fbData = JSON.parse(storage.local.getItem('fbHermes'))
      const access_token = fbData?.accessToken
      if (access_token) {
        window.FB.api('/me', { access_token });
      }
    }
  }
})
</script>

<style scoped lang="scss">

</style>
