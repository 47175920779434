import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useLocationStore = defineStore({
  id: 'location',
  persist: false,
  state: () => ({
    location: null,
    locations: [],
    fetching: false,
    chartData: [],
    fetchingChartData: false
  }),
  getters:{

  },
  actions: {
    deepInsert(target, data){
      if (typeof target === 'object') {
        for (const key in target) {

          if (target['uuid'] === data['parent']['uuid']) {
            if (!target['children']) {
              target['children'] = []
            }
            target['children'].push(data)
            return false
          }
          this.deepInsert(target[key], data)
        }
      }
    },
    getList (params, signal) {
      this.fetching = true
      return tenant.team.location.list(params, signal).then(res => {
        this.locations = res.data
        return res
      }).catch(err => {
        console.log(err)
        return null
      }).then(() => {
        this.fetching = false
      })
    },
    create (params, signal) {
      return tenant.team.location.store(params, signal)
    },
    update (id, params, signal) {
      return tenant.team.location.update(id, params, signal)
    },
    delete (id, signal) {
      return tenant.team.location.delete(id, signal)
    },
    makeParent(id, signal) {
      return tenant.team.location.makeParent(id, signal)
    },
    getOrgChartData (params, signal) {
      this.fetchingOrgChart = true
      return tenant.team.location.getOrgChart(params, signal).then(res => {
        this.chartData = res.data.data
        this.fetchingOrgChart = false
        return res
      }).catch(err => {
        console.log(err)
        this.fetchingOrgChart = false
        return null
      })
    },
  }
})
