<template>
  <v-app id="benesApp">
    <div class="app-container app-theme-white">
      <IdleHandler></IdleHandler>
      <transition name="fade" mode="out-in" appear>
        <Header :headerbg="headerColor" />
      </transition>
      <transition name="fade" mode="out-in" appear>
        <Sidebar :sidebarbg="sidebarColor" />
      </transition>
      <div class="app-main__outer">
        <div class="app-main__inner">
          <router-view></router-view>
        </div>
        <transition name="fade" mode="out-in" appear>
          <Footer />
        </transition>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from '../layout/Header'
import Sidebar from '../layout/Sidebar'
import Footer from '../layout/Footer'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import store from '@/stores'
import { useAuthStore} from '@/stores/auth'
import IdleHandler from '@/components/IdleHandler'
import { loadProgressBar } from 'x-axios-progress-bar'
import 'x-axios-progress-bar/dist/nprogress.css'

library.add(faCog)
const default_layout = 'default'


export default {
  name: 'Main',
  components: {
    Header,
    Sidebar,
    Footer,
    // eslint-disable-next-line vue/no-unused-components
    'font-awesome-icon': FontAwesomeIcon,
    IdleHandler
  },
  setup () {
    const authStore = useAuthStore(store)
    return { authStore }
  },
  methods: {
    toggleConfig (className) {
      const el = document.body
      this.isConfigOpen = !this.isConfigOpen

      if (this.isConfigOpen) {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  },
  data () {
    return {
      headerColor: null,
      sidebarColor: null,

      isConfigOpen: false,

      colors: [
        'bg-primary text-lighter',
        'bg-secondary text-lighter',
        'bg-success text-lighter',
        'bg-info text-lighter',
        'bg-warning text-darker',
        'bg-danger text-lighter',
        'bg-light text-darker',
        'bg-dark text-lighter',
        'bg-focus text-lighter',
        'bg-alternate text-lighter',

        'bg-vicious-stance text-lighter',
        'bg-midnight-bloom text-lighter',
        'bg-night-sky text-lighter',
        'bg-slick-carbon text-lighter',
        'bg-asteroid text-lighter',
        'bg-royal text-lighter',
        'bg-warm-flame text-darker',
        'bg-night-fade text-darker',
        'bg-sunny-morning text-darker',
        'bg-tempting-azure text-darker',
        'bg-amy-crisp text-darker',
        'bg-heavy-rain text-darker',
        'bg-mean-fruit text-darker',
        'bg-malibu-beach text-darker',
        'bg-deep-blue text-darker',
        'bg-ripe-malin text-lighter',
        'bg-arielle-smile text-lighter',
        'bg-plum-plate text-lighter',
        'bg-happy-fisher text-darker',
        'bg-happy-itmeo text-darker',
        'bg-mixed-hopes text-lighter',
        'bg-strong-bliss text-lighter',
        'bg-grow-early text-lighter',
        'bg-love-kiss text-lighter',
        'bg-premium-dark text-lighter',
        'bg-happy-green text-lighter'
      ]
    }
  },
  created () {
    this.authStore.check().catch(() => {
      this.$router.push('/login')
    })
  },
  mounted() {
    loadProgressBar()
    if (
      this.authStore.tenant &&
        (
          (this.authStore.tenant.groups && this.authStore.tenant.groups.length < 1) ||
            (this.authStore.tenant.locations && this.authStore.tenant.locations.length < 1)
        )) {
      this.$router.push({ name: 'company-onboard' })
    }
  }
}
</script>
