<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div class="h-100">
        <b-row class="h-100 no-gutters">
          <b-col lg="7" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <img src="@/assets/images/extreme-bg.jpg" class="img-responsive position-absolute w-100 h-100 "/>
          </b-col>
          <b-col lg="5" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <b-col lg="9" md="10" sm="12" class="mx-auto app-login-box">
              <div class="app-logo" />
              <h4 class="mb-0">
                <span>We'll be happy to send you a password reset link.</span>
              </h4>
              <div class="divider" />
              <div>
                <validation-observer ref="observer"
                                     v-slot="{ reset }">
                  <b-form @submit.prevent="handleSendLink"
                          @reset.prevent="reset(onReset)">
                    <b-row form>
                      <b-col md="12">
                        <b-form-group label="Email Address *">
                          <validation-provider name="email"
                                               rules="required"
                                               vid="email"
                                               v-slot="{ classes, errors }">
                          <b-form-input type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email Address"
                                        v-model="authData.email"
                                        :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']" />
                            <b-form-invalid-feedback id="email-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="12" v-if="errorMessage || message">
                        <b-form-group>
                          <b-alert show
                                   :variant="message ? 'success' : 'danger'"
                                   style="height: 40px"
                                   class="p-2">
                            {{ errorMessage }} {{ message  }}
                          </b-alert>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex align-items-center">
                      <div class="ml-auto">
                        <b-link :to="{ name: 'login' }" class="text-primary mr-2">Back to login</b-link>
                        <b-button variant="primary"
                                  type="submit"
                                  size="sm"
                                  :disabled="isBusy">
                          <b-spinner label="Loading..."
                                     small
                                     v-if="isBusy" />
                          {{ isBusy ? 'Please wait...' : 'Send Password Reset Link' }}
                        </b-button>
                      </div>
                    </div>
                  </b-form>
                </validation-observer>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import store from '@/stores'

export default {
  setup () {
    const authStore = useAuthStore(store)
    const authData = {
      email: null,
      password: null,
      rememberMe: false
    }

    return {
      authStore,
      authData
    }
  },

  data: () => ({
    isBusy: false,
    toastSetting: {
      hideProgressBar: true,
      icon: false
    },
    errorMessage: null,
    message: null
  }),

  methods: {
    onReset () {},
    handleSendLink(){
      this.$refs.observer.validate().then(res => {
        if (res) {
          this.sendLink()
        }
      })
    },
    async sendLink () {
      this.isBusy = true
      this.errorMessage = null
      this.message = null
      await this.authStore.sendPasswordResetLink({ email: this.authData.email }).then((res) => {
        this.isBusy = false
        this.message = res.data.message
      }).catch((err) => {
        this.isBusy = false
        this.errorMessage = 'Unable to process your request. Please try again.'
        if (err.response.status === 422) {
          this.errorMessage = 'Invalid email.'
        }
      })
    }
  },
  mounted () {
    this.authStore.check().then(() => {
      this.$router.push({ name: 'dashboard' })
    })
  }
}
</script>
