<template>
  <div>
    <b-overlay class="table-more-rows-spinner mt-10"
               :show="loading"
               rounded="sm">
      <div class="row mt-4 mh-v-10">
        <div class="col-md-12">
          <validation-observer ref="observer"
                               v-slot="{ reset }">
            <b-form ref="form"
                    class="mass-registration-form"
                    @submit.prevent="onSubmit"
                    @reset.prevent="reset(onReset)">
              <div class="w-100" v-for="(employee, index) in employees"
                   :key="index">
                <div class="mb-2">
                  <div class="custom-label w-25"># {{ index + 1 }} </div>
                </div>
                <hr/>
                <div class="mb-2">
                  <div class="custom-label w-25">Name * </div>
                </div>
                <b-row>
                  <b-col sm="12" md="4">
                    <b-form-group>
                      <validation-provider name="firstname"
                                           rules="required"
                                           :vid="`employees.`+ index +`.first_name`"
                                           v-slot="{ classes, errors }">
                        <b-form-input type="text"
                                      placeholder="First"
                                      aria-describedby="first-name-live-feedback"
                                      v-model="employee.first_name"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                        <b-form-invalid-feedback id="first-name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <b-form-group>
                      <b-form-input v-model="employee.middle_name"
                                    type="text"
                                    placeholder="Middle"/>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <b-form-group>
                      <validation-provider name="lastname"
                                           rules="required"
                                           :vid="`employees.`+ index +`.last_name`"
                                           v-slot="{ classes, errors }">
                        <b-form-input aria-describedby="last-name-live-feedback"
                                      type="text"
                                      placeholder="Last"
                                      v-model="employee.last_name"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                        <b-form-invalid-feedback id="last-name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="4">
                    <div class="mb-2">
                      <div class="custom-label w-25">Email * </div>
                    </div>
                    <b-form-group>
                      <validation-provider name="email"
                                           rules="required"
                                           :vid="`employees.`+ index +`.email`"
                                           v-slot="{ classes, errors }">
                        <b-form-input type="text"
                                      placeholder="Email"
                                      aria-describedby="email-live-feedback"
                                      v-model="employee.email"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                        <b-form-invalid-feedback id="email-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <div class="mb-2">
                      <div class="custom-label w-25">Birthdate * </div>
                    </div>
                    <b-form-group>
                      <validation-provider name="birthdate"
                                           rules="required"
                                           :vid="`employees.`+ index +`.birthdate`"
                                           v-slot="{ classes, errors }">
                        <DatePicker placeholder="Select date"
                                    class="w-100"
                                    format="YYYY-MM-DD"
                                    type="date"
                                    aria-describedby="birthdate-live-feedback"
                                    v-model="employee.birthdate"
                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                    :default-value="birthdateMaxDate"
                                    :disabled-date="(date) => date >= birthdateMaxDate"
                                    :formatter="formatter"/>
                        <b-form-invalid-feedback id="birthdate-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <div class="mb-2">
                      <div class="custom-label w-25">Hired Date * </div>
                    </div>
                    <b-form-group>
                      <validation-provider name="hired date"
                                           rules="required"
                                           :vid="`employees.`+ index +`.hired_date`"
                                           v-slot="{ classes, errors }">
                        <DatePicker placeholder="Select date"
                                    class="w-100"
                                    format="YYYY-MM-DD"
                                    type="date"
                                    aria-describedby="hired_date-live-feedback"
                                    v-model="employee.hired_date"
                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                    :default-value="hiredDateMinDate"
                                    :formatter="formatter"/>
                        <b-form-invalid-feedback id="hired_date-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <div class="mb-2">
                      <div class="custom-label w-100">Access Type * </div>
                    </div>
                    <b-form-group>
                      <validation-provider name="role"
                                           rules="required"
                                           :vid="`employees.`+ index +`.role`"
                                           v-slot="{ classes, errors }">
                        <role-selector aria-describedby="role-live-feedback"
                                       v-model="employee.role"
                                       :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                       @onSelect="onRoleSelect($event, index)"/>
                        <b-form-invalid-feedback id="role-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="4">
                    <div class="mb-2">
                      <div class="custom-label w-25">Job Role * </div>
                    </div>
                    <b-form-group class="mb-0">
                      <validation-provider name="job role"
                                           rules="required"
                                           :vid="`employees.`+ index +`.job_roles`"
                                           v-slot="{ classes, errors }">
                        <job-role-selector aria-describedby="job-role-live-feedback"
                                           v-model="employee.job_roles"
                                           :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                           @onSelect="onJobRoleSelect($event, index)"/>
                        <b-form-invalid-feedback id="job-role-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                    <b-link @click="showJobRolesEntryDialog = true">Add Job Role</b-link>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12"
                         md="4"
                         v-if="showLocationSelector">
                    <div class="mb-2">
                      <div class="custom-label w-25">Location * </div>
                    </div>
                    <b-form-group>
                      <validation-provider name="location"
                                           rules="required"
                                           :vid="`employees.`+ index +`.location`"
                                           v-slot="{ classes, errors }">
                        <location-selector aria-describedby="group-live-feedback"
                                           v-model="employee.location"
                                           :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                           @onSelect="onLocationSelect($event, index)"></location-selector>
                        <b-form-invalid-feedback id="location-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12"
                         md="4"
                         v-if="showTeamSelector">
                    <div class="mb-2">
                      <div class="custom-label w-25">Team * </div>
                    </div>

                    <b-form-group>
                      <validation-provider name="group"
                                           rules="required"
                                           :vid="`employees.`+ index +`.group`"
                                           v-slot="{ classes, errors }">
                        <groups-selector aria-describedby="group-live-feedback"
                                         v-model="employee.group"
                                         :multiple="true"
                                         :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                         @change="onGroupChange($event, index)"></groups-selector>
                        <b-form-invalid-feedback id="group-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col sm="12" md="12">
                    <hr/>
                    <div class="d-flex justify-content-end">
                      <b-button type="button"
                              size="sm"
                              class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white mr-2"
                              v-if="employees.length === 1 || index === (employees.length - 1)"
                              :disabled="!canAddForm"
                              @click="onAddFields">
                        <font-awesome-icon class="mr-2" icon="plus" />Add
                      </b-button>
                      <b-button type="button"
                              size="sm"
                              class="btn-shadow d-inline-flex align-items-center btn btn-danger text-white"
                              v-if="employees.length > 1"
                              @click="onRemoveFields(index)">
                        <font-awesome-icon class="mr-2" icon="times" />Remove
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
          <br/>
          Saving data...
        </div>
      </template>
    </b-overlay>
    <JobRolesEntryDialog :show="showJobRolesEntryDialog"
                         @onHide="showJobRolesEntryDialog = false"
                         @onCreated="onJobRoleCreated"></JobRolesEntryDialog>
  </div>
</template>

<script>
import JobRoleSelector from '@/components/selectors/job-roles-selector'
import GroupsSelector from '@/components/selectors/groups-selector'
import LocationSelector from '@/components/selectors/location-selector'
import RoleSelector from '@/components/selectors/role-selector'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useJobRoleStore} from '@/stores/jobRole'
import {useLocationStore} from '@/stores/location'
import {useAuthStore} from '@/stores/auth'
import {useGroupStore} from '@/stores/groups'
import {useEmployeeStore} from '@/stores/employee'
import JobRolesEntryDialog from '@/components/job-roles/CreateFormModal'
library.add(faPlus, faTimes)

export default {
  name: 'MassRegistrationForm',

  components: {
    FontAwesomeIcon,
    JobRoleSelector,
    GroupsSelector,
    LocationSelector,
    RoleSelector,
    JobRolesEntryDialog,

    DatePicker
  },

  props: {
    location: {
      type: Object,
      default: null
    },
    group: {
      type: Array,
      default: () => []
    }
  },

  setup(){
    const jobRoleStore = useJobRoleStore()
    const locationStore = useLocationStore()
    const authStore = useAuthStore()
    const groupStore = useGroupStore()
    const store = useEmployeeStore()
    return {
      jobRoleStore,
      locationStore,
      authStore,
      groupStore,
      store
    }
  },

  computed: {
    canAddForm(){
      return this.authStore.roles.length > 0 && this.locationStore.locations.length > 0 &&
          this.jobRoleStore.roles.length > 0 && this.groupStore.groups.length > 0
    },

    birthdateMaxDate() {
      return window.moment().subtract(18, 'year')._d
    },

    hiredDateMinDate() {
      return window.moment()._d
    },
    hasDuplicateEmails(){
      const emails = this.employees.map(employee => employee.email)
      return new Set(emails).size !== emails.length
    },
  },

  data: () => ({
    heading: 'Team',
    subheading:
        'Team entry form.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    loading: false,
    searchText: '',
    abortController : null,
    formatter: {
      stringify: (date) => {
        return date ? window.moment(date).format('LL') : ''
      },
    },
    employees: [
      {
        first_name: null,
        middle_name: null,
        last_name: null,
        birthdate: window.moment().subtract(18, 'year')._d,
        hired_date: window.moment()._d,
        email: null,
        mobile: null,
        phone: null,
        job_roles: null,
        group: null,
        location: null,
        role: null,
      }
    ],
    showJobRolesEntryDialog: false,
    showLocationSelector: true,
    showTeamSelector: true,
  }),

  methods: {
    onAddFields(){
      this.employees.push({
        first_name: null,
        middle_name: null,
        last_name: null,
        birthdate: window.moment().subtract(18, 'year')._d,
        hired_date: window.moment()._d,
        email: null,
        mobile: null,
        phone: null,
        job_roles: null,
        group:null,
        location: null,
        role: null,
      })
    },
    onRemoveFields(index){
      this.employees.splice(index, 1)
    },
    async onValidate () {
      this.$refs.observer.validate().then(res => {
        if (res) {
          if (this.hasDuplicateEmails) {
            this.markDuplicateEmails()
          } else {
            this.onSubmit()
          }
        }
      })
    },
    async onSubmit(){
      const confirm = await this.$dialog.confirm({ text: 'You are about to save employees data. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.loading = true
        this.store.creating = true
        this.store.massStoreEmployee(
          { employees: this.employees }
        ).then(res => {
          if (res && res.data.status === 'success') {
            this.$toast.success('Employees data has been saved.', {
              hideProgressBar: true,
              icon: false
            })

            this.store.created = true

            this.$emit('onCreated')
          }
        }).catch(err => {
          if (err.response.status === 422) {
            this.$refs.observer.setErrors({...this.$refs.observer.errors, ...err.response.data.errors})
          } else {
            this.$toast.error('Error while processing your request. Please try again.', {
              hideProgressBar: true,
              icon: false
            })
          }
        }).then(() => {
          this.store.creating = false
          this.loading = false
        })
      }
    },
    onReset(){},
    onRoleSelect(data, index){
      this.employees[index].role = data
    },
    onLocationSelect(data, index){
      this.employees[index].location = data
    },
    onGroupChange(group, index){
      this.employees[index].group = group
    },
    onJobRoleSelect(data, index){
      this.employees[index].job_roles = data
    },
    onJobRoleCreated () {
      this.showJobRolesEntryDialog = false
    },
    markDuplicateEmails() {
      //get all emails
      const emails = this.employees.map(employee => employee.email)

      // get the duplicate emails
      const duplicates = emails.filter((item, index) => emails.indexOf(item) !== index)

      this.employees.forEach((employee, index) => {
        if (duplicates.includes(employee.email)) {
          this.$refs.observer.setErrors({...this.$refs.observer.errors, [`employees.${index}.email`]: ['Duplicate email found.']})
        }
      })
    }
  },

  mounted() {
    if (this.location) {
      this.employees.forEach((employee, index) => {
        this.employees[index].location = this.location
      })
    }

    if (this.group) {
      this.employees.forEach((employee, index) => {
        this.employees[index].group = this.group
      })
    }
  },
}
</script>
