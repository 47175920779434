import * as storage from '@/plugins/helpers/storage'
import {useAuthStore} from '@/stores/auth'

window.axios.interceptors.response.use(response => response, error => {
  const authStore = useAuthStore()
  if (error && error.response.status === 401) {
    if (authStore.user) {
      authStore.deAuthenticate()
    }
  }
  return Promise.reject(error)
})

export const tenantUrl = () => {
  const tenantModel = JSON.parse(storage.local.getItem('tenant'))
  return `/api/${tenantModel.uuid}/`
}

export const chart = {
  full: function () {
    return window.axios.get('/api/organization/chart')
  },
  node (id) {
    return window.axios.get(`api/organization/${id}/chart`)
  }
}

export const auth = {
  login: function (params) {
    return window.axios.post('/api/login', params)
  },
  social: {
    facebook: {
      redirect: function () {
        return window.axios.get('/api/facebook/redirect')
      },
      callback: function (params) {
        return window.axios.post('/api/facebook/callback', params)
      }
    },
    update: function (provider, params) {
      return window.axios.post(`${tenantUrl()}${provider}/update`, params)
    },
  },
  logout: function () {
    return window.axios.delete('/api/logout')
  },
  sendPasswordResetLink (params) {
    return window.axios.post('/api/forgot-password', params)
  },
  resetPassword (params) {
    return window.axios.post('/api/reset-password', params)
  },
  getCsrfCookie () {
    return window.axios.get('/sanctum/csrf-cookie')
  },
  resendVerificationEmail(id) {
    return window.axios.post(`${tenantUrl()}email-verification-notification/${id}`)
  },
  resendWelcomeEmail(id) {
    return window.axios.post(`${tenantUrl()}email-welcome-notification/${id}`)
  },
  roles : {
    list (params) {
      return window.axios.get(`${tenantUrl()}roles`, params)
    },
    get (id) {
      return window.axios.get(`${tenantUrl()}roles/${id}`)
    },
    create (params) {
      return window.axios.post(`${tenantUrl()}roles`, params)
    },
    update (id, params) {
      return window.axios.put(`${tenantUrl()}roles/${id}`, params)
    },
    revokeAll(id) {
      return window.axios.post(`${tenantUrl()}roles/${id}/permissions/revoke-all`)
    },
    grantAll(id) {
      return window.axios.post(`${tenantUrl()}roles/${id}/permissions/grant-all`)
    }
  },
  permissions : {
    get (params) {
      return window.axios.get(`${tenantUrl()}permissions`, params)
    }
  }
}

export const tenant = {
  onboard: (params) => {
    return window.axios.post(`${tenantUrl()}onboard`, params)
  },
  activities: {
    all: (params) => {
      return window.axios.get(`${tenantUrl()}activities`, params)
    }
  },
  team: {
    list: (params) => {
      return window.axios.get(`${tenantUrl()}employees`, params)
    },
    store: (params) => {
      return window.axios.post(`${tenantUrl()}employees`, params)
    },
    massStore: (params) => {
      return window.axios.post(`${tenantUrl()}employees/bulk-create`, params)
    },
    get: (id, params) => {
      return window.axios.get(`${tenantUrl()}employees/${id}`, params)
    },
    update: (id, params) => {
      return window.axios.put(`${tenantUrl()}employees/${id}`, params)
    },
    delete: (id, params) => {
      return window.axios.delete(`${tenantUrl()}employees/${id}`, params)
    },
    updateRole: (id, params) => {
      return window.axios.post(`${tenantUrl()}employees/${id}/role/update`, params)
    },
    updateJobRole: (id, params) => {
      return window.axios.post(`${tenantUrl()}employees/${id}/job-role/update`, params)
    },
    updateSkills: (id, params) => {
      return window.axios.put(`${tenantUrl()}employees/${id}/skills`, params)
    },
    updateLocation: (id, params) => {
      return window.axios.post(`${tenantUrl()}employees/${id}/location/update`, params)
    },
    updatePassword: (id, params) => {
      return window.axios.post(`${tenantUrl()}employees/${id}/password/update`, params)
    },
    updateTeams: (id, params) => {
      return window.axios.post(`${tenantUrl()}employees/${id}/teams/update`, params)
    },
    profilePhotoUpload(id, formData) {
      return window.axios.post(`${tenantUrl()}employees/${id}/profile-photo/upload`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    getImportTemplate: () => {
      return window.axios.get(`${tenantUrl()}team/import/template/download`)
    },
    getBirthdays: () => {
      return window.axios.get(`${tenantUrl()}employees/birthdays`)
    },
    goals: {
      store(formData){
        return window.axios.post(`${tenantUrl()}goals`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
      },
      delete(id){
        return window.axios.delete(`${tenantUrl()}goals/${id}`)
      },
      update(id, formData){
        return window.axios.post(`${tenantUrl()}goals/${id}?_method=PUT`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } })
      },
      list() {
        return window.axios.get(`${tenantUrl()}goals`)
      }
    },
    jobRoles: {
      massStore(params) {
        return window.axios.post(`${tenantUrl()}job-roles/bulk-create`, params)
      },
      store(params) {
        return window.axios.post(`${tenantUrl()}job-roles`, params)
      },
      delete(id){
        return window.axios.delete(`${tenantUrl()}job-roles/${id}`)
      },
      update(id, params){
        return window.axios.put(`${tenantUrl()}job-roles/${id}`, params)
      },
      list() {
        return window.axios.get(`${tenantUrl()}job-roles`)
      }
    },
    groups: {
      store(id, params) {
        return window.axios.post(`${tenantUrl()}groups`, params)
      },
      massStore(params) {
        return window.axios.post(`${tenantUrl()}groups/bulk-create`, params)
      },
      delete(id){
        return window.axios.delete(`${tenantUrl()}groups/${id}`)
      },
      update(id, params){
        return window.axios.put(`${tenantUrl()}groups/${id}`, params)
      },
      list() {
        return window.axios.get(`${tenantUrl()}groups`)
      },
      getOrgChart() {
        return window.axios.get(`${tenantUrl()}groups/org-chart`)
      }
    },
    location: {
      list() {
        return window.axios.get(`${tenantUrl()}locations`)
      },
      store(params) {
        return window.axios.post(`${tenantUrl()}location`, params)
      },
      update(id, params){
        return window.axios.put(`${tenantUrl()}location/${id}`, params)
      },
      delete(id){
        return window.axios.delete(`${tenantUrl()}location/${id}`)
      },
      makeParent(id){
        return window.axios.put(`${tenantUrl()}locations/${id}/make-parent`)
      },
      getOrgChart() {
        return window.axios.get(`${tenantUrl()}locations/org-chart`)
      }
    },
    skills: {
      updateAll(userId, params) {
        return window.axios.post(`${tenantUrl()}team/user/${userId}/skills`, params)
      },
      updateSkill(userId, skillId, params) {
        return window.axios.post(`${tenantUrl()}team/user/${userId}/skill/${skillId}`, params)
      }
    },
    assessments: {
      get(id, params) {
        return window.axios.get(`${tenantUrl()}assessments/user/${id}`, params)
      }
    }
  },
  organization: {
    get: (params) => {
      return window.axios.get(`${tenantUrl()}team/organization/chart`, params)
    }
  },
  assessments: {
    get: (params) => {
      return window.axios.get(`${tenantUrl()}assessments`, params)
    },
    store: (params) => {
      return window.axios.post(`${tenantUrl()}assessments`, params)
    },
    show: (id) => {
      return window.axios.get(`${tenantUrl()}assessments/${id}`)
    },
    getAssessmentTableByLocation: (id, params) => {
      return window.axios.get(`${tenantUrl()}assessment/user/${id}/people-by-location`, params)
    },
    getAssessmentTableByGroup: (id, params) => {
      return window.axios.get(`${tenantUrl()}assessment/user/${id}/people-by-group`, params)
    },
    templates: {
      get: () => {
        return window.axios.get(`${tenantUrl()}assessment/templates`)
      },
      show: (id) => {
        return window.axios.get(`${tenantUrl()}assessment/templates/${id}`)
      },
      store: (params) => {
        return window.axios.post(`${tenantUrl()}assessment/templates`, params)
      },
      update: (id, params) => {
        return window.axios.put(`${tenantUrl()}assessment/templates/${id}`, params)
      },
    }
  },
  objectives: {
    store(params){
      return window.axios.post(`${tenantUrl()}objectives`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    storeQuarterlyGoals(params){
      return window.axios.post(`${tenantUrl()}objectives/quarterly`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    update(id, params){
      return window.axios.put(`${tenantUrl()}objectives/${id}`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    list(params) {
      return window.axios.get(`${tenantUrl()}objectives`, params)
    }
  },
  skills: {
    list: () => {
      return window.axios.get(`${tenantUrl()}skills`)
    },
    get: (id) => {
      return window.axios.get(`${tenantUrl()}skills/${id}`)
    },
    store: (params) => {
      return window.axios.post(`${tenantUrl()}skills`, params)
    },
    update: (id, params) => {
      return window.axios.put(`${tenantUrl()}skills/${id}`, params)
    },
    category: {
      store: (params) => {
        return window.axios.post(`${tenantUrl()}skill-categories`, params)
      },
      list: () => {
        return window.axios.get(`${tenantUrl()}skill-categories`)
      },
      update: (id, params) => {
        return window.axios.put(`${tenantUrl()}skill-categories/${id}`, params)
      }
    },
    assessments: {
      store: (params) => {
        return window.axios.post(`${tenantUrl()}skills-assessment`, params)
      },
      list: () => {
        return window.axios.get(`${tenantUrl()}skills-assessments`)
      },
      update: (id, params) => {
        return window.axios.put(`${tenantUrl()}skills-assessment/${id}`, params)
      }
    },
    resource: {
      get(skillId, params){
        return window.axios.get(`${tenantUrl()}skills/${skillId}/resource`, params)
      },
      create(skillId, params) {
        return window.axios.post(`${tenantUrl()}skills/${skillId}/resource`, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      update(id, params) {
        return window.axios.post(`${tenantUrl()}skills/resource/${id}?_method=PUT`, params, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      delete(id) {
        return window.axios.delete(`${tenantUrl()}skills/resource/${id}`)
      }
    }
  }
}
