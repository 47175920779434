<template>
  <validation-observer ref="observer"
                       v-slot="{ reset }">
    <b-form ref="form"
            class="quarterly-goal-entry-form"
            @submit.prevent="onSample"
            @reset.prevent="reset(onReset)">
      <div class="mb-2">

        <div  class="w-100">
          <b-row>
            <b-col sm="12" md="6">
              <div class="custom-label">Year *</div>
              <b-form-group>
                <validation-provider name="start date"
                                     rules="required"
                                     vid="start_date"
                                     v-slot="{ classes, errors }">
                  <DatePicker placeholder="Select year"
                              class="w-100"
                              aria-describedby="start-live-feedback"
                              format="YYYY"
                              v-model="data.year"
                              type="year"
                              :default-value="minDate"
                              :editable="true"
                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                              :formatter="formatter"/>
                  <b-form-invalid-feedback id="start-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="6">
              <div class="custom-label">Team</div>
              <b-form-group>
                <GroupSelector v-model="data.component"
                               :allow-empty="true"
                               @onSelect="onGroupSelect"></GroupSelector>
                <small class="form-text text-muted">
                  Leave this blank for company-wide goal.
                </small>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div class="w-100">
          <b-card no-body>
            <b-tabs class="card-header-tab-animation"
                    card>
              <b-tab  v-for="(quarter, i) in data.quarters"
                      :key="quarter.key"
                      :title="`Q${quarter.key}`"
                     :active="i === 0">
                <b-row v-for="(goal, index) in quarter.goals" :key="index">
                  <b-col sm="12" md="12">
                    <div class="d-flex justify-content-between">
                      <h6>#{{ index + 1  }}</h6>
                      <div>
                        <b-button variant="danger"
                                  size="sm"
                                  class="mr-2"
                                  title="Remove"
                                  v-if="quarter.goals.length > 1"
                                  @click="onDelete(quarter, index)">
                          <font-awesome-icon icon="trash"/>
                        </b-button>
                        <b-button variant="primary"
                                  size="sm"
                                  title="Add"
                                  v-if="(index + 1) === quarter.goals.length"
                                  @click="onAdd(quarter, index)">
                          <font-awesome-icon icon="plus"/>
                        </b-button>
                      </div>
                    </div>

                    <hr/>

                    <ImageCropUploader class="mb-2"
                                       :show-preview="false"
                                       :cropped-height="1000"
                                       :cropped-width="1000"
                                       :cropper-height="false"
                                       :aspect-ratio="false"
                                       @fileAdded="onImageAdded($event, goal)"
                                       @fileSelected="onImageSelected($event, goal)"
                    />

                    <div class="custom-label w-25">Title * </div>
                    <b-form-group>
                      <validation-provider name="title"
                                           rules="required"
                                           vid="title"
                                           v-slot="{ classes, errors }">
                        <b-form-input type="text"
                                      placeholder="Title"
                                      aria-describedby="title-live-feedback"
                                      v-model="goal.title"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                        <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="12">
                    <div class="custom-label w-25">Description</div>
                    <b-form-group>
                      <b-form-input type="text"
                                    placeholder="Description"
                                    v-model="goal.description"/>
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <div class="w-100"
             v-for="goal in data.goals"
             :key="goal.key">
          <p>Q{{ goal.key }}</p>
          <hr/>
          <b-row>
            <b-col sm="12" md="12">
              <div class="custom-label w-25">Title * </div>
              <b-form-group>
                <validation-provider name="title"
                                     rules="required"
                                     vid="title"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="text"
                                placeholder="Title"
                                aria-describedby="title-live-feedback"
                                v-model="goal.title"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>


            <b-col sm="12" md="12">
              <div class="custom-label w-25">Description</div>
              <b-form-group>
                  <b-form-input type="text"
                                placeholder="Description"
                                v-model="goal.description"/>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr>
      <div class="d-block text-right">
        <button type="button"
                class="mr-2 btn btn-link btn-sm"
                :disabled="loading"
                @click="onCancel">Cancel</button>
        <button type="button"
                class="btn btn-success btn-sm"
                :disabled="loading"
                @click="onSubmit">
          <b-spinner label="Loading..."
                     small
                     v-if="loading"></b-spinner>
          {{ loading ? 'Saving data...' : 'Save' }}
        </button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import GroupSelector from '@/components/selectors/groups-selector'
import {useObjectivesStore} from '@/stores/objectives'

import * as GoalTypes from '@/constants/goal-types'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ImageCropUploader from '@/components/image-crop-uploader'
import JsonFormData from 'json-form-data'
export default {
  name: 'QuarterlyGoalEntryForm',

  components: {
    FontAwesomeIcon,
    GroupSelector,
    DatePicker,
    ImageCropUploader
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  setup(){
    const store = useObjectivesStore()

    return {
      store
    }
  },

  computed: {
    minDate() {
      return window.moment()._d
    }
  },

  data() {
    return {
      GoalTypes,
      formatter: {
        stringify: (date) => {
          return date ? window.moment(date).format('YYYY') : ''
        },
      },

      types: [
        {
          label: 'Day',
          key: GoalTypes.DAILY
        },
        {
          label: 'Week',
          key: GoalTypes.WEEKLY
        },
        {
          label: 'Month',
          key: GoalTypes.MONTHLY
        },
        {
          label: 'Quarterly',
          key: GoalTypes.QUARTERLY
        },
        {
          label: 'Yearly',
          key: GoalTypes.YEARLY
        }
      ],
      loading: false,

      data: {
        component: null,
        year: null,
        quarters: [
          {
            label: 'Q1',
            key: 1,
            start_date: null,
            end_date: null,
            goals: [{
              title: null,
              description: null,
              duration: 1,
              type: { key: GoalTypes.QUARTERLY, label: 'Quarterly' }
            }]
          },
          {
            label: 'Q2',
            key: 2,
            start_date: null,
            end_date: null,
            goals: [{
              title: null,
              description: null,
              duration: 1,
              type: { key: GoalTypes.QUARTERLY, label: 'Quarterly' }
            }]
          },
          {
            label: 'Q3',
            key: 3,
            start_date: null,
            end_date: null,
            goals: [{
              title: null,
              description: null,
              duration: 1,
              type: { key: GoalTypes.QUARTERLY, label: 'Quarterly' }
            }]
          },
          {
            label: 'Q4',
            key: 4,
            start_date: null,
            end_date: null,
            goals: [{
              title: null,
              description: null,
              duration: 1,
              type: { key: GoalTypes.QUARTERLY, label: 'Quarterly' }
            }]
          }
        ]
      },
    }
  },

  methods: {
    onImageAdded(imageData, goal) {
      goal.image = imageData

    },
    onImageSelected(imageData, goal) {
      goal.image = imageData
    },
    onSubmit() {
      // this.$refs.observer.validate().then(() => {
      // })
      this.handleCreate()
    },

    handleCreate() {
      this.loading = true

      let options = {
        initialFormData: new FormData(),
        showLeafArrayIndexes: true,
        includeNullValues: true,
        mapping: function(value) {
          if (typeof value === 'boolean') {
            return +value ? '1': '0'
          }
          return value
        }
      }

      let formData = JsonFormData(this.data, options)
      this.store.createQuarterlyGoals(formData).then(res => {
        this.loading = false
        if (res.status === 'success') {
          this.$bvToast.toast('Quarterly goals created successfully.', {
            title: 'Success',
            variant: 'success',
            solid: true
          })

          this.$emit('created', res.data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    onSample(){

    },
    onReset(){

    },
    onCancel(){
      this.$emit('onCancel')
    },
    onGroupSelect(component){
      console.log(component)
      //this.goal.component = component
    },
    onDelete(quarter, index) {
      quarter.goals.splice(index, 1)
    },
    onAdd(quarter, index) {
      console.log(index)
      //quarter.goals.splice(index, 0, { title: null, description: null })
      quarter.goals.push({ title: null, description: null })
    },
  },

  mounted() {
    this.data.year = new Date()
  }
}
</script>


<style lang="scss">
  .quarterly-goal-entry-form {
    .ankaCropper__saveButton {
      display: none;
    }
  }
</style>
