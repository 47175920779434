<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon"></page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-row>
          <b-col md="3">
            <div class="main-card mb-3 card">
              <div class="card-body">
                <b-form-group label="Chat for">
                  <employee-selector :exclude-current-user="true"
                                     :employee-id="$route.params.id"
                                     @onSelect="onEmployeeSelect">
                  </employee-selector>
                  <b-link class="small float-right" :to="{ name: 'team-mass-registration' }">Add Employee</b-link>
                </b-form-group>

                <b-form-group label="Template">
                  <assessment-template-selector @onSelect="onTemplateSelect"
                                                @onRemove="onTemplateDeSelect">
                  </assessment-template-selector>
                  <b-link class="small float-right" :to="{ name: 'assessment-create-template' }">Add Template</b-link>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col md="9">
            <div class="main-card mb-3 card"
                 v-if="assessment.template && assessment.employee">
              <validation-observer ref="observer"
                                   v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(onSubmit)">
                  <div class="card-body">
                    <b-form-group label="Title *">
                      <validation-provider name="title"
                                           rules="required"
                                           vid="title"
                                           v-slot="{ classes, errors }">
                        <b-form-input type="text"
                                      placeholder="Title"
                                      v-model="title"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                      aria-describedby="input-title-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback :id="`input-title-live-feedback`">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="Description">
                        <b-form-input type="text"
                                      placeholder="Description"
                                      v-model="description"
                                      aria-describedby="input-title-live-feedback">
                        </b-form-input>
                    </b-form-group>
                    <hr class="mb-5"/>
                    <b-form-group v-for="(question) in questions"
                                    :key="question.id"
                                    :label="question.item">
                      <validation-provider name="answer"
                                           rules="required"
                                           :vid="`answer-`+ question.id"
                                           v-slot="{ classes, errors }">
                        <b-form-input type="text"
                                      placeholder="Key in answer here.."
                                      v-model="question.answer"
                                      key="input-{{ question.id }}"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                      :aria-describedby="`input-`+ question.id +`-live-feedback`">
                        </b-form-input>
                        <b-form-invalid-feedback :id="`input-`+ question.id +`-live-feedback`">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div class="d-block text-right card-footer">
                  <button class="mr-2 btn btn-link btn-sm"
                          type="reset"
                          :disabled="isSaving"
                          @click="onReset">
                    Reset
                  </button>
                  <button type="submit"
                          class="btn btn-success btn-sm"
                          :disabled="isSaving">
                    <b-spinner label="Loading..."
                               small
                               v-if="isSaving"></b-spinner>
                    {{ isSaving ? 'Saving...' : 'Save' }}
                  </button>
                </div>
                </form>
              </validation-observer>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import PageTitle from '@/components/layout/PageTitle'
import EmployeeSelector from '@/components/selectors/employee-selector'
import AssessmentTemplateSelector from '@/components/selectors/assessment-template-selector'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faAngleDown, faSearch, faStar, faTags} from '@fortawesome/free-solid-svg-icons'
import {useAssessmentStore} from '@/stores/assessment'
import {useEmployeeStore} from '@/stores/employee'

library.add(faAngleDown, faTags, faStar, faSearch)

export default {
  name: 'NewAssessment',

  components: {
    PageTitle,
    EmployeeSelector,
    AssessmentTemplateSelector
  },

  setup () {
    const assessmentStore = useAssessmentStore()
    const employeeStore = useEmployeeStore()
    return {
      assessmentStore,
      employeeStore
    }
  },

  data: () => ({
    heading: 'Create A Chat',
    subheading:
        'Create a chat with colleagues or team mates.',
    icon: 'pe-7s-chat icon-gradient bg-mixed-hopes',

    isMobileOpen: false,
    assessment: {
      employee: null,
      template: null
    },
    title: '',
    description: '',
    questions: [],
    isSaving: false
  }),

  created () {
    if (this.$route.params.id) {
      this.assessment.employee = this.employeeStore.employee
    }
  },

  methods: {
    onEmployeeSelect (employee) {
      this.assessment.employee = employee
    },
    onTemplateSelect (template) {
      this.assessment.template = template
    },
    onTemplateDeSelect () {
      this.assessment.template = null
    },
    async onSubmit () {
      const confirm = await this.$dialog.confirm({ text: 'You are about to save a conversation. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.isSaving = true
        this.assessmentStore.storeAssessment({
          template_id: this.assessment.template.uuid,
          employee_id: this.assessment.employee.uuid,
          title: this.title,
          description: this.description,
          data: JSON.stringify(this.questions),
        }).then(() => {
          this.$toast.success('Chat has been saved.', {
            hideProgressBar: true,
            icon: false
          })

          this.$router.push({ name: 'assessment' })
        }).catch(() => {
          this.$toast.error('Error while processing your request. Please try again.', {
            hideProgressBar: true,
            icon: false
          })
        }).then(() => {
          this.isSaving = false
        })
      }
    },
    setJsonFormattedQuestions () {
      let id = 0
      this.questions = this.assessment.template.data.map(function (item) {
        return {...item, answer: '', id: id++}
      })
    },

    onReset () {
      this.setJsonFormattedQuestions()

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    }
  },
  watch: {
    'assessment.template': function(template){
      if (template) {
        this.setJsonFormattedQuestions()
      }
    },
    'employeeStore.employees': function (employees) {
      if (this.$route.params.id && !this.assessment.employee) {
        this.assessment.employee = employees.find(employee => employee.uuid === this.$route.params.id)
      }
    }
  }
}
</script>
