<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon"></page-title>
    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-row>
          <b-col sm="12" md="6" offset-md="3">
            <validation-observer ref="observer"
                                 v-slot="{ reset }">
              <b-form ref="form"
                      class="registration-form mb-5"
                      @submit.prevent="onSubmit"
                      @reset.prevent="reset(onReset)">
                <v-stepper v-model="stepper" vertical>
                  <v-stepper-step  step="1"
                                   :editable="false"
                                   :complete="stepper > 1">
                    Privileges
                    <small>Define what access to actions and resources</small>
                  </v-stepper-step>
                  <b-overlay class="table-more-rows-spinner"
                             :show="store.isLoadingPermissions || gettingRole"
                             rounded="sm">
                    <v-stepper-content step="1">
                      <validation-observer ref="BasicInfoObserver">
                          <div class="mb-2">
                            <div class="w-100">
                              <div class="mb-4">
                                <div class="custom-label w-25">Title * </div>
                              </div>
                              <b-row>
                                <b-col sm="12" md="12">
                                  <b-form-group>
                                    <validation-provider name="title"
                                                         rules="required"
                                                         vid="title"
                                                         v-slot="{ classes, errors }">
                                      <b-form-input type="text"
                                                    placeholder="e.g. Admin"
                                                    aria-describedby="title-live-feedback"
                                                    v-model="role.name"
                                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                                      <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                              <b-row>
                                <b-col sm="12" md="8">
                                  <div class="custom-label w-25 mb-4">Permissions </div>
                                  <b-form-group v-for="(permission, index) in permissions"
                                                :key="index"
                                                :label="index"
                                                class="text-muted"
                                                style="font-weight: 500; font-size: 14px">
                                    <b-form-checkbox-group
                                        text-field="label"
                                        value-field="name"
                                        stacked
                                        v-model="role.permissions"
                                        :options="permission"
                                    ></b-form-checkbox-group>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </div>
                            <v-btn color="primary"
                                   class="mr-2"
                                   :loading="loading"
                                   :disabled="store.isLoadingPermissions"
                                   @click="validateBasicInfo">Save</v-btn>
                            <v-btn color="danger"
                                   class="mr-2"
                                   :disabled="store.isLoadingPermissions || loading"
                                   @click="onCancel">Cancel</v-btn>
                          </div>
                        </validation-observer>
                    </v-stepper-content>
                    <template #overlay>
                      <div class="text-center">
                        <b-spinner variant="primary" label="Spinning"></b-spinner>
                        <br/>
                        Loading data...
                      </div>
                    </template>
                  </b-overlay>
                </v-stepper>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowLeft,
  faAngleUp,
  faDotCircle,
  faAngleDown,
  faSearch,
  faEnvelope,
  faMobile,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import groupBy from 'lodash.groupby'

import {useAuthStore} from '@/stores/auth'
library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown, faSearch, faEnvelope, faMobile, faPhone)

export default {
  name: 'RoleForm',
  components: {
    FontAwesomeIcon,
    PageTitle
  },

  setup(){
    const store = useAuthStore()

    return {
      store
    }
  },

  computed: {
    permissions(){
      return groupBy(this.store.permissions, permission => {
        return permission.group
      })
    }
  },

  data: () => ({
    heading: 'Privilege Form',
    subheading:
        'Add or update privilege data.',
    icon: 'pe-7s-door-lock icon-gradient bg-tempting-azure',
    onEdit: false,
    role: {
      name: null,
      permissions: []
    },
    showValueLabel: false,
    showInputs: false,
    showSkeletonLoader: false,
    loading: false,
    gettingRole: false,
    formatter: {
      stringify: (date) => {
        return date ? window.moment(date).format('LL') : ''
      },
    },
    stepper: 1
  }),
  methods: {
    onSubmit(){
      if (this.role && this.role.id) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }
    },

    async handleCreate()
    {
      const confirm = await this.$dialog.confirm({ text: 'You are about to save a new role data. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.loading = true
        this.store.createRole(this.role).then(res => {
          if (res.data.status === 'success') {
            this.$toast.success('New privilege data has been saved.', {
              hideProgressBar: true,
              icon: false
            })
            this.$router.push({ name: 'roles' })
          } else {
            this.$toast.error('Error while processing your request. Please try again.', {
              hideProgressBar: true,
              icon: false
            })
          }
          this.loading = false
        })
      }
    },

    async handleUpdate(){
      const confirm = await this.$dialog.confirm({ text: 'You are about to save changes. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.loading = true
        this.store.updateRole(this.role.uuid, this.role).then((res) => {
          if (res.data.status === 'success') {
            this.$toast.success('Privilege data has been saved.', {
              hideProgressBar: true,
              icon: false
            })

            this.$router.push({ name: 'roles' })
          } else {
            this.$toast.error('Error while processing your request. Please try again.', {
              hideProgressBar: true,
              icon: false
            })
          }
          this.loading = false
        })
      }
    },

    onReset(){},

    onCancel(){
      this.$router.push({ name: 'roles' })
    },

    validateBasicInfo () {
      this.$refs.BasicInfoObserver.validate().then(res => {
        if (res) {
          this.onSubmit()
        }
      })
    }
  },
  mounted(){
    if (this.store.permissions.length < 1) {
      this.store.getPermissions()
    }

    if (this.$route.params.id) {
      if (!this.store.role || (this.store.role && this.store.role.uuid !== this.$route.params.id)) {
        this.gettingRole = true
        this.store.getRole(this.$route.params.id).then(() => {
          this.role = { ...this.store.role, permissions: this.store.role.permissions.map(item => { return item.name }) }
          this.gettingRole = false
        })
      } else {
        this.role = { ...this.store.role, permissions: this.store.role.permissions.map(item => { return item.name }) }
      }
    }
  }
}
</script>

<style lang="scss">
.registration-form {
  .multiselect__content-wrapper {
    position: relative !important;
  }
}
</style>
