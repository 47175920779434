<template>
  <b-modal size="lg"
           id="modal-1"
           title="Template Details"
           v-model="isOpen"
           @hide="onHide">
    <p class="mb-0 font-weight-bolder">{{ template.name }}</p>
    <p class="mb-4 mt-0 font-italic">{{ template.description }}</p>

    <p class="mb-1 mt-4 font-weight-bold">Questions</p>
    <p class="mb-2" v-for="(question, index) in items" :key="index">{{ index + 1 }}. {{ question.item }}</p>

    <template #modal-footer="{ hide }">

      <!-- Button with custom close trigger value -->
      <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'TemplateViewModal',

  computed:{
    isOpen: {
      get () {
        return this.show
      },
      set (isOpen) {
        return isOpen
      }
    },
    items(){
      return JSON.parse(this.template.data)
    }
  },
  props: {
    template: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    onHide(){
      this.isOpen = false
      this.$emit('hide')
    }
  }
}
</script>