import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useAssessmentStore = defineStore({
  id: 'assessment',
  persist: false,
  state: () => ({
    assessment: null,
    assessments: [],
    template: null,
    templates: [],
    filters: {
      default: {
        chat_by: null,
        chat_for: null,
        conductedLast: null
      },
      current: {
        chat_by: null,
        chat_for: null,
        conductedLast: null
      }
    }
  }),
  getters:{
    filterHasChanges (state) {
      return JSON.stringify(state.filters.default) !== JSON.stringify(state.filters.current)
    }
  },
  actions: {
    getTemplates () {
      return tenant.assessments.templates.get().then(res => {
        this.templates = res.data.data
        return res
      }).catch(() => {
        return []
      })
    },
    getTemplate (id) {
      return tenant.assessments.templates.show(id).then(res => {
        this.template = res.data
        return res
      }).catch(() => {
        return []
      })
    },
    storeTemplate (params) {
      return tenant.assessments.templates.store(params)
    },
    updateTemplate (id, params) {
      return tenant.assessments.templates.update(id, params)
    },
    storeAssessment (params){
      return tenant.assessments.store(params)
    },
    getAssessments (params){
      return tenant.assessments.get({ params: params })
    },
    getAssessment (id){
      return tenant.assessments.show(id)
    },
    getAssessmentableByLocation (id){
      return tenant.assessments.getAssessmentTableByLocation(id)
    },
    getAssessmentableByGroup (id){
      return tenant.assessments.getAssessmentTableByGroup(id)
    }
  }
})
