<template>
  <div>
    <span class="text-nowrap"
          v-html="dateTimePassed"
          v-b-tooltip.hover="{ variant: 'info' }"
          :title="relativeDateTime"></span>
  </div>
</template>

<script>
import {shortDateTimePassed} from '@/plugins/filters/datetime.filters'

export default {
  name: 'item-date-time',
  props: {
    fromTime: {
      type: String,
      required: true
    },

    updateInterval: {
      type: Number,
      required: false,
      default: 1000
    }
  },
  data: function () {
    return {
      dateTimePassed: null,
      relativeDateTime: null,
      getDateTimePassedInterval: null
    }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    clearInterval(this.getDateTimePassedInterval)
  },
  methods: {
    init () {
      this.getDateTimePassed()
      this.getDateTimePassedInterval = setInterval(this.getDateTimePassed, this.updateInterval)
      this.relativeDateTime = this.$options.filters.fixRelativeDatetimeFormat(this.fromTime)
    },
    getDateTimePassed () {
      this.dateTimePassed = this.$options.filters.shortDateTimePassed(this.fromTime, false)
    }
  }
}
</script>
