<template>
  <VueMultiselect track-by="uuid"
                  label="name"
                  placeholder="Select job role"
                  selected-label=""
                  select-label=""
                  deselect-label=""
                  v-model="role"
                  :allowEmpty="allowEmpty"
                  :options="store.roles"
                  :loading="store.loading || showLoader"
                  :disabled="store.loading || disabled || showLoader"
                  @remove="onRemove"
                  @select="onSelect">
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.name }} </span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { useJobRoleStore } from '@/stores/jobRole'

export default {
  name: 'JobRolesSelector',
  components: { VueMultiselect },

  props: {
    value: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },

  model: {
    prop: 'value',
    event: 'select'
  },

  setup () {
    const store = useJobRoleStore()
    const getList = () => {
      store.loading = true
      store.getRoles().then(() => {
        store.loading = false
      })
    }

    return {
      store,
      getList
    }
  },

  data () {
    return {
      role: this.value
    }
  },
  methods: {
    onSelect () {
      this.$nextTick(function(){
        this.$emit('select', this.role)
      })

    },
    onRemove () {
      this.$emit('remove')
    }
  },

  mounted() {
    if (this.store.roles.length < 1) {
      this.getList()
    }
  },

  watch: {
    value: {
      deep: true,
      handler(){
        this.role = this.value
      }
    }
  }
}
</script>
