import Login from '@/components/LoginCompact'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/RecoverPassword'
import Logout from '@/components/Logout'
import Main from '@/components/layout/Main'
import Dashboard from '@/components/Dashboard'

import EmployeeRegistration from '@/components/employee/Registration'
import EmployeeList from '@/components/employee/List'
import EmployeeChart from '@/components/employee/EmployeeOrgChart'
import EmployeeProfile from '@/components/employee/Profile'
import Assessment from '@/components/assessment/Assessments'
import NewAssessment from '@/components/assessment/NewAssessment'
import AssessmentDetails from '@/components/assessment/AssessmentDetails'
import AssessmentTemplates from '@/components/assessment/Templates'
import AssessmentTemplateForm from '@/components/assessment/TemplateForm'
import DreamBoards from '@/components/dream-board/DreamBoards'
import DreamBoard from '@/components/employee/DreamBoard'

import JobRolesList from '@/components/job-roles/List'
import LocationsList from '@/components/locations/List'
import GoalsList from '@/components/goals/List'

import RolesList from '@/components/roles/List'
import RoleForm from '@/components/roles/Form'

import Error404 from '@/components/errors/Error404'
import Error401 from '@/components/errors/Error401'
import MassRegistration from '@/components/employee/MassRegistration'

import GroupsList from '@/components/groups/List'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'Recover Password'
    }
  },
  {
    path: '/reset-password/:token+',
    name: 'reset-password',
    component: ResetPassword,
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'Logout'
    }
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/components/VerifyEmailNotice'),
    meta: {
      title: 'Email Verification'
    }
  },
  {
    path: '/company/onboard',
    name: 'company-onboard',
    component: () => import('@/components/Onboard'),
    meta: {
      title: 'Company Onboard'
    }
  },
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard'
        }
      },
      {
        path: '/team/directory',
        name: 'team',
        component: EmployeeList,
        meta: {
          title: 'Team Directory',
          protected: true,
          permissions: ['view-employees']
        }
      },
      {
        path: '/team/org-chart',
        name: 'team-org-chart',
        component: EmployeeChart,
        meta: {
          title: 'Team Organization Chart',
          protected: true,
          permissions: ['view-org-chart']
        }
      },
      {
        path: '/chart/:component(teams|locations|job-roles)+',
        name: 'chart',
        component: () => import('@/components/chart/Builder'),
        meta: {
          title: 'Hierarchy',
          protected: false
        }
      },
      {
        path: '/setup/:component(teams|locations)+',
        name: 'setup',
        component: () => import('@/components/chart/Builder'),
        meta: {
          title: 'Chart Builder',
          protected: true,
          permissions: ['chart-builder']
        }
      },
      {
        path: '/team/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'team-profile',
        component: EmployeeProfile,
        meta: {
          title: 'Profile',
          protected: false,
          permissions: ['view-employee']
        }
      },
      {
        path: '/team/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/:page(dream-board|skills|location|job-roles|permissions|roles|teams|email-addresses|security|social-login)+',
        name: 'team-profile-pages',
        component: EmployeeProfile,
        meta: {
          title: 'Profile',
          protected: false,
          permissions: ['view-employee-pages']
        }
      },
      {
        path: '/team/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/dream-board',
        name: 'team-dream-board',
        component: DreamBoard,
        meta: {
          title: 'My Dream Board'
        }
      },
      {
        path: '/team/register',
        name: 'team-registration',
        component: EmployeeRegistration,
        meta: {
          title: 'Register',
          protected: true,
          permissions: ['create-employee']
        }
      },
      {
        path: '/team/registers',
        name: 'team-mass-registration',
        component: MassRegistration,
        meta: {
          title: 'Register',
          protected: true,
          permissions: ['create-employee']
        }
      },
      {
        path: '/chat',
        name: 'assessment',
        component: Assessment,
        meta: {
          title: 'Chat',
          protected: true,
          permissions: ['view-assessments']
        }
      },
      {
        path: '/chat/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'assessment-details',
        component: AssessmentDetails,
        meta: {
          title: 'Chat',
          protected: true,
          permissions: ['view-assessment']
        }
      },
      {
        path: '/chat/new',
        name: 'new-assessment',
        component: NewAssessment,
        meta: {
          title: 'Create a chat',
          protected: true,
          permissions: ['create-assessment']
        }
      },
      {
        path: '/chat/new/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'new-user-assessment',
        component: NewAssessment,
        meta: {
          title: 'Create a chat',
          protected: true,
          permissions: ['create-assessment']
        }
      },
      {
        path: '/chat/templates',
        name: 'assessment-templates',
        component: AssessmentTemplates,
        meta: {
          title: 'Chat Templates',
          protected: true,
          permissions: ['view-assessment-templates']
        }
      },
      {
        path: '/chat/templates/new',
        name: 'assessment-create-template',
        component: AssessmentTemplateForm,
        meta: {
          title: 'Chat Templates',
          protected: true,
          permissions: ['create-assessment-template']
        }
      },
      {
        path: '/chat/templates/edit/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'assessment-edit-template',
        component: AssessmentTemplateForm,
        meta: {
          title: 'Chat Templates',
          protected: true,
          permissions: ['edit-assessment-template']
        }
      },
      {
        path: '/dream-board',
        name: 'dream-board',
        component: DreamBoards,
        meta: {
          title: 'Dream Board'
        }
      },

      {
        path: '/job-roles',
        name: 'job-roles',
        component: JobRolesList,
        meta: {
          title: 'Job roles',
          protected: true,
          permissions: ['view-job-roles']
        }
      },
      {
        path: '/job-roles/create',
        name: 'create-job-role',
        component: () => import('@/components/job-roles/JobRolesEntry'),
        meta: {
          title: 'Create job roles',
          protected: true,
          permissions: ['create-job-role']
        }
      },

      {
        path: '/job-roles/edit/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'edit-job-role',
        component: () => import('@/components/job-roles/JobRolesEntry'),
        meta: {
          title: 'Edit job roles',
          protected: true,
          permissions: ['edit-job-role']
        }
      },

      {
        path: '/locations',
        name: 'locations',
        component: LocationsList,
        meta: {
          title: 'Locations',
          protected: true,
          permissions: ['view-locations']
        }
      },
      {
        path: '/onboarding',
        name: 'onboarding',
        component: () => import('@/components/onboarding/List'),
        meta: {
          title: 'Employee Onboarding',
          protected: false,
          permissions: ['view-onboarding-list']
        }
      },
      {
        path: '/skills',
        name: 'skills',
        component: () => import('@/components/skills/List'),
        meta: {
          title: 'Skills',
          protected: true,
          permissions: ['view-skills']
        }
      },
      {
        path: '/skills/assessments',
        name: 'skills-assessments',
        component: () => import('@/components/skills/assessments/Assessments'),
        meta: {
          title: 'Skills Assessments',
          protected: false,
          permissions: ['view-skills-assessments']
        }
      },
      {
        path: '/skills/category/add',
        name: 'create-skills-category',
        component: () => import('@/components/skills/category/Entry'),
        meta: {
          title: 'Skills Category',
          protected: true,
          permissions: ['create-skills-category']
        }
      },

      {
        path: '/skills/category/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'edit-skills-category',
        component: () => import('@/components/skills/category/Entry'),
        meta: {
          title: 'Skills Category',
          protected: true,
          permissions: ['edit-skills-category']
        }
      },

      {
        path: '/skills/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'skill',
        component: () => import('@/components/skills/Skill'),
        meta: {
          title: 'Skills',
          protected: true,
          permissions: ['view-skills']
        }
      },

      {
        path: '/skills/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/:page(resources|trainings)+',
        name: 'skill-pages',
        component: () => import('@/components/skills/Skill'),
        meta: {
          title: 'Skills',
          protected: true,
          permissions: ['view-skills']
        }
      },

      {
        path: '/goals',
        name: 'goals',
        component: GoalsList,
        meta: {
          title: 'Goals',
          protected: true,
          permissions: ['view-goals']
        }
      },

      {
        path: '/skills/matrix',
        name: 'skills-matrix',
        component: () => import('@/components/skills/SkillMatrix'),
        meta: {
          title: 'Skills Matrix',
          protected: false,
          permissions: ['view-skills-matrix']
        }
      },

      {
        path: '/teams',
        name: 'teams',
        component: GroupsList,
        meta: {
          title: 'Groups',
          protected: true,
          permissions: ['view-groups']
        }
      },
      {
        path: '/teams/create',
        name: 'create-groups',
        component: () => import('@/components/groups/Create'),
        meta: {
          title: 'Create Team',
          protected: true,
          permissions: ['create-groups']
        }
      },

      {
        path: '/teams/setup:component(teams|locations)+',
        name: 'teams-setup',
        component: () => import('@/components/chart/Builder'),
        meta: {
          title: 'Chart Builder',
          protected: true,
          permissions: ['chart-builder']
        }
      },

      {
        path: '/privileges',
        name: 'roles',
        component: RolesList,
        meta: {
          title: 'Roles',
          protected: true,
          permissions: ['view-roles-list']
        }
      },
      {
        path: '/privileges/new',
        name: 'create-role',
        component: RoleForm,
        meta: {
          title: 'Create Role',
          protected: true,
          permissions: ['create-role']
        }
      },
      {
        path: '/privileges/edit/:id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
        name: 'edit-role',
        component: RoleForm,
        meta: {
          title: 'Edit Role',
          protected: true,
          permissions: ['edit-role']
        }
      },
      {
        path: '/activities',
        name: 'activities',
        component: () => import('@/components/Activities'),
        meta: {
          title: 'Activities',
          protected: false,
          permissions: ['view-all-activities']
        }
      },
    ]
  },
  {
    path: '*',
    component: Error404,
    meta: {
      title: 'Resource Not Found'
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Error401,
    meta: {
      title: 'Unauthorized'
    }
  }
]

export default routes
