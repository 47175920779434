import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useSkillsStore = defineStore({
  id: 'skills',
  persist: false,
  state: () => ({
    skill: null,
    category: null,
    skills: [],
    categories: [],
    shouldFetchSkills: false,
    shouldFetchCategories: false,
    isLoadingCategories: false,
    isLoadingAssessments: false,

    assessment: null,
    assessments: []
  }),
  getters:{

  },
  actions: {
    getSkill (id, signal) {
      return tenant.skills.get(id, signal)
    },
    getSkills (params, signal) {
      return tenant.skills.list({ params: params, signal: signal }).then(res => {
        this.skills = res.data
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    createSkill (params, signal) {
      return tenant.skills.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateSkill (id, params, signal) {
      return tenant.skills.update(id, params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    getCategories (params, signal) {
      this.isLoadingCategories = true
      return tenant.skills.category.list({ params: params, signal: signal }).then(res => {
        this.categories = res.data
        this.isLoadingCategories = false
        return res
      }).catch(err => {
        console.log(err)
        this.isLoadingCategories = false
        return null
      })
    },
    createSkillCategory (params, signal) {
      return tenant.skills.category.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateSkillCategory (id, params, signal) {
      return tenant.skills.category.update(id, params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    getSkillAssessments (params, signal) {
      this.isLoadingAssessments = true
      return tenant.skills.assessments.list({ params: params, signal: signal }).then(res => {
        this.assessments = res.data
        this.isLoadingAssessments = false
        return res
      }).catch(err => {
        console.log(err)
        this.isLoadingAssessments = false
        return null
      })
    },
    createSkillAssessment (params, signal) {
      return tenant.skills.assessments.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    updateSkillAssessment (id, params, signal) {
      return tenant.skills.assessments.update(id, params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    getResources(skillId, params) {
      return tenant.skills.resource.get(skillId, params)
    },
    createResource(id, params) {
      return tenant.skills.resource.create(id, params)
    },
    updateResource(id, params) {
      return tenant.skills.resource.update(id, params)
    },
    deleteResource(id) {
      return tenant.skills.resource.delete(id)
    }
  }
})
