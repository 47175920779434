<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">

      <template slot="actions">
        <b-link type="button"
                class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white mr-2 btn-sm"
                :to="{ name: 'team-mass-registration' }">
          <font-awesome-icon class="mr-2" icon="users" />Add Employee
        </b-link>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-overlay class="text-center"
                   :show="showOverlay"
                   rounded="sm">
          <employee-table></employee-table>
          <template #overlay>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <br/>
            Please wait...
          </template>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import EmployeeTable from '@/components/employee/EmployeeTable'

export default {
  name: 'EmployeeList',

  components: {
    PageTitle,
    EmployeeTable
  },

  data: () => ({
    heading: 'Team',
    subheading:
        'Team Directory',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    showOverlay: false
  }),
}
</script>


