<template>
  <div style="margin-top: 20vh;" class="text-center">
     <div>
       <b-card-title class="text-transform-none mt-3">
         We couldn't find the page you are looking for.
       </b-card-title>
       <b-button variant="primary" :to="{ name: 'dashboard' }">Home</b-button>
     </div>
      <img src="@/assets/images/errors/error.svg" class="mt-5"/>
  </div>
</template>

<script>
export default {
  name: 'Error404.vue',
  data(){
    return {
      imgUrl: '@/assets/images/errors/error.svg'
    }
  }
}
</script>