<template>
  <b-modal title="Create Job Role"
           size="xxl"
           hide-footer
           hide-header-close
           no-close-on-esc
           no-close-on-backdrop
           scrollable
           v-model="isOpen"
           @hide="onHide">
    <template v-slot:modal-header>
      <p class="modal-title">
        Create Job Roles
      </p>
      <div>
        <b-button size="sm"
                  variant="primary"
                  class="mr-2"
                  :disabled="store.creating || loading"
                  @click="onSubmit">
          Save
        </b-button>
        <b-button size="sm"
                  variant="danger"
                  :disabled="store.creating || loading"
                  @click="onHide">
          Close
        </b-button>
      </div>
    </template>
    <JobRolesEntryForm ref="entryForm"
                       @submitted="onSubmitted"
                       @updated="onUpdated"
                       @created="onCreated"/>
  </b-modal>
</template>

<script>
import JobRolesEntryForm from '@/components/job-roles/JobRolesEntryForm'
import {useJobRoleStore} from '@/stores/jobRole'

export default {
  name: 'CreateFormModal',

  components: {
    JobRolesEntryForm
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  setup(){
    const store = useJobRoleStore()

    return {
      store
    }
  },

  computed:{
    isOpen: {
      get () {
        return this.show
      },
      set (isOpen) {
        return isOpen
      }
    }
  },

  data(){
    return {
      loading: false
    }
  },

  methods: {
    onHide(){
      this.isOpen = false
      this.$emit('onHide')
    },

    onSubmit(){
      this.$refs.entryForm.onSave()
    },
    onSubmitted(){
      this.loading = true
    },

    onCreated(){
      this.loading = false
      this.$emit('onCreated')
    },

    onUpdated(){
      this.loading = false
      this.$emit('onUpdated')
    },
  }
}
</script>
