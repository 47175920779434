<template>
  <div class="d-flex justify-content-center" style="margin-top: 20vh;">
    <b-card
        tag="logout"
        class="mb-2 text-center"
    >
      <div>
        <b-spinner style="width: 2rem; height: 2rem; margin-top: 7px; margin-left: -8px"
                   variant="primary"
                   class="position-absolute"></b-spinner>
        <font-awesome-icon icon="right-from-bracket"
                           class="mt-3"></font-awesome-icon>
      </div>
      <b-card-title class="text-transform-none mt-3">
        Hang on, we're logging you out...
      </b-card-title>
      <b-card-text class="text-muted">
        Have a good day!
      </b-card-text>
    </b-card>
  </div>
</template>

<script>

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faRightFromBracket
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useAuthStore } from '@/stores/auth'
import store from '@/stores'
library.add(faRightFromBracket)
export default {
  components: {
    FontAwesomeIcon
  },

  setup () {
    const authStore = useAuthStore(store)

    return {
      authStore,
    }
  },

  methods: {
    logout(){
      this.authStore.logout().then(() => {
        this.$router.push('/login')
      }).catch(err => {
        if (err.response.status === 401) {
          this.$router.push('/login')
        }
      })
    }
  },

  mounted(){
    if (!this.authStore.authenticated) {
      this.$router.push('/login')
    } else {
      this.logout()
    }
  }
}
</script>
