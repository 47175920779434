<template>
  <div class="mb-5">
    <validation-observer ref="observer">
      <b-card
          header="Basic Information"
          header-tag="header"
      >
        <b-form ref="observer">
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="first_name" class="col-form-label">
                Name
                <br/>
                <small class="text-muted">Your full name</small>
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <validation-provider name="firstname"
                                     rules="required"
                                     vid="first_name"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="text"
                                placeholder="First"
                                aria-describedby="first-name-live-feedback"
                                v-model="employeeClone.first_name"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                @blur="handleBlur($event, 'first_name')"/>
                  <b-form-invalid-feedback id="first_name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div class="mt-1">
                <b-input class="form-control"
                         placeholder="Middle"
                         v-model="employeeClone.middle_name"></b-input>
              </div>
              <div class="mt-1">
                <validation-provider name="lastname"
                                     rules="required"
                                     vid="last_name"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="text"
                                placeholder="First"
                                aria-describedby="last-name-live-feedback"
                                v-model="employeeClone.last_name"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                @blur="handleBlur($event, 'last_name')"/>
                  <b-form-invalid-feedback id="last_name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
<!--                  <div class="mt-1">-->
<!--                     <b-input class="form-control"-->
<!--                             placeholder="Suffix"-->
<!--                             v-model="employeeClone.suffix"></b-input>-->
<!--                  </div>-->
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="gender" class="col-form-label">
                Gender
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <GenderSelector
                    v-model="gender"
                    @onSelect="onGenderSelect"
                />
              </div>
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="birthdate" class="col-form-label">
                Date of Birth
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <DatePicker placeholder="Select date"
                            class="w-100"
                            format="YYYY-MM-DD"
                            type="date"
                            v-model="birthDateValue"
                            :default-value="birthdateMaxDate"
                            :disabled-date="(date) => date >= birthdateMaxDate"
                            :formatter="formatter"
                            @change="onBirthdateChange($event, 'birthdate')"/>
              </div>
            </b-col>
          </div>
        </b-form>
      </b-card>

      <b-card
          header="Contact Information"
          header-tag="header"
          class="mt-1"
      >
        <form class>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="address" class="col-form-label">
                Address
                <br/>
                <small class="text-muted">Your complete address</small>
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <b-form-input type="text"
                              placeholder="Address Line 1"
                              v-model="employeeClone.metafields.address_line_1.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'address_line_1')"/>
              </div>
              <div class="mt-1">
                <b-form-input type="text"
                              placeholder="Address Line 2"
                              v-model="employeeClone.metafields.address_line_2.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'address_line_2')"/>
              </div>
              <div class="mt-1">
                <b-form-input type="text"
                              placeholder="City"
                              v-model="employeeClone.metafields.city.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'city')"/>
              </div>
              <div class="mt-1">
                <b-form-input type="text"
                              placeholder="Country"
                              v-model="employeeClone.metafields.country.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'country')"/>
              </div>
              <div class="mt-1">
                <b-form-input type="text"
                              placeholder="ZIP Code"
                              v-model="employeeClone.metafields.zip.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'zip')"/>
              </div>
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="mobile_no" class="col-form-label">
                Mobile No.
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <b-form-input type="text"
                              placeholder="Mobile No."
                              v-model="employeeClone.metafields.mobile.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'mobile')"/>
              </div>
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="phone_no" class="col-form-label">
                Phone No.
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <b-form-input type="text"
                              placeholder="Phone No."
                              v-model="employeeClone.metafields.phone.value"
                              data-vv-validate-on="blur"
                              @blur="handleMetaFieldsBlur($event, 'phone')"/>
              </div>
            </b-col>
          </div>
        </form>
      </b-card>

      <b-card
          header="Avatar"
          header-tag="header"
          class="mt-1"
          v-if="employeeClone.uuid === authStore.user.uuid"
      >

        <b-card-text class="text-center"
                     v-if="!changeAvatarMode">
          <AvatarComponent size="150"
                           :employee="employeeClone" />
          <br/>
          <div class="mt-5">
            <b-button variant="outline-primary"
                      size="sm"
                      @click="onChangePhoto">Change Photo</b-button>
          </div>
        </b-card-text>
        <b-card-text>
          <b-overlay class="table-more-rows-spinner mt-10"
                     rounded="sm"
                     :show="uploading">
            <b-button variant="danger"
                      id="btn-cancel-uploader"
                      class="float-right"
                      size="sm"
                      v-if="changeAvatarMode && showUploaderCancel"
                      @click="onCancelChangePhoto"><font-awesome-icon icon="times"></font-awesome-icon></b-button>
          <ImageCropUploader v-if="changeAvatarMode"
                             crop-area="circle"
                             :close-on-save="false"
                             :show-preview="false"
                             @fileAdded="onFileAdded"
                             @fileSelected="onFileSelected"
                             @cancel="changeAvatarMode = false"></ImageCropUploader>
            <template #overlay>
              <div class="text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                <br/>
                Uploading image...
              </div>
            </template>
          </b-overlay>
        </b-card-text>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import AvatarComponent from '@/components/employee/AvatarComponent'
import ImageCropUploader from '@/components/image-crop-uploader'
import GenderSelector from '@/components/selectors/gender-selector'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import * as Gender from '@/constants/gender'
import _ from 'lodash'
import {useEmployeeStore} from '@/stores/employee'
import {useAuthStore} from '@/stores/auth'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'AccountDetails',

  components: {
    DatePicker,
    GenderSelector,
    ImageCropUploader,
    FontAwesomeIcon,
    AvatarComponent
  },

  props: {
    employee: {
      type: Object,
    }
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  computed: {
    gender() {
      if (this.employeeClone.metafields.gender && this.employeeClone.metafields.gender.value) {
        return this.employeeClone.metafields.gender.value === Gender.Male.toString() ? {
          id: Gender.Male,
          label: 'Male'
        } : {
          id: Gender.Female,
          label: 'Female'
        }
      }
      return null
    },
    birthdateMaxDate() {
      return window.moment().subtract(18, 'year')._d
    },
    birthDateValue()
    {
      return window.moment(this.employeeClone.metafields.birthdate.value)._d
    }
  },

  data(){
    return {
      formatter: {
        stringify: (date) => {
          return date ? window.moment(date).format('LL') : ''
        },
      },
      employeeClone: null,
      uploading: false,
      changeAvatarMode: false,
      showUploaderCancel: false
    }
  },

  methods: {
    onGenderSelect (data) {
      this.employeeClone.metafields.gender.value = data.id
      this.handleSubmit()
    },

    onBirthdateChange (data) {
      if (data) {
        this.employeeClone.metafields.birthdate.value = data // window.moment(data).format('YYYY-MM-DD')
        this.handleSubmit()
      }
    },

    handleBlur (event, field) {
      const value = event.target.value
      this.$refs.observer.validate().then(res => {
        //only submit if valid and value has changes
        if (res && this.employee[field] !== value) {
          this.handleSubmit()
        }
      })
    },

    handleMetaFieldsBlur (event, field) {
      const value = event.target.value
      this.$refs.observer.validate().then(res => {
        //only submit if valid and value has changes
        if (res && this.employee.metafields[field].value !== value) {
          this.handleSubmit()
        }
      })
    },
    handleSubmit() {

      this.$toast.info('Updating...', {
        hideProgressBar: false,
        icon: true,
        timeout: 1000,
      })

      this.loading = true
      this.employeeStore.updateEmployee(this.employeeClone.uuid, this.employeeClone).then(res => {
        this.employeeStore.employee = res.data.user
        if (this.employeeStore.employee.metafields.birthdate) {
          this.employeeStore.employee.metafields.birthdate.value = new Date(this.employeeStore.employee.metafields.birthdate.value)
        }

        if (this.authStore.user.uuid === this.employeeClone.uuid) {
          this.authStore.setUser(res.data.user)
        }
        this.employeeClone = _.cloneDeep(this.employeeStore.employee)
        this.$toast.success('Changes has been saved.', {
          hideProgressBar: true,
          icon: false
        })
      }).then(() => {
        this.loading = false
      })
    },
    onFileAdded(file) {
      this.uploading = true
      const formData = new FormData()
      formData.append('file', file)
      this.employeeStore.uploadProfilePhoto(this.employeeClone.uuid, formData).then(res => {
        const ts = Date.now()
        const url = `${res.data.path}?${ts}`

        this.employeeStore.employee.metafields.profile_photo.value = url
        this.employeeClone.metafields.profile_photo.value = url
        const index = this.employeeStore.employees.findIndex(item => item.uuid === this.employeeClone.uuid)

        if (index >= 0) {
          this.employeeStore.employees[index].metafields.profile_photo.value = url
        }

        if (this.authStore.user.uuid === this.employeeClone.uuid) {
          this.authStore.user.metafields.profile_photo.value = this.employeeStore.employee.metafields.profile_photo.value
          this.authStore.setUser(this.authStore.user)
        }

        this.changeAvatarMode = false
      }).then(() => {
        this.uploading = false
      })
    },

    onFileSelected() {
      this.showUploaderCancel = false
    },

    onChangePhoto () {
      this.changeAvatarMode = true
      this.showUploaderCancel = true
    },

    onCancelChangePhoto () {
      this.changeAvatarMode = false
      this.showUploaderCancel = false
    }
  },

  mounted() {
    this.employeeClone = _.cloneDeep(this.employee)
  }
}
</script>

<style lang="scss" scoped>
#btn-cancel-uploader {
  position: absolute;
  z-index: 9999;
  right: -10px;
  top: -14px;
}
</style>
