<template>
   <b-card>
        <div class="position-relative row form-group">
          <b-col md="4">
            <label for="role" class="col-form-label">
              Privileges <br/>
              <small class="text-muted">All permission set on respective privilege will be inherited by the user.</small>
            </label>
          </b-col>
          <b-col md="8">
            <div class="mt-2">
              <role-selector v-model="role"
                             :disabled="loading"
                             @onSelect="onSelect"/>
            </div>
          </b-col>
        </div>
    </b-card>
</template>

<script>
import {useEmployeeStore} from '@/stores/employee'
import RoleSelector from '@/components/selectors/role-selector'
import {useAuthStore} from '@/stores/auth'
import _ from 'lodash'
export default {
  name: 'RolesCard',

  components: {
    RoleSelector
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  data(){
    return {
      edit: false,
      loading: false,
      roles: null,
      role: null
    }
  },

  methods: {
    onCancel(){
      this.setValues()
      this.edit = false
    },
    onEdit(){
      this.edit = true
    },
    handleSubmit() {
      this.loading = true
      this.employeeStore.updateRole(this.employeeStore.employee.uuid, { roles: [this.role] }).then(res => {
        if (res.data.status === 'success') {
          this.edit = false
          this.$emit('onSave', this.roles)
        }
      }).then(() => {
        this.loading = false
      })
    },
    onSelect(data) {
      this.role = data
      this.handleSubmit()
    },
    async setValues(){
      this.roles = await _.cloneDeep(this.employeeStore.employee.roles)
      this.role = this.roles[0]
    }
  },
  mounted(){
    this.setValues()
  },
}
</script>
