import axios from 'axios'

window.axios = axios

window.axios.defaults.baseURL = process.env.VUE_APP_URL

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

window.axios.defaults.withCredentials = true

const apiToken = localStorage.getItem('api_token')
if (apiToken) {
  window.axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('api_token')
}

export default async ({ Vue }) => {
  Vue.prototype.$axios = window.axios
}
