<template>
   <b-card>
        <div class="position-relative row form-group">
          <b-col md="4">
            <label for="role" class="col-form-label">
              Teams <br/>
              <small class="text-muted">The team or group where the user belongs to, user can be into multiple teams.</small>
            </label>
          </b-col>
          <b-col md="8">
            <div class="mt-2">
              <TeamSelector
                  v-model="teams"
                  :disabled="loading"
                  :show-loader="loading"
                  :multiple="true"
                  @onSelect="onSelect"
                  @remove="onRemove"/>
            </div>
          </b-col>
        </div>
    </b-card>
</template>

<script>
import {useEmployeeStore} from '@/stores/employee'
import TeamSelector from '@/components/selectors/groups-selector'
import {useAuthStore} from '@/stores/auth'
import _ from 'lodash'
export default {
  name: 'TeamsCard',

  components: {
    TeamSelector
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  data(){
    return {
      loading: false,
      teams: null,
      team: null
    }
  },

  methods: {
    onCancel(){
      this.setValues()
    },
    handleSubmit: _.debounce(function(){
      this.$toast.info('Updating teams...', {
        hideProgressBar: false,
        icon: true,
        timeout: 1000,
      })

      this.loading = true
      this.employeeStore.updateTeams(this.employeeStore.employee.uuid, { teams: this.teams }).then(res => {
        if (res.data.status === 'success') {
          this.employeeStore.employee.groups = res.data.teams
          const index = this.employeeStore.employees.findIndex(item => item.uuid === this.employeeStore.employee.uuid)
          if (index >= 0) {
            this.employeeStore.employees[index].groups = res.data.teams
          }

          if (this.authStore.user.uuid === this.employeeStore.employee.uuid) {
            this.authStore.user.groups = res.data.teams
            this.authStore.setUser(this.authStore.user)
          }
          this.$emit('onSave', this.teams)
          this.$toast.success('User teams has been updated.', {
            hideProgressBar: true,
            icon: false
          })
        } else {
          this.$toast.error('An error has been encountered while processing your request. Please try again later.', {
            hideProgressBar: true,
            icon: false
          })
        }
      }).then(() => {
        this.loading = false
      })
    }, 3000),
    async onSelect(data) {
      await this.teams.push(data)
      this.handleSubmit()
    },
    async onRemove(uuid) {
      const index = await this.teams.findIndex(item => item.uuid === uuid)
      await this.teams.splice(index, 1)
      this.handleSubmit()
    },
    async setValues(){
      this.teams = await _.cloneDeep(this.employeeStore.employee.groups) || []
    }
  },
  mounted(){
    this.setValues()
  },
}
</script>
