<template>
  <div class="mb-5">
    <validation-observer ref="observer">
      <b-card
          no-body
          header="Password"
          header-tag="header"
      >
        <b-list-group class="custom-list-group">
          <b-list-group-item variant="primary"> Changing password will invalidate all logged in sessions.</b-list-group-item>
        </b-list-group>
        <hr class="m-0"/>
        <b-card-body>
          <b-form ref="observer">
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="first_name" class="col-form-label">
                Current Password
                <br/>
                <small class="text-muted">Your current password</small>
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <validation-provider name="current password"
                                     rules="required"
                                     vid="current_password"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="password"
                                placeholder="Current password"
                                aria-describedby="current-password-live-feedback"
                                v-model="current_password"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="current-password-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="first_name" class="col-form-label">
                New Password
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <validation-provider name="new password"
                                     rules="required"
                                     vid="password"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="password"
                                placeholder="New Password"
                                aria-describedby="password-live-feedback"
                                v-model="password"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="password-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
          </div>
          <hr/>
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="first_name" class="col-form-label">
                Verify Password
                <br/>
                <small class="text-muted">Re-enter you new password to verify</small>
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <validation-provider name="verify password"
                                     rules="required|confirmed:password"
                                     vid="confirm_password"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="password"
                                placeholder="Verify password"
                                aria-describedby="confirm-password-live-feedback"
                                v-model="confirm_password"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="confirm-password-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </b-col>
          </div>
        </b-form>
        </b-card-body>

        <template #footer>
          <div class="d-flex justify-content-between w-100">
            <div>
              Update password?
            </div>
            <div class="float-right">
              <b-button size="sm"
                        variant="primary"
                        :disabled="loading"
                        @click="onSubmit">
                <b-spinner label="Loading..."
                           small
                           v-if="loading"></b-spinner>
                {{ loading ? 'Please wait...' : 'Change Password' }}
              </b-button>
            </div>
          </div>
        </template>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css'
import _ from 'lodash'
import {useEmployeeStore} from '@/stores/employee'
import {useAuthStore} from '@/stores/auth'

export default {
  name: 'AccountDetails',

  props: {
    employee: {
      type: Object,
    }
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  data() {
    return {
      formatter: {
        stringify: (date) => {
          return date ? window.moment(date).format('LL') : ''
        },
      },
      employeeClone: null,
      loading: false,
      current_password: null,
      confirm_password: null,
      password: null
    }
  },

  methods: {
    onSubmit() {
      this.$refs.observer.validate().then(res => {
        if (res) {
          this.handleSubmit()
        }
      })
    },
    handleSubmit() {
      this.loading = true
      this.employeeStore.updatePassword(this.employeeClone.uuid, {
        current_password: this.current_password,
        password: this.password,
        confirm_password: this.confirm_password,
      }).then(res => {
        if (res.data.status === 'success') {
          this.$toast.success('Changes has been saved.', {
            hideProgressBar: true,
            icon: false
          })
          if (this.authStore.user.uuid === this.employeeClone.uuid) {
            this.$router.push({ name: 'logout' })
          }
        } else {
          this.$toast.error('Error while processing your request.', {
            hideProgressBar: true,
            icon: false
          })
        }

      }).then(() => {
        this.loading = false
      })
    }
  },

  mounted() {
    this.employeeClone = _.cloneDeep(this.employee)
  }
}
</script>

<style lang="scss" scoped>
  .custom-list-group {
    border-radius: 0 !important;

    .list-group-item {
      border: none;
      border-radius: 0;
    }
  }
</style>
