<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div class="h-100">
        <b-row class="h-100 no-gutters">
          <b-col lg="7" md="12" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <img src="@/assets/images/extreme-bg.jpg" class="img-responsive position-absolute w-100 h-100 "/>
          </b-col>
          <b-col lg="5" class="h-100 d-flex bg-white justify-content-center align-items-center">
            <b-col lg="9" md="10" sm="12" class="mx-auto app-login-box">
              <div class="app-logo" />
              <h4 class="mb-0">
                <div>Welcome back,</div>
                <span>Please sign in to your account.</span>
              </h4>
              <div class="divider" />
              <div>
                <validation-observer ref="observer"
                                     v-slot="{ reset, valid }">
                  <b-form @submit.prevent="login">
                  <b-row form>
                    <b-col md="12">
                      <b-form-group>
                        <label for="email">Email</label>
                        <validation-provider name="email"
                                             rules="required|email"
                                             vid="email"
                                             v-slot="{ classes, errors }">
                          <b-form-input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email Address"
                              v-model="authData.email"
                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                          />
                          <b-form-invalid-feedback id="email-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <label for="password">Password</label>
                        <validation-provider name="password"
                                             rules="required"
                                             vid="password"
                                             v-slot="{ classes, errors }">
                          <b-input-group
                              class="mt-3"
                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                          >
                            <template #append>
                              <b-input-group-text
                                  class="cursor-pointer"
                                  @click="showPassword = !showPassword"
                              >
                                <i :class="['fa', showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                              </b-input-group-text>
                            </template>
                            <b-form-input
                                name="password"
                                id="password"
                                placeholder="Password"
                                v-model="authData.password"
                                :type="showPassword ? 'text' : 'password'"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                            />
                          </b-input-group>
                          <b-form-invalid-feedback id="password-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12" v-if="errorMessage">
                      <b-form-group>
                        <b-alert show
                                 variant="danger"
                                 style="height: 40px"
                                 class="p-2">
                          {{ errorMessage }}
                        </b-alert>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="d-flex justify-content-around align-items-center">
                    <div class="ml-auto">
                      <b-link :to="{ name: 'forgot-password' }" class="btn btn-link"> Recover Password </b-link>
                      <b-button variant="primary"
                                type="submit"
                                size="sm"
                                :disabled="isBusy || isSocialLoggingIn || !valid">
                        <b-spinner label="Loading..."
                                   small
                                   v-if="isBusy" />
                        {{ isBusy ? 'Authenticating...' : 'Login' }}
                      </b-button>
                    </div>
                  </div>
                </b-form>
                </validation-observer>
              </div>
              <hr />
              <div>
                <FacebookLogin
                    :disabled="isSocialLoggingIn || isBusy"
                    @connected="handleFacebookConnected"
                />
              </div>
            </b-col>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import { useAuthStore } from '@/stores/auth'
import store from '@/stores'
import FacebookLogin from '@/components/social-media/FacebookLogin'
import * as storage from '../plugins/helpers/storage'

export default {
  components: {FacebookLogin},
  setup () {
    const authStore = useAuthStore(store)
    const authData = {
      email: null,
      password: null,
      rememberMe: false
    }

    return {
      authStore,
      authData
    }
  },

  data: () => ({
    slickOptions6: {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true
    },

    slide: 0,
    sliding: null,
    isBusy: false,
    isSocialLoggingIn: false,
    registerUrl: process.env.VUE_APP_URL + '/register',
    forgotPasswordUrl: process.env.VUE_APP_URL + '/forgot-password',
    toastSetting: {
      hideProgressBar: true,
      icon: false
    },
    errorMessage: null,
    showPassword: false
  }),

  methods: {
    handleClick (newTab) {
      this.currentTab = newTab
    },
    next () {
      this.$refs.slick.next()
    },

    prev () {
      this.$refs.slick.prev()
    },

    reIni () {
      this.$nextTick(() => {
        this.$refs.slick.reSlick()
      })
    },

    onSlideStart () {
      this.sliding = true
    },
    onSlideEnd () {
      this.sliding = false
    },
    async login () {
      this.isBusy = true
      this.errorMessage = null
      await this.authStore.getCsrfCookie()
      await this.authStore.login(this.authData.email, this.authData.password, this.authData.rememberMe).then(() => {
        this.isBusy = false
        this.errorMessage = null
        this.$router.push({ name : 'dashboard' })
      }).catch((err) => {
        this.isBusy = false
        this.errorMessage = 'Unable to process your request. Please try again.'
        if (err.response.status === 401) {
          this.errorMessage = 'Invalid email or password.'
        }
      })
    },
    handleFacebookConnected (data) {
      this.authStore.fbLogin({ provider_id: data.userID }).then(res => {
        storage.local.setItem('fbHermes', JSON.stringify(data) )
        this.isSocialLoggingIn = false
        this.errorMessage = null
        this.$router.push({ name : 'dashboard' })
      }).catch(err => {
        this.errorMessage = err.response.data.error
        console.log(err)
      })
    }
  },
  mounted () {
    this.authStore.check().then(() => {
      this.$router.push({ name: 'dashboard' })
    })
  }
}
</script>
