<template>
    <VueMultiselect track-by="id"
                    label="label"
                    placeholder="Select gender"
                    selected-label=""
                    select-label=""
                    deselect-label=""
                    v-model="gender"
                    :allowEmpty="false"
                    :options="options"
                    @select="onSelect">
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.label }} </span>
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.label }}</span>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import * as Gender from '@/constants/gender'
export default {
  name: 'gender-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: [Object, null]
    }
  },

  setup () {

    const options = [
      {
        id: Gender.Male.toString(),
        label: 'Male'
      },
      {
        id: Gender.Female.toString(),
        label: 'Female'
      }
    ]
    
    return {
      options,
    }
  },

  data () {
    return {
      gender: this.value
    }
  },
  methods: {
    onSelect (data) {
      this.$emit('onSelect', data)
    }
  }
}
</script>
