import { useAuthStore } from '@/stores/auth'
import store from '@/stores'
import * as storage from '@/plugins/helpers/storage'

const facebook = {
  init () {
    return new Promise((resolve, reject) => {
      window.fbAsyncInit = function() {
        window.FB.init(
          {
            appId: process.env.VUE_APP_FB_APP_ID,
            cookie: true,
            xfbml: true,
            version: "v17.0"
          }
        )

        // auto authenticate with the api if already logged in with facebook
        window.FB.getLoginStatus(({ authResponse }) => {
          if (authResponse) {
            const authStore = useAuthStore(store)
            authStore.fbLogin({ provider_id: authResponse.userID }).then(res => {
              storage.local.setItem('fbHermes', JSON.stringify(authResponse) )
              resolve()
            })
          } else {
            resolve();
          }
        });
      };

      this.loadSDK(document, "script", "facebook-jssdk")
    })
  },
  loadSDK (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  },
  logout () {
    const fbData = JSON.parse(storage.local.getItem('fbHermes'))
    const access_token = fbData?.accessToken
    window.FB.api('/me/permissions', { access_token }, 'delete', null, () => window.FB.logout());
    // return new Promise((resolve, reject) => {
    //   window.FB.logout(function(response) {
    //     resolve()
    //   });
    // })
  }
}

export default facebook
