<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  class="text-white"
                  size="sm"
                  :disabled="loading"
                  @click="showFormDialog = true">
          <font-awesome-icon class="mr-2" icon="plus" />Create Team
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout">
      <div class="app-inner-layout__wrapper padded">
        <div class="app-inner-layout__content card">
          <v-data-table
              loading-text="Loading... Please wait"
              height="55vh"
              :loading="loading"
              :fixed-header="true"
              :headers="columns"
              :items="groups"
              :items-per-page="10"
              :hide-default-header="false"
              :sort-by="['name']"
              :sort-desc="[false, true]"
              class="elevation-1"
          >
            <template v-slot:top>
              <div class="p-3">
                <b-row>
                  <b-col sm="12"
                         md="12"
                         class="d-flex justify-content-end">
                    <div class="w-25">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <font-awesome-icon icon="search" />
                          </div>
                        </div>
                        <input placeholder="Search..."
                               type="text"
                               class="form-control"
                               v-model="searchText"
                               @input="onSearchInputChange()"/>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <span :style="`color:` + item.color || `#FFFF00`">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <b-dropdown class="mx-1"
                          variant="white"
                          right
                          text="menu"
                          no-caret>
                <b-dropdown-item @click="onEdit(item)">
                  <font-awesome-icon icon="pencil" class="mr-2"/>
                  Edit
                </b-dropdown-item>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </template>
              </b-dropdown>
            </template>
          </v-data-table>

          <b-modal :title="(team ? 'Edit' : 'Add') + ' Team'"
                   hide-footer
                   hide-header-close
                   no-close-on-esc
                   no-close-on-backdrop
                   v-model="showFormDialog"
                   @hide="showFormDialog = false; team = null;">
            <TeamForm :team="team"
                      @onCancel="showFormDialog = false"
                      @onUpdated="onTeamUpdated"
                      @onCreated="onTeamCreated"/>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import TeamForm from '@/components/groups/Form'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faThumbsDown, faPlus, faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {useGroupStore} from '@/stores/groups'
import _ from 'lodash'

library.add(faThumbsUp, faThumbsDown, faPlus,faEllipsisV, faPencil)
export default {
  name: 'Teams',
  components: {
    PageTitle,
    FontAwesomeIcon,
    TeamForm
  },
  setup(){
    const store = useGroupStore()

    return {
      store
    }
  },
  data: () => ({
    heading: 'Teams',
    subheading:
        'Your company\'s group or team.',
    icon: 'pe-7s-door-lock icon-gradient bg-tempting-azure',
    columns: [
      {
        value: 'name',
        text: 'Name',
        sortable: true
      },
      {
        value: 'actions',
        label: '',
        sortable: false
      }
    ],
    loading: false,
    showFormDialog: false,
    team: null,
    searchText: '',
    groups: []
  }),
  methods: {
    onEdit(data){
      this.team = data
      this.showFormDialog = true
    },
    onTeamUpdated(data){
      this.showFormDialog = false
      const index = this.store.groups.findIndex(item => item.uuid === data.uuid)
      if (index >= 0) {
        this.store.groups[index] = data
      }
    },
    onTeamCreated(){
      this.groups = this.store.groups
      this.showFormDialog = false
    },
    onSearchInputChange: _.debounce(function() {
      if (this.searchText && this.searchText.length > 0) {
        this.groups = this.groups.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()))
      } else {
        this.groups = this.store.groups
      }
    }, 1000),
  },
  mounted () {
    if (this.store.groups.length < 1) {
      this.loading = true
      this.store.groups = []
      this.store.getGroups().then(() => {
        this.groups = this.store.groups
        this.loading = false
      })
    } else {
      this.groups = this.store.groups
    }
  }
}
</script>
