<template>
  <VueMultiselect track-by="id"
                  style="width: 15vw;"
                  label="name"
                  placeholder="Select employee or group"
                  selected-label=""
                  select-label=""
                  deselect-label="x"
                  group-values="items"
                  group-label="label"
                  clearable
                  v-model="item"
                  :group-select="false"
                  :loading="loading"
                  :disabled="loading"
                  :options="formattedData"
                  @select="onSelect"
                  @remove="onRemove">
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import {useEmployeeStore} from '@/stores/employee'

export default {
  name: 'organization-entry-selector',

  components: {
    VueMultiselect
  },

  props:{
    orgData: {
      type:[Array, null],
      default: null,
      required: false
    },
    fetchData: {
      type:[Boolean],
      default: true
    }
  },

  setup(){
    const employeeStore = useEmployeeStore()

    return {
      employeeStore
    }
  },

  computed: {
    formattedData(){
      if (!this.data) {
        return []
      }
      return [
        {
          label: 'Team',
          items: this.data.filter(item => item.component_class === 'App\\Models\\User')
        },
        {
          label: 'Group',
          items: this.data.filter(item => item.component_class === 'App\\Models\\Group')
        }
      ]
    }
  },

  data(){
    return {
      data: null,
      item: null,
      abortController: null,
      loading: false
    }
  },
  methods:{
    getName(data){
      console.log(data)
      if (data.option.component_class === 'App\\Models\\User') {
        return data.option.component.first_name + ' ' + data.option.component.last_name
      } else {
        return data.option.component.name
      }
    },
    onSelect(item){
      this.$emit('onSelect', item)
    },
    onRemove(){
      this.$emit('onRemove')
    },
    getData(){
      this.employeeStore.getOrganization({ params: { type: 'flat' } }, this.abortController.signal).then(async (res) => {
        this.employeeStore.organization = res.data
        this.data = this.employeeStore.organization
      }).then(() => {
        this.loading = false
      })
    }
  },

  mounted(){
    this.abortController = new AbortController()
    if (!this.orgData) {
      this.getData()
    } else {
      this.data = this.orgData
    }
  }
}
</script>