<template>
  <div>
    <div class="nav-justified">
      <div class="text-uppercase font-weight-bold ml-4 mr-4">Filter</div>
      <div class="scroll-gradient">
        <div class="scroll-area-lg">
          <VuePerfectScrollbar class="scrollbar-container">
            <div class="mt-1">
              <div>
                <b-nav-item  v-b-toggle.skills class="text-left">Skills</b-nav-item>
                <b-collapse id="skills" class="mt-2" visible>
                  <b-card class="border-0 box-shadow-none" no-body>
                    <b-card-body class="pt-0">
                      <b-form-group>
                        <SkillSelector v-model="store.filters.current.skill"
                                       :allow-empty="true"
                                       @onSelect="onSkillSelected"
                                       @onRemove="store.filters.currentskill = null"></SkillSelector>
                      </b-form-group>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </div>
              <hr class="mt-0 mb-0"/>
              <div>
                <b-nav-item  v-b-toggle.location class="text-left text--black" >Location</b-nav-item>
                <b-collapse id="location" class="mt-2" visible>
                  <b-card class="border-0 box-shadow-none" no-body>
                    <b-card-body class="pt-0">
                      <b-form-group>
                        <LocationSelector v-model="store.filters.current.location"
                                          :allow-empty="true"
                                          @onSelect="onLocationSelected"
                                          @onRemove="store.filters.currentlocation = null"></LocationSelector>
                      </b-form-group>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </div>
              <hr class="mt-0 mb-0"/>
              <div>
                <b-nav-item  v-b-toggle.group class="text-left text--black" >Team</b-nav-item>
                <b-collapse id="group" class="mt-2" visible>
                  <b-card class="border-0 box-shadow-none" no-body>
                    <b-card-body class="pt-0">
                      <b-form-group>
                        <GroupSelector v-model="store.filters.current.group"
                                       :allow-empty="true"
                                       @onSelect="onGroupSelected"
                                       @onRemove="store.filters.currentgroup = null"></GroupSelector>
                      </b-form-group>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </div>
              <div class="filter-fields">
                <slot name="fields"></slot>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item-divider nav-item mt-0"></li>
        <li class="nav-item-btn nav-item d-flex justify-content-between">
          <div>
            <button class="btn-shadow btn-wid btn btn-success btn-sm mr-1"
                    :disabled="!store.filterHasChanges"
                    @click="onApply">Apply</button>
            <button class="btn-shadow btn-wid btn btn-outline-primary btn-sm mr-1"
                    :disabled="!store.filterHasChanges"
                    @click="onResetFilter">Reset</button>
          </div>

          <button class="btn-shadow btn-wide btn btn-outline-danger btn-sm"
                  @click="onClose">Close</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import SkillSelector from '@/components/selectors/skill-selector'
import LocationSelector from '@/components/selectors/location-selector'
import GroupSelector from '@/components/selectors/groups-selector'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faCog, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useEmployeeStore } from '@/stores/employee'
import _ from 'lodash'
library.add(faArrowUp, faCog, faFilter)

export default {
  name: 'EmployeeFilter',
  components: {
    VuePerfectScrollbar,
    // eslint-disable-next-line vue/no-unused-components
    'font-awesome-icon': FontAwesomeIcon,
    SkillSelector,
    LocationSelector,
    GroupSelector
  },

  setup(){
    const store = useEmployeeStore()
    return {
      store
    }
  },

  data: () => ({
    loading: false
  }),

  methods: {
    onApply(){
      this.$emit('apply', this.store.filters.current)
    },

    onLocationSelected(data){
      this.store.filters.current.location = data ? data : null
    },
    onSkillSelected(data){
      this.store.filters.current.skill = data ? data : null
    },
    onGroupSelected(data){
      this.store.filters.current.group = data ? data : null
    },
    onClose () {
      this.$emit('close')
    },
    onResetFilter() {
      this.store.filters.current = _.cloneDeep(this.store.filters.default)
      this.$emit('reset')
    }
  },
}
</script>

