<template>
   <b-card>
        <div class="position-relative row form-group">
          <b-col md="4">
            <label for="role" class="col-form-label">
              Location <br/>
              <small class="text-muted">The location where the user belongs to.</small>
            </label>
          </b-col>
          <b-col md="8">
            <div class="mt-2">
              <LocationSelector
                  v-model="location"
                  :disabled="loading"
                  :show-loader="loading"
                  @input="onInput"/>
            </div>
          </b-col>
        </div>
    </b-card>
</template>

<script>
import {useEmployeeStore} from '@/stores/employee'
import LocationSelector from '@/components/selectors/location-selector'
import {useAuthStore} from '@/stores/auth'
import _ from 'lodash'
export default {
  name: 'LocationsCard',

  components: {
    LocationSelector
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  data(){
    return {
      edit: false,
      loading: false,
      locations: null,
      location: null
    }
  },

  methods: {
    onCancel(){
      this.setValues()
      this.edit = false
    },
    onEdit(){
      this.edit = true
    },
    handleSubmit() {
      this.$toast.info('Updating location...', {
        hideProgressBar: false,
        icon: true,
        timeout: 1000,
      })

      this.loading = true
      this.employeeStore.updateLocation(this.employeeStore.employee.uuid, { location: this.location }).then(res => {
        if (res.data.status === 'success') {
          this.edit = false
          this.employeeStore.employee.locations = res.data.locations
          const index = this.employeeStore.employees.findIndex(item => item.uuid === this.employeeStore.employee.uuid)
          if (index >= 0) {
            this.employeeStore.employees[index].locations = res.data.locations
          }

          if (this.authStore.user.uuid === this.employeeStore.employee.uuid) {
            this.authStore.user.locations = res.data.locations
            this.authStore.setUser(this.authStore.user)
          }
          this.$emit('onSave', this.location)
          this.$toast.success('User locations has been updated.', {
            hideProgressBar: true,
            icon: false
          })
        } else {
          this.$toast.error('An error has been encountered while processing your request. Please try again later.', {
            hideProgressBar: true,
            icon: false
          })
        }
      }).then(() => {
        this.loading = false
      })
    },
    onInput(data) {
      this.location = data
      this.handleSubmit()
    },
    async setValues(){
      this.locations = await _.cloneDeep(this.employeeStore.employee.locations)
      if (this.locations && this.locations.length > 0) {
        this.location = this.locations[0]
      }
    }
  },
  mounted(){
    this.setValues()
  },
}
</script>
