<template>
  <div class="mb-5">
    <validation-observer ref="observer">
      <b-card
          header="Email Addresses"
          header-tag="header"
      >
        <b-list-group flush>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <div>
              {{ employeeClone.email }}
              <b-badge variant="primary" class="mr-1">Primary</b-badge>
              <b-badge :variant="!employeeClone.email_verified_at ? 'danger' : 'success'">{{ !employeeClone.email_verified_at ? 'Unverified' : 'Verified' }}</b-badge>
            </div>

            <b-button size="sm"
                      variant="outline-success"
                      v-if="!employeeClone.email_verified_at"
                      :disabled="sending"
                      @click="handleSendVerification">Resend Verification
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card
          v-if="enableAddSecondaryEmail"
          class="mt-1"
          header="Add Secondary Emails"
          header-tag="header"
      >
        <b-form ref="observer">
          <div class="position-relative row form-group">
            <b-col md="4">
              <label for="first_name" class="col-form-label">
                Additional Email
                <br/>
                <small class="text-muted">Designate an alternative email for your account</small>
              </label>
            </b-col>
            <b-col md="8">
              <div>
                <validation-provider name="email"
                                     rules="required|email"
                                     vid="email"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="text"
                                placeholder="e.g. secondary@email.com"
                                aria-describedby="secondary-email-live-feedback"
                                v-model="email"
                                data-vv-validate-on="blur"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="secondary-email-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </div>

            </b-col>
          </div>
        </b-form>

        <template #footer>
          <div class="d-flex justify-content-between">
            <div>
              Add this new email address to your account?
            </div>
            <div>
              <b-button size="sm"
                        variant="outline-secondary"
                        class="mr-1">Cancel</b-button>
              <b-button size="sm"
                        variant="primary">Save</b-button>
            </div>
          </div>
        </template>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import _ from 'lodash'
import {useEmployeeStore} from '@/stores/employee'
import {useAuthStore} from '@/stores/auth'
import { auth } from '@/api/v1'
export default {
  name: 'EmailAddresses',

  props: {
    employee: {
      type: Object,
    }
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      employeeStore,
      authStore
    }
  },

  computed: {

  },

  data(){
    return {
      employeeClone: null,
      sending: false,
      email: null,
      enableAddSecondaryEmail: false
    }
  },

  methods: {
    handleSubmit() {
      this.loading = true
      this.employeeStore.updateEmployee(this.employeeClone.uuid, this.employeeClone).then(res => {
        this.employeeStore.employee = res.data.user
        if (this.employeeStore.employee.metafields.birthdate) {
          this.employeeStore.employee.metafields.birthdate.value = new Date(this.employeeStore.employee.metafields.birthdate.value)
        }

        if (this.authStore.user.uuid === this.employeeClone.uuid) {
          this.authStore.setUser(res.data.user)
        }
        this.employeeClone = _.cloneDeep(this.employeeStore.employee)
      }).then(() => {
        this.loading = false
      })
    },
    handleSendVerification(email) {
      this.sending = true
      auth.resendWelcomeEmail(this.employeeClone.uuid, { email: email }).then(res => {
        console.log(res)
        this.sending = false
      })
    }
  },

  mounted() {
    if (this.employeeStore.employee.uuid !== this.authStore.user.uuid && !this.authStore.hasAdministrativeRights) {
      this.$router.push({ name: 'team-profile' })
    }
    this.employeeClone = _.cloneDeep(this.employee)
  }
}
</script>

<style lang="scss" scoped>
#btn-cancel-uploader {
  position: absolute;
  z-index: 9999;
  right: -10px;
  top: -14px;
}
</style>
