<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon"
    />

    <b-container>
      <div class="row mt-4">
      <b-col md="8"
             offset-md="2">
        <b-overlay class="table-more-rows-spinner mt-10"
                   rounded="sm"
                   :show="loading"
        >
          <div class="main-card mb-3 card">
          <div class="card-header">
            Details
          </div>
          <validation-observer ref="observer"
                               v-slot="{ reset, invalid }">
            <b-form ref="form"
                  @submit.prevent="onSubmit"
                  @reset.prevent="reset(onReset)">
              <div class="card-body">
                <b-form-group label="Template Name *">
                  <validation-provider name="template name"
                                       rules="required"
                                       vid="template_name"
                                       v-slot="{ classes, errors }"
                  >
                    <b-form-input placeholder="Template name"
                                  ref="template_name"
                                  v-model="template.name"
                                  :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                  aria-describedby="template_name-live-feedback">
                    </b-form-input>
                    <b-form-invalid-feedback id="template_name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Description">
                    <b-form-input
                        placeholder="Enter Description"
                        v-model="template.description">
                    </b-form-input>
                </b-form-group>
              </div>
              <hr/>
              <div class="card-body">
                <div v-for="(question, index) in template.data"
                     :key="index"
                     class="mb-4">
                  <validation-provider name="template question"
                                       rules="required"
                                       :vid="`template_question_` + index"
                                       v-slot="{ classes, errors }">
                    <b-input-group :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']">
                      <template #prepend>
                        <b-input-group-text >{{ index + 1 }}</b-input-group-text>
                      </template>

                      <b-form-input placeholder="Enter question here.."
                                    v-model="question.item"
                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                    :aria-describedby="`template_question-`+ index +`-live-feedback`">
                      </b-form-input>

                      <b-input-group-append v-if="template.data.length > 1">
                        <b-button variant="outline-secondary"
                                  v-b-tooltip.hover
                                  title="Remove"
                                  @click="remove(index)">
                          <font-awesome-icon icon="xmark" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback :id="`template_question-`+ index +`-live-feedback`">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
              </div>
              <hr />
              <div class="card-body">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-success btn-sm"
                          type="button"
                          @click="add">Add Question Field</button>
                </div>
              </div>

              <div class="d-block text-right card-footer">
                <button class="mr-2 btn btn-link btn-sm"
                        type="reset"
                        :disabled="isStoring"
                        @click="onReset">Reset</button>
                <button class="btn btn-primary btn-sm"
                        type="submit"
                        :disabled="invalid || isStoring">
                  <b-spinner label="Loading..."
                             small
                             v-if="isStoring"></b-spinner>
                  {{ isStoring ? 'Saving template...' : 'Save' }}
                </button>
              </div>
            </b-form>
          </validation-observer>
        </div>
          <template #overlay>
            <div class="d-flex flex-column justify-content-center align-items-center">
              <b-spinner variant="primary" label="Spinning"></b-spinner>
              <br/>
              Please wait...
            </div>
          </template>
        </b-overlay>
      </b-col>
   </div>
    </b-container>
  </div>
</template>

<script>
import { useAssessmentStore } from '@/stores/assessment'
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrash, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faTrash, faPlus, faXmark)

import _ from 'lodash'

export default {
  name: 'TemplateForm',

  components: {
    PageTitle,
    'font-awesome-icon': FontAwesomeIcon
  },

  setup () {
    const store = useAssessmentStore()

    return {
      store
    }
  },

  computed:{
    isEmpty () {
      return this.template.data.length === 1 && this.template.data[0].item.length < 1
    }
  },

  data: () => ({
    heading: 'Create Template',
    subheading:
        'Create a new chat template.',
    icon: 'pe-7s-comment icon-gradient bg-tempting-azure',
    isStoring: false,
    template: {
      name: '',
      description: '',
      data: [
        {
          item : ''
        }
      ]
    },
    templateCopy: null,
    loading: false
  }),

  methods: {
    onReset () {
      if (this.store.template) {
        this.template = _.cloneDeep(this.store.template)
      } else {
        this.template = {
          name: '',
          description: '',
          data: [
            {
              item: ''
            }
          ]
        }
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    add () {
      this.template.data.push({ item: '' })
    },
    remove (index) {
      this.template.data.splice(index, 1)
    },

    onSubmit () {
      if (this.isEmpty) {
        return
      }

      if (this.store.template && this.$route.params.id) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }

    },

    async handleCreate() {
      const confirmSave = await this.$dialog.confirm({ text: 'Do you wish to save this template?', actions: ['No', 'Yes'] })

      if (confirmSave === 'Yes') {
        this.isStoring = true
        const t = {...this.template}
        t.data = JSON.stringify(t.data)

        this.store.storeTemplate(t).then(async res => {
          if (res.data.status === 'success') {
            this.onReset()
            this.store.templates.push(res.data.template)
            const confirm = await this.$dialog.confirm({text: 'Template has been saved. Do you wish to create a new one?', actions: ['No', 'Yes']})

            if (confirm === 'No') {
              this.$router.push({name: 'assessment-templates'})
            }
          }
        }).catch(err => {
          console.log(err)
          this.$toast.error('Error while processing your request.', {
            hideProgressBar: true,
            icon: false
          })
        }).then(() => {
          this.isStoring = false
        })
      }
    },

    async handleUpdate() {
      const confirmSave = await this.$dialog.confirm({ text: 'Do you wish to save changes to this template?', actions: ['No', 'Yes'] })

      if (confirmSave === 'Yes') {
        this.isStoring = true
        const t = {...this.template}
        t.data = JSON.stringify(t.data)

        this.store.updateTemplate(this.template.uuid, t).then(async res => {
          if (res.data.status === 'success') {
            this.onReset()
            this.store.templates.push(res.data.template)
            const confirm = await this.$dialog.confirm({text: 'Template has been saved. Do you wish to create a new one?', actions: ['No', 'Yes']})

            if (confirm === 'No') {
              this.$router.push({name: 'assessment-templates'})
            }
          }
        }).catch(err => {
          console.log(err)
          this.$toast.error('Error while processing your request.', {
            hideProgressBar: true,
            icon: false
          })
        }).then(() => {
          this.isStoring = false
        })
      }
    },

    initValue() {
      this.template = _.cloneDeep(this.store.template)
    }
  },

  mounted() {

    if (this.$route.params.id) {
      this.heading = 'Edit Template'
      this.subheading = 'Edit an existing chat template.'
    }

    if (this.$route.params.id && (!this.store.template || this.$route.params.id !== this.store.template.uuid)) {
      this.loading = true
      this.store.getTemplate(this.$route.params.id).then(() => {
        this.initValue()
        this.loading = false
      })
    } else if (this.store.template && this.$route.params.id === this.store.template.uuid) {
      this.initValue()
    }
  }
}
</script>
