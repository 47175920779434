<template>
  <div></div>
</template>

<script>
import * as storage from '../plugins/helpers/storage'

export default {
  name: 'IdleHandler',

  data(){
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
    }
  },

  methods: {
    setTimers(){
      this.logoutTimer = setTimeout(this.logout, 60 * 1000 * (process.env.VUE_APP_IDLE_LOGOUT || 30))
    },

    resetTimers(){
      clearTimeout(this.logoutTimer)
      this.setTimers()
    },

    logout() {
      this.$router.push({ name: 'logout' })
    }
  },

  mounted() {
    if (storage.local.getItem('api_token') !== null) {
      this.events.forEach(function(event){
        window.addEventListener(event, this.resetTimers)
      }, this)

      this.setTimers()
    }
  },

  destroyed() {
    this.events.forEach(function(event){
      window.removeEventListener(event, this.resetTimers)
    }, this)
    clearTimeout(this.logoutTimer)
  }
}
</script>
