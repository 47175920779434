<template>
  <div>
    <v-card>
      <v-card-text>
        <FacebookLogin @connected="handleFBConnected"></FacebookLogin>
        <EmailLogin class="ml-4" @login="handleSwitchToEmail"></EmailLogin>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {auth} from '@/api/v1'
import FacebookLogin from '@/components/social-media/FacebookLogin'
import EmailLogin from '@/components/social-media/EmailLogin'
import {useAuthStore} from '@/stores/auth'
export default defineComponent({
  name: 'SocialLogin',
  components: {FacebookLogin, EmailLogin},
  setup () {
    const authStore = useAuthStore()
    return {
      authStore
    }
  },
  methods: {
    handleFBConnected (data) {
      auth.social.update('facebook', {
        provider_id: data.userID,
        data: {
          provider: 'facebook',
          access_token: data.accessToken,
          expires_in: data.expiresIn ,
          expires_at: data.data_access_expiration_time,
          signed_request: data.signedRequest,
        }
      }).then((res) => {
        this.authStore.user = res.data.user
      })

    },
    handleSwitchToEmail () {
      auth.social.update('email').then((res) => {
        this.authStore.user = res.data.user
      })
    }
  }
})
</script>
