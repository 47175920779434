<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon"/>

    <div class="app-inner-layout">
      <div class="app-inner-layout__wrapper padded">
        <div class="app-inner-layout__content card">
          <div class="app-inner-layout__top-pane">
            <div class="pane-left">
              <b-button type="button"
                        variant="primary"
                        class="text-white mr-1"
                        :disabled="loading"
                        @click="fitOnScreen">Fit On Screen</b-button>
            </div>
            <div class="pane-right">
              <organization-entry-selector v-if="employeeStore.organization.length > 0"
                                           class="mt-1"
                                           :fetch-data="false"
                                           :org-data="employeeStore.organization"
                                           @onSelect="onItemSelected"
                                           @onRemove="onItemRemoved"/>
              <b-button type="button"
                        variant="primary"
                        class="ml-1 text-white"
                        :disabled="!selected"
                        @click="locate">Locate</b-button>
              <b-button type="button"
                        variant="primary"
                        class="ml-1 text-white"
                        :disabled="loading"
                        @click="refresh">
                <font-awesome-icon icon="refresh"></font-awesome-icon>
              </b-button>
            </div>
          </div>
          <b-overlay class="text-center mt-10"
                     :show="loading"
                     rounded="sm">
            <div class="bg-white mh-v-10 is-bordered">
              <div ref="svgElementContainer" ></div>
            </div>
            <p class="text-center" v-if="!loading && (!this.data || this.data.length < 1)">No data to display</p>
            <template #overlay>
              <b-spinner variant="primary" label="Spinning"></b-spinner>
              <br/>
              Fetching chart data...
            </template>
          </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { useEmployeeStore } from '@/stores/employee'

import { OrgChart } from 'd3-org-chart'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faPlus, faEllipsisV, faEye, faRefresh } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import OrganizationEntrySelector from '@/components/selectors/organization-entry-selector'
library.add(faStar, faPlus,faEllipsisV, faEye, faRefresh)

export default {
  name: 'EmployeeOrgChart',

  components: {
    OrganizationEntrySelector,
    'font-awesome-icon': FontAwesomeIcon,
    PageTitle
  },

  setup() {
    const employeeStore = useEmployeeStore()

    return {
      employeeStore
    }
  },

  data: () => ({
    heading: 'Team Org Chart',
    subheading:
        'Team organizational chart.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    searchText: '',
    abortController : null,
    data: null,
    chartReference: null,
    selected: null,
    loading: false
  }),

  methods: {
    fitOnScreen() {
      this.chartReference.fit()
    },
    async formatChartData() {
      this.employeeStore.organization.forEach((value) => {
        value.id = value.component_id
        if (value.component_class === 'App\\Models\\User') {
          value.name = value.component.first_name + ' ' + value.component.last_name
        }

        if (value.component_class === 'App\\Models\\Group') {
          value.name = value.component.name
        }

        value.parentId = value.parent_id || ''
      })
    },
    loadList() {
      this.loading = true
      this.employeeStore.getOrganization({ params: { type: 'flat' } }, this.abortController.signal).then(async (res) => {
        if (res.data.length > 0) {
          this.employeeStore.organization = res.data
          await this.formatChartData()
          this.data = this.employeeStore.organization
          this.render()
        }
      }).then(() => {
        this.loading = false
      })
    },
    render() {
      if (!this.chartReference) {
        this.chartReference = new OrgChart()
      }

      this.chartReference
        .container(this.$refs.svgElementContainer)
        .data(this.data)
        // .svgWidth(window.innerWidth)
        // .svgHeight(window.innerWidth)
        .nodeHeight(d => 120)
        .onNodeClick(d => {
          console.log(d)
          // this.$router.push({
          //   name: 'team-profile',
          //   params: { id: d }
          // })
        }).nodeContent(data => {
          console.log(data)
          const bgClass = data.data.component_class === 'App\\Models\\User' ? 'bg-primary' : 'bg-warning'

          const userData = data.data.component_class === 'App\\Models\\User' ? '<p>'+ data.data.component.email +'</p>' : ''

          return '<div class="card '+ bgClass +' text-white">\n' +
                '  <div class="card-body">\n' +
                '    <h5 class="card-title text-white">'+ data.data.name +'</h5>\n' + userData +
                '  </div>\n' +
                '</div>'
        })
        .render()

      this.chartReference.expandAll().render()
    },
    onItemSelected(item) {
      this.selected = item
    },
    onItemRemoved() {
      this.selected = null
    },
    locate() {
      this.chartReference.clearHighlighting()
      this.chartReference.setHighlighted(this.selected.id).render().fit()
      this.chartReference.setCentered(this.selected.id).render()
    },
    refresh() {
      this.loadList()
    }
  },

  async mounted() {
    this.abortController = new AbortController()
    if (this.employeeStore.organization.length < 1) {
      this.loadList()
    } else {
      this.loading = true
      await this.formatChartData()
      this.data = this.employeeStore.organization
      this.render()
      this.loading = false
    }
  },

  created () {

  },
  watch: {
    selected: function(data) {
      if (!data) {
        this.chartReference.clearHighlighting()
        this.fitOnScreen()
      }
    }
  }
}
</script>
