<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">

      <template slot="actions">
        <b-button type="button"
                class="text-white"
                variant="primary"
                size="sm"
                :to="{ name: 'create-job-role' }">
          <font-awesome-icon class="mr-2" icon="plus" />New Job Role
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <v-data-table
            loading-text="Loading... Please wait"
            height="55vh"
            class="elevation-1"
            :loading="loading"
            :fixed-header="true"
            :headers="columns"
            :items="roles"
            :items-per-page="10"
            :hide-default-header="false"
            :sort-by="['name']"
            :sort-desc="[false, true]">
          <template v-slot:top>
            <div class="p-3">
              <b-row>
                <b-col sm="12" md="6"></b-col>
                <b-col sm="12"
                       md="6"
                       class="d-flex justify-content-end">

                  <div class="w-50">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <font-awesome-icon icon="search" />
                        </div>
                      </div>
                      <input placeholder="Search job roles..."
                             type="text"
                             class="form-control"
                             v-model="searchText"/>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>

          <template v-slot:item.skills="{ item }">
            <v-badge
                class="mr-2"
                :color="item.color || `#FFFF00`"
                dot
            >
            </v-badge>
            {{ item.name }}
          </template>

          <template v-slot:item.skills="{ item }">
            <span v-html="formatSkills(item.skills)"></span>
          </template>
          <template v-slot:item.actions="{ item }">
            <b-dropdown class="mx-1"
                        variant="white"
                        right
                        text="menu"
                        no-caret>
              <b-dropdown-item  @click="onEdit(item)">
                <font-awesome-icon icon="pencil" class="mr-2"/>
                Edit
              </b-dropdown-item>
              <template #button-content>
                <font-awesome-icon icon="ellipsis-vertical"/>
              </template>
            </b-dropdown>
          </template>
        </v-data-table>
        <CreateFormModal :show="showCreateFormDialog"
                         @onHide="onHide"
                         @onCreated="onCreated"></CreateFormModal>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowLeft,
  faAngleUp,
  faDotCircle,
  faAngleDown,
  faSearch,
  faPencil
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import _ from 'lodash'
import { useJobRoleStore } from '@/stores/jobRole'
import CreateFormModal from '@/components/job-roles/CreateFormModal'

library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown, faSearch, faPencil)


export default {
  name: 'JobRolesList',

  components: {
    FontAwesomeIcon,
    PageTitle,
    CreateFormModal
  },

  setup(){
    const store = useJobRoleStore()

    return {
      store
    }
  },

  data: () => ({
    heading: 'Job Roles',
    subheading:
        'Employee job roles or assignments',
    icon: 'pe-7s-lintern icon-gradient bg-tempting-azure',
    searchText: '',
    abortController : null,
    columns: [
      // {
      //   name: 'id',
      //   label: '#',
      //   sortable: false
      // },
      {
        name: 'name',
        value: 'name',
        text: 'Name',
        sortable: false
      },
      {
        name: 'description',
        value: 'description',
        text: 'Description',
        sortable: false
      },
      {
        name: 'skills',
        value: 'skills',
        text: 'Skills',
        sortable: false
      },
      {
        name: 'actions',
        value: 'actions',
        text: '',
        sortable: false
      },
    ],
    loading: false,
    selectedRole: null,
    showCreateFormDialog: false,
    roles: []
  }),

  methods: {
    formatSkills(skills){
      let h = ''
      if (skills && skills.length > 0) {
        skills.forEach((skill) => {
          h+= '<span class="badge badge-primary mr-2">'+ skill.skill.name +' <small>('+ skill.assessment.name +')</small></span>'
        })
      }

      return h
    },
    loadList() {
      this.loading = true
      this.store.getRoles(this.abortController.signal).then(res => {
        this.store.roles = res.data
        this.roles = this.store.roles
      }).then(() => {
        this.loading = false
      })
    },
    onSearchInputChange: _.debounce(function (){
      this.roles = this.store.roles.filter(item =>
        item.name.toLowerCase().includes(this.searchText.toLowerCase()) || item.description.toLowerCase().includes(this.searchText.toLowerCase()) )
    }, 1000),
    onUpdated(data){
      const index = this.store.roles.findIndex(item => item.id === data.id)
      if (index >= 0) {
        this.store.roles[index] = data
      }
      this.showCreateFormDialog = false
    },
    onCreated(){
      this.store.roles = []
      this.loadList()
      this.showCreateFormDialog = false
    },
    onEdit(data){
      this.store.role = data
      this.$router.push({ name: 'edit-job-role', params: { id: data.uuid } })
    },
    onSubmit(){
      this.$refs.roleCreateForm.onValidate()
    },
    onHide(){
      this.showCreateFormDialog = false
    }
  },

  mounted(){
    this.abortController = new AbortController()
    if (this.store.roles.length < 1) {
      this.loadList()
    } else {
      this.roles = this.store.roles
    }

    this.store.role = null
  },

  watch: {
    'searchText': _.debounce(function (value){
      this.roles = this.store.roles.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
          (item.description && item.description.toLowerCase().includes(value.toLowerCase())))
    }, 1000)
  }
}
</script>
