<template>
  <div>
    <validation-observer ref="observer"
                         v-slot="{ reset }">
      <b-form ref="form"
              @submit.prevent="onSubmit"
              @reset.prevent="reset(onCancel)">
        <b-row>
          <b-col sm="12"
                 md="12"
                 v-if="location || (store.locations.length > 0 && (location && location.parent))">
            <b-form-group label="Parent Location *">
              <validation-provider name="location"
                                   rules="required"
                                   vid="location"
                                   v-slot="{ classes, errors }">
                <location-selector aria-describedby="group-live-feedback"
                                   v-model="data.parent"
                                   :excluded-location="location"
                                   :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                   @onSelect="onLocationSelect"></location-selector>
                <b-form-invalid-feedback id="location-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12">
            <b-form-group label="Location Name *">
              <validation-provider name="name"
                                   rules="required"
                                   vid="location_name"
                                   v-slot="{ classes, errors }">
                <b-form-input placeholder="e.g. Main Office"
                              ref="location_name"
                              v-model="data.name"
                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                              aria-describedby="location-name-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="location-name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12">
            <b-form-group label="Description">
              <b-form-textarea
                  id="textarea"
                  v-model="data.description"
                  placeholder="Give some description of this location"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12">
            <b-form-group label="Address">

                <b-form-input placeholder="Exact address"
                              ref="address"
                              v-model="data.address"
                              aria-describedby="address-live-feedback">
                </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="12">
            <b-form-group label="Color">

                <v-swatches aria-describedby="color-live-feedback"
                            show-fallback
                            fallback-input-type="color"
                            popover-x="right"
                            popover-y="top"
                            swatches="text-advanced"
                            v-model="data.color"></v-swatches>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="showFormButtons">
          <hr>
          <div class="d-block text-right">
            <button type="button"
                    class="mr-2 btn btn-link btn-sm"
                    :disabled="loading"
                    @click="onCancel">Cancel</button>
            <button type="button"
                    class="btn btn-success btn-sm"
                    :disabled="!data.name|| loading"
                    @click="onSubmit">
              <b-spinner label="Loading..."
                         small
                         v-if="loading"></b-spinner>
              {{ loading ? 'Saving data...' : 'Save' }}
            </button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {useLocationStore} from '@/stores/location'
import LocationSelector from '@/components/selectors/location-selector'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'Form',

  components: {
    LocationSelector,
    VSwatches
  },

  setup() {
    const store = useLocationStore()

    return {
      store
    }
  },

  props: {
    location: {
      type: [null, Object]
    },
    parent: {
      type: [null, Object]
    },
    showFormButtons: {
      type: Boolean,
      default: true
    },
    submitAfterValidate: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      data: {
        name: null,
        description: null,
        address: null,
        parent: null,
        order: null,
        color: null
      },
      loading: false
    }
  },
  methods: {

    onValidate(){
      this.$refs.observer.validate().then(res => {
        if (res) {
          if (this.submitAfterValidate) {
            this.onSubmit()
          } else {
            this.$emit('validated', this.data)
          }
        }
      })
    },

    onSubmit() {
      this.loading = true
      if (this.location) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }
    },
    handleCreate(){
      this.store.create(this.data).then(res => {
        if (res.data.status === 'success') {
          // if (this.store.locations.length > 0) {
          //   this.store.deepInsert(this.store.locations, res.data.data)
          // }
          this.store.locations = res.data.locations
          this.store.chartData = res.data.chartData
          this.$emit('onCreated', { location: res.data.location, locations: res.data.locations })
        }
      }).catch(e => {
        console.log(e)
        this.$toast('Error while saving location data.', {
          hideProgressBar: true,
          icon: false
        })
      }).then(() => {
        this.loading = false
      })
    },
    handleUpdate(){
      this.store.update(this.location.uuid, this.data).then(res => {
        // we get the whole locations for now
        this.$emit('onUpdated', {
          location: res.data.location,
          locations: res.data.locations
        })
      }).catch(() => {
        this.$toast('Error while saving location data.', {
          hideProgressBar: true,
          icon: false
        })
      }).then(() => {
        this.loading = false
      })
    },
    onCancel(){
      this.data = {
        name: null,
        description: null,
        address: null,
        color: null
      }

      this.$nextTick(() => {
        this.$refs.observer.reset()
      })

      this.$emit('onCancel')
    },
    onLocationSelect(location) {
      this.data.parent = location
    }
  },
  mounted(){
    if (this.location) {
      this.data = { ...this.location }
    }

    if (this.parent) {
      this.data.parent = this.parent
    }
  }
}
</script>
