<template>
  <div>
    <div class="mb-5">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <font-awesome-icon icon="search" />
          </div>
        </div>
        <input placeholder="Search..."
               type="text"
               class="form-control"
               v-model="searchText" />
      </div>
    </div>

    <div class="main-card mb-3 card">
      <div class="card-body text-center" v-if="loading">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <br/>
        Loading data...
      </div>
      <div class="card-body text-center" v-else-if="!loading && (!categories || categories.length < 1)">
        Unable to find skills and category data.. <b-button variant="link" @click="show = true">Create one</b-button>
      </div>
      <div class="card-body d-flex justify-content-between pt-0 pb-0 pl-2 pr-2" v-else>
        <div class="w-50 pb-3 category-wrapper pr-2">
          <ul class="nav flex-column mt-2">
            <li class="nav-item mb-1"
                v-for="item in searchedCategories"
                :key="item.uuid">
              <button type="button"
                      tabindex="0"
                      class="dropdown-item"
                      :class="[category && category.uuid === item.uuid ? 'active' : '']"
                      @click="onSelect(item)">
                <div class="widget-content p-0">
                  <div class="widget-content-wrapper">
                    <div class="widget-content-left">
                      <div class="widget-heading">{{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </button>
            </li>
            <li>
              <b-button type="button"
                        class="mt-5"
                        variant="outline-primary"
                        block
                        size="sm"
                        @click="show = true">
                <font-awesome-icon class="mr-2" icon="plus" /> Add Category
              </b-button>
            </li>
          </ul>
        </div>
        <div class="w-75 pb-3 ml-3" v-if="category">
          <div class="d-flex justify-content-between mt-2"
               v-for="skill in category.skills"
               :key="skill.uuid">
            <b-form-checkbox v-if="role"
                             class="mt-1 mdi-cursor-pointer"
                             name="cb-skills"
                             :value="skill"
                             v-model="role.skills"
                             @change="onSkillCheck(skill)">
              {{ skill.name }}
            </b-form-checkbox>
            <b-link v-if="!role"
                    class="pb-0 mt-2 mb-0"
                    @click="onSkillSelect(skill)">{{ skill.name }}</b-link>
            <SkillAssessmentSelector v-if="role"
                                     style="width: 15vw;"
                                     v-model="skill.assessment"
                                     :auto-fetch="false"
                                     :disabled="role.skills.findIndex(item => item.uuid === skill.uuid) < 0"
                                     @select="onAssessmentSelect($event, skill)"/>
          </div>
          <div class="mt-5" v-if="isAddSkill">
            <b-form-input placeholder="Skill"
                          v-model="skill"></b-form-input>
            <div class="mt-2">
              <b-button variant="primary"
                        size="sm"
                        class="mr-1"
                        :disabled="isSavingSkill"
                        @click="onSaveSkill">
                <b-spinner label="Spinning"
                           small
                           v-if="isSavingSkill"></b-spinner>
                {{ isSavingSkill ? 'Please wait' : 'Save' }}
              </b-button>
              <b-button variant="danger"
                        size="sm"
                        :disabled="isSavingSkill"
                        @click="isAddSkill = false">
                Cancel
              </b-button>
            </div>
          </div>
          <div class="mt-5" v-if="!isAddSkill" >
            <b-link @click="isAddSkill = true">Add Skill</b-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal title="Add Category"
             size="lg"
             hide-header
             hide-footer
             hide-header-close
             no-close-on-esc
             no-close-on-backdrop
             scrollable
             body-class="p-0"
             v-model="isOpen"
             @hide="isOpen = false">
      <CategoryForm @onCreated="onCreated"
                    @onUpdated="onUpdated"
                    @onCancel="onCancel"/>
    </b-modal>
  </div>
</template>

<script>
import {useSkillsStore} from '@/stores/skills'
import {useEmployeeStore} from '@/stores/employee'
import SkillAssessmentSelector from '@/components/selectors/skill-assessment-selector'
import CategoryForm from '@/components/skills/category/Form'

import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'SkillsCard',

  components: {
    FontAwesomeIcon,
    SkillAssessmentSelector,
    CategoryForm
  },

  props: {
    role: {
      type: [Object, null]
    }
  },

  setup() {
    const store = useSkillsStore()
    const employeeStore = useEmployeeStore()
    return {
      store,
      employeeStore
    }
  },

  computed: {
    isOpen: {
      get () {
        return this.show
      },
      set (isOpen) {
        return isOpen
      }
    },
    searchedCategories() {
      if (this.searchText && this.searchText.length > 0) {
        return this.store.categories.filter(item => item.name.includes(this.searchText) || item.skills.filter(skill => skill.name.includes(this.searchText)).length > 0)
      }
      return this.store.categories
    }
  },

  data() {
    return {
      loading: false,
      category: null,
      isEdit: false,
      updating: false,
      categories: [],
      searchText: '',
      show: false,
      isAddSkill: false,
      isSavingSkill: false,
      skill: null
    }
  },

  methods: {
    onSkillSelect(skill) {
      this.store.skill = skill
      this.$router.push({ name: 'skill', params: { id: skill.uuid } })
    },
    onSkillCheck(skill){
      console.log(skill)
    },
    onCreated(category){
      this.show = false
      this.store.categories.push(category)
      this.category = category
      this.formatCategories()
    },
    onUpdated() {

    },
    onCancel() {
      this.show = false
    },
    onSelect(category) {
      this.isAddSkill = false
      this.category = category
    },
    onUpdate() {
      this.updating = true
      this.employeeStore.updateSkills(this.employeeStore.employee.uuid, { skills: this.categories.map(item => item.skills).flat() }).then(res => {
        if (res.data.status === 'success') {
          this.updating = false
          this.isEdit = false
          this.employeeStore.employee.skills = res.data.skills
        }
      })
    },
    getSkillRating(id) {
      return 0
    },

    formatCategories() {
      this.categories = _.cloneDeep(this.store.categories)
      for (const category of this.categories) {
        for (const skill of category.skills) {
          skill['user_rating'] = this.getSkillRating(skill.uuid)
        }
      }
    },
    onAssessmentSelect(item, skill) {
      skill['assessment'] = item
    },
    onSaveSkill(){
      this.isSavingSkill = true
      this.store.createSkill({ category_id: this.category.uuid, name: this.skill }).then(res => {
        if (res.status === 'success') {
          this.skill = null
          this.category.skills.push(res.skill)
          this.isAddSkill = false
          this.isSavingSkill = false
        }
      })
    }
  },

  mounted() {
    if (this.store.assessments.length < 1) {
      this.store.getSkillAssessments().then(() => {
        this.assessment = this.store.assessments[0]
      })
    }
    if (this.store.categories.length < 1) {
      this.loading = true
      this.store.getCategories().then(async () => {
        await this.formatCategories()

        if (this.categories.length > 0) {
          this.category = this.categories[0]
        }
        this.loading = false
      })
    } else {
      this.formatCategories()

      if (this.categories.length > 0) {
        this.category = this.categories[0]
      }
    }
  }
}
</script>

<style lang="scss">
.category-wrapper {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
