<template>
  <div>
    <b-row>
      <b-col sm="12"
             md="12">
        <b-form-group>
          <file-pond
              ref="goalsPond"
              label-idle="Click or drop files here to upload..."
              accepted-file-types="image/jpeg, image/png"
              labelMaxFileSize="5Mb Max"
              maxFileSize="5MB"
              :allow-multiple="false"
              :instant-upload="false"
              :allow-process="false"
              :allow-remove="true"
              :files="files"
              @addfile="onAddFile"
          />
        </b-form-group>
        <br/>
        <b-form-group>
          <b-form-input type="text"
                        placeholder="Enter caption..."
                        v-model="dream.caption"/>
        </b-form-group>

<!--        <b-form-group>-->
<!--          <b-form-input type="text"-->
<!--                        placeholder="Enter caption..."-->
<!--                        v-model="dream.show"/>-->
<!--        </b-form-group>-->
      </b-col>
    </b-row>
    <hr>
    <div class="d-block text-right">
      <button class="mr-2 btn btn-link btn-sm"
              :disabled="loading"
              @click="onCancel">Cancel</button>
      <button type="button"
              class="btn btn-success btn-sm"
              :disabled="(!goal && !dream.image) || loading"
              @click="onSubmit">
        <b-spinner label="Loading..."
                   small
                   v-if="loading"></b-spinner>
        {{ loading ? 'Saving data...' : 'Save' }}
      </button>
    </div>
  </div>
</template>

<script>
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import {useEmployeeStore} from '@/stores/employee'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
)

export default {
  name: 'Form',

  components: {
    FilePond
  },

  props: {
    employee: {
      type: Object,
      required: true
    },
    goal: {
      required: false
    }
  },

  setup(){
    const employeeStore = useEmployeeStore()

    return {
      employeeStore
    }
  },

  data(){
    return {
      dream: {
        image: null,
        caption: null,
        show: false
      },
      files: [],
      loading: false
    }
  },

  methods: {
    onAddFile(error, file){
      if (!error) {
        this.dream.image = file.file
      }
    },
    onBrowse() {
      this.$refs.pond.browse()
    },
    onSubmit(){
      const formData = new FormData()
      formData.append('caption', this.dream.caption)
      formData.append('user_uuid', this.employee.uuid)
      if (this.dream.image) {
        formData.append('file', this.dream.image)
      }

      if (this.goal) {
        this.handleUpdate(formData)
      } else {
        this.handleCreate(formData)
      }

    },
    onCancel(){
      this.$emit('onCancel')
    },
    handleUpdate(formData) {
      this.loading = true
      this.employeeStore.updateGoal(this.goal.uuid, formData).then(res => {
        if (res.data.status === 'success') {
          this.$refs.goalsPond.removeFiles()
          this.$emit('onUpdated', res.data.goal)
        }
      }).then(() => {
        this.loading = false
      })
    },
    handleCreate(formData){
      this.loading = true
      this.employeeStore.storeGoal(formData).then(res => {
        if (res.data.status === 'success') {
          this.$refs.goalsPond.removeFiles()
          this.$emit('onCreated', res.data.data)
        }
      }).then(() => {
        this.loading = false
      })
    }
  },

  mounted(){
    if (this.goal) {
      this.dream.caption = this.goal.caption
    }
  }
}
</script>
