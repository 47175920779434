import Vue from 'vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import persistedState from 'pinia-plugin-persistedstate'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

pinia.use(persistedState)

export default pinia
