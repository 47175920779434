import { defineStore, getActivePinia } from 'pinia'
import { auth } from '@/api/v1'
import * as storage from '../plugins/helpers/storage'
import Avatar from '@/assets/images/avatars/default.png'
import facebook from '@/plugins/helpers/facebook'
export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    authenticated: false,
    tenant: null,
    user: null,
    impersonatedTenant: null,
    isLoggingOut: false,
    isGettingRole: false,
    isLoadingRoles: false,
    isCreatingRoles: false,
    isUpdatingRoles: false,
    isLoadingPermissions: false,
    roles: [],
    role: null,
    permissions:[],
    activities:[]
  }),
  getters:{
    fullName: (state) => state.user ? state.user.first_name + ' ' + state.user.last_name : '',
    roleLabel: (state) => state.user.roles.length > 0 ? (typeof state.user.roles[0] === 'string' ? state.user.roles[0] : state.user.roles[0].name) : 'Team Member',
    avatar(state) {
      if (!state.user ||
        state.user.metafields.length < 1 ||
        !state.user.metafields.profile_photo ||
        !state.user.metafields.profile_photo.value) {
        return Avatar
      }

      return state.user.metafields.profile_photo.value
    },
    can: (state) => (permissions) => {
      return (state.user && state.user.roles.map(i => i['name']).some(r => ['Owner', 'Administrator', 'Super Admin', 'Admin'].indexOf(r) >= 0)) ||
        (state.user.permissions.some(r => permissions.indexOf(r) >= 0) && permissions.some(r => state.user.permissions.indexOf(r) >= 0))
      // return !(state.user && !state.user.roles.includes('Owner') &&
      //   (state.user.permissions.some(r => permissions.indexOf(r) < 0) &&
      //     permissions.some(r => state.user.permissions.indexOf(r) < 0)))
    },
    hasAdministrativeRights: (state) => state.user.roles.filter(item => ['Owner', 'Administrator', 'Super Admin', 'Admin'].includes(item.name)).length > 0
  },
  actions: {
    check (preventLogout = false) {
      try {
        let isTokenExpired = false
        switch (true) {
        case !storage.local.getItem('api_token_expiration'):
        case window.moment().utc().isSameOrAfter(window.moment.unix(storage.local.getItem('api_token_expiration')).utc()):
          isTokenExpired = true
        }

        if (storage.local.getItem('api_token') === null || isTokenExpired) {
          this.deAuthenticate()
          return Promise.reject('Unauthorized')
        } else {
          window.axios.defaults.headers.common.Authorization = 'Bearer ' + storage.local.getItem('api_token')
        }
        this.authenticated = true
        this.tenant = JSON.parse(storage.local.getItem('tenant'))
        this.user = JSON.parse(storage.local.getItem('user'))
        return Promise.resolve(true)
      } catch (err) {
        if (!preventLogout) {
          // error
          this.authenticated = false
          this.tenant = null
        }
        this.deAuthenticate()
        return Promise.reject(err)
      }
    },
    getCsrfCookie () {
      return auth.getCsrfCookie()
    },
    login (email, password, rememberMe, isMobile = false, deviceInfo = null) {
      return auth.login({
        email,
        password,
        remember_me: rememberMe,
        is_mobile: isMobile,
        device_info: deviceInfo
      }).then(res => {
        if (res) {
          // success
          const user = {...res.data.user}
          storage.local.setItem('api_token', res.data.meta.token)
          storage.local.setItem('api_token_expiration', res.data.meta.expiration)
          storage.local.setItem('tenant', JSON.stringify(res.data.user.tenant))
          storage.local.setItem('user', JSON.stringify(user))
          this.authenticated = true
          this.tenant = res.data.user.tenant
          //this.getRoles()
          return Promise.resolve(res)
        }
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    fbLogin (params) {
      return auth.social.facebook.callback(params).then(res => {
        if (res) {
          // success
          const user = {...res.data.user}
          storage.local.setItem('api_token', res.data.meta.token)
          storage.local.setItem('api_token_expiration', res.data.meta.expiration)
          storage.local.setItem('tenant', JSON.stringify(res.data.user.tenant))
          storage.local.setItem('user', JSON.stringify(user))
          this.authenticated = true
          this.tenant = res.data.user.tenant
          //this.getRoles()
          return Promise.resolve(res)
        }
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    logout () {
      this.isLoggingOut = true
      const activePinia = getActivePinia()
      const user = activePinia.state.value.auth.user

      if (user && user.social_provider.provider === 'facebook') {
        facebook.logout()
      }
      return auth.logout().then((res) => {
        this.isLoggingOut = false
        this.deAuthenticate()
        return Promise.resolve(res)
      }).catch((err) => {
        if (err.response.status === 401) {
          this.deAuthenticate()
        }
        return Promise.reject(err)
      })
    },
    deAuthenticate () {
      const activePinia = getActivePinia()
      activePinia._s.forEach((store) => store.$reset())
      storage.local.removeItem('api_token')
      storage.local.removeItem('api_token_expiration')
      storage.local.removeItem('tenant')
      storage.local.removeItem('user')
      storage.local.removeItem('roles')
      storage.local.removeItem('skills')
      storage.local.removeItem('jobRole')
      storage.local.removeItem('location')
      storage.local.removeItem('objectives')
      storage.local.removeItem('employee')
      storage.local.removeItem('groups')
      storage.local.removeItem('assessment')
      window.axios.defaults.headers.common['Authorization'] = null
      this.authenticated = false
      this.tenant = null
      this.user = null
      this.impersonatedTenant = null
    },
    getRoles(){
      this.isLoadingRoles = true
      return auth.roles.list().then(res => {
        this.isLoadingRoles = false
        if (res) {
          this.roles = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getRole(id){
      this.isGettingRole = true
      return auth.roles.get(id).then(res => {
        this.isGettingRole = false
        console.log(res)
        this.role = res.data
      }).catch((res) => {
        console.log(res)
      })
    },
    roleGrantAll(id){
      return auth.roles.grantAll(id)
    },
    roleRevokeAll(id){
      return auth.roles.revokeAll(id)
    },
    createRole(params){
      return auth.roles.create(params)
    },
    updateRole(id, params){
      return auth.roles.update(id, params)
    },
    getPermissions(){
      this.isLoadingPermissions = true
      return auth.permissions.get().then(res => {
        this.isLoadingPermissions = false
        this.permissions = res.data.map(item => {
          const label = item.name.replace(/-/g, ' ')
          return ({...item, label: label.charAt(0).toUpperCase() + label.slice(1)})
        })
      })
    },
    resendVerificationEmail(id){
      return auth.resendVerificationEmail(id)
    },
    setTenant(tenant){
      storage.local.setItem('tenant', JSON.stringify(tenant))
    },
    setUser (user) {
      this.user = user
      storage.local.setItem('user', JSON.stringify(user))
    },
    sendPasswordResetLink (email) {
      return auth.sendPasswordResetLink(email)
    },
    resetPassword (params) {
      return auth.resetPassword(params)
    }
  }
})
