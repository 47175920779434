<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  class="text-white mr-2"
                  size="sm"
                  :to="{ name: 'create-role' }">
          <font-awesome-icon class="mr-2" icon="plus" />New Privilege
        </b-button>

        <b-button type="button"
                  variant="primary"
                  class="text-white"
                  size="sm"
                  title="Refresh list"
                  :disabled="loading"
                  @click="onRefresh">
          <font-awesome-icon icon="refresh" />
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <v-data-table
            loading-text="Loading... Please wait"
            height="55vh"
            class="elevation-1"
            :loading="loading"
            :fixed-header="true"
            :headers="columns"
            :items="roles"
            :items-per-page="10"
            :hide-default-header="false"
            :sort-by="['title']"
            :sort-desc="[false, true]">
          <template v-slot:top>
            <div class="p-3">
              <b-row>
                <b-col sm="12" md="6"></b-col>
                <b-col sm="12"
                       md="6"
                       class="d-flex justify-content-end">

                  <div class="w-50">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">
                          <font-awesome-icon icon="search" />
                        </div>
                      </div>
                      <input placeholder="Search..."
                             type="text"
                             class="form-control"
                             v-model="searchText"
                             :disabled="loading"
                             @input="onSearch"/>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-slot:item.title="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <b-dropdown class="mx-1"
                        variant="white"
                        right
                        text="menu"
                        no-caret>
              <b-dropdown-item @click="onEdit(item)">
                <font-awesome-icon icon="pencil" class="mr-2"/>
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click="onGrantAll(item)">
                <font-awesome-icon icon="thumbs-up" class="mr-2"/>
                Grant all permissions
              </b-dropdown-item>
              <b-dropdown-item @click="onRevokeAll(item)">
                <font-awesome-icon icon="thumbs-down" class="mr-2"/>
                Revoke all permissions
              </b-dropdown-item>
              <template #button-content>
                <font-awesome-icon icon="ellipsis-vertical"/>
              </template>
            </b-dropdown>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp, faThumbsDown, faPlus, faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import _ from 'lodash'
import {useAuthStore} from '@/stores/auth'

library.add(faThumbsUp, faThumbsDown, faPlus,faEllipsisV, faPencil)
export default {
  name: 'Roles',
  components: {
    PageTitle,
    'font-awesome-icon': FontAwesomeIcon
  },
  setup(){
    const store = useAuthStore()

    return {
      store
    }
  },
  data: () => ({
    heading: 'Privileges',
    subheading:
        'Privileges to determine resources access permissions.',
    icon: 'pe-7s-door-lock icon-gradient bg-tempting-azure',
    columns: [
      // {
      //   name: 'id',
      //   label: '#',
      //   sortable: false
      // },
      {
        value: 'name',
        text: 'Name',
        sortable: false
      },
      {
        value: 'actions',
        text: '',
        sortable: false
      }
    ],
    loading: false,
    showViewModal: false,
    showFormDialog: false,
    selectedData: null,
    isUpdatingPermissions: false,
    searchText: null,
    roles: []
  }),
  methods: {
    onRefresh () {
      this.loadList()
    },
    onSearch: _.debounce(function(){
      if (this.searchText && this.searchText.length > 0) {
        this.roles = this.store.roles.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()))
      } else {
        this.roles = this.store.roles
      }
    }, 500),
    onEdit(data) {
      this.store.role = data
      this.$router.push({ name: 'edit-role', params: { id: data.uuid } })
    },
    async onGrantAll(data) {

      const confirm = await this.$dialog.confirm({ text: `Do you wish to grant all permissions to <b>${data.name}</b> role?`, actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.isUpdatingPermissions = true
        this.store.roleGrantAll(data.uuid).then(res => {
          if (res.data.status === 'success') {
            this.$toast.success('All permissions has been successfully granted.', {
              hideProgressBar: true,
              icon: false
            })

            this.$router.push({ name: 'roles' })
          } else {
            this.$toast.error('Error while processing your request. Please try again.', {
              hideProgressBar: true,
              icon: false
            })
          }
        }).then(() => {
          this.isUpdatingPermissions = false
        })
      }
    },
    async onRevokeAll(data){
      const confirm = await this.$dialog.confirm({ text: 'Do you wish to revoke all permissions to this role?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.isUpdatingPermissions = true
        this.store.roleRevokeAll(data.uuid).then(res => {
          if (res.data.status === 'success') {
            this.$toast.success('All permissions has been successfully revoked.', {
              hideProgressBar: true,
              icon: false
            })

            this.$router.push({ name: 'roles' })
          } else {
            this.$toast.error('Error while processing your request. Please try again.', {
              hideProgressBar: true,
              icon: false
            })
          }
        }).then(() => {
          this.isUpdatingPermissions = false
        })
      }
    },
    loadList() {
      this.loading = true
      this.store.roles = []
      this.store.getRoles().then(() => {
        this.roles = this.store.roles
        this.loading = false
      })
    }
  },
  mounted () {
    if (this.store.roles.length < 1) {
      this.loadList()
    } else {
      this.roles = this.store.roles
    }
  }
}
</script>
