<template>
  <div style="margin-top: 20vh;" class="text-center">
     <div>
       <b-card-title class="text-transform-none mt-3">
         You are not authorized to do this action or to access this resource.
       </b-card-title>
       <b-button variant="primary"
                 size="sm"
                 class="mr-2"
                 @click="goHome">Home</b-button>
       <b-button variant="primary"
                 size="sm"
                 @click="goBack">Back</b-button>
     </div>
      <img src="@/assets/images/errors/not-authorized.svg"
           class="mt-5"
           alt="Unauthorized"/>
  </div>
</template>

<script>
export default {
  name: 'Error404.vue',
  data(){
    return {
      imgUrl: '@/assets/images/errors/error.svg'
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goHome() {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>
