<template>
  <div class="main-card mb-3 card">
    <div class="card-header d-flex justify-content-between">
      <span>Skills</span>
      <div v-if="isEdit">
        <b-button variant="primary"
                  size="sm"
                  class="text-white mr-1"
                  :disabled="updating"
                  @click="onUpdate">
          <b-spinner label="Loading..."
                     small
                     v-if="updating"></b-spinner>
          {{ updating ? 'Saving changes...' : 'Save' }}
        </b-button>
        <b-button variant="danger"
                  size="sm"
                  class="text-white"
                  :disabled="updating"
                  @click="onCancel">
          Cancel
        </b-button>
      </div>
      <b-button v-if="!isEdit && categories && categories.length > 0"
                variant="primary"
                size="sm"
                class="text-white"
                :disabled="loading"
                @click="isEdit = true">Edit</b-button>
    </div>
    <div class="card-body text-center" v-if="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
      <br/>
      Loading data...
    </div>
    <div class="card-body text-center" v-else-if="!loading && (!categories || categories.length < 1)">
      No data to display
    </div>
    <div class="card-body d-flex justify-content-between pt-0 pb-0" v-else>
      <div class="w-25 pb-3 category-wrapper pr-2">
        <ul class="nav flex-column mt-2">
          <li class="nav-item mb-1"
              v-for="item in categories"
              :key="item.uuid">
            <button type="button"
                    tabindex="0"
                    class="dropdown-item"
                    :class="[category && category.uuid === item.uuid ? 'active' : '']"
                    @click="onSelect(item)">
              <div class="widget-content p-0">
                <div class="widget-content-wrapper">
                  <div class="widget-content-left">
                    <div class="widget-heading">{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
      <div class="w-75 pb-3 ml-3" v-if="category">
        <div class="d-flex justify-space-between mt-2" v-for="skill in category.skills"
             :key="skill.uuid">
          <p class="pb-0 mt-2 mb-0">{{ skill.name }}</p>
          <SkillAssessmentSelector style="width: 15vw;"
                                   v-model="skill.assessment"
                                   :preselect-first="false"
                                   :auto-fetch="false"
                                   :disabled="!isEdit || loading"
                                   @select="onAssessmentSelect($event, skill)"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {useSkillsStore} from '@/stores/skills'
import {useEmployeeStore} from '@/stores/employee'
import SkillAssessmentSelector from '@/components/selectors/skill-assessment-selector'

import _ from 'lodash'
export default {
  name: 'SkillsCard',

  components: {
    SkillAssessmentSelector
  },

  setup() {
    const store = useSkillsStore()
    const employeeStore = useEmployeeStore()
    return {
      store,
      employeeStore
    }
  },

  data(){
    return {
      loading: false,
      category: null,
      isEdit: false,
      updating: false,
      categories: []
    }
  },

  methods: {
    onCancel() {
      this.isEdit = false
      this.formatCategories()
    },
    onSelect(category) {
      this.category = category
    },
    onUpdate() {
      this.updating = true
      this.employeeStore.updateSkills(this.employeeStore.employee.uuid, { skills: this.employeeStore.employee.skills }).then((res) => {
        if (res.data.status === 'success') {
          this.updating = false
          this.isEdit = false

          //await res.data.skills.forEach((value) => { value.skill['assessment'] = value.assessment })
          this.employeeStore.employee.skills = res.data.skills
          this.employeeStore.updateEmployees(this.employeeStore.employee)
          this.$toast.success('User skills has been updated.', {
            hideProgressBar: true,
            icon: false
          })
        } else {
          this.$toast.error('An error has been encountered while processing your request. Please try again later.', {
            hideProgressBar: true,
            icon: false
          })
        }
      })
    },
    getSkillRating(id) {
      const e = this.employeeStore.employee.skills.find(item => item.skill_id === id)
      return e ? e.assessment : null
    },

    formatCategories() {
      this.categories = _.cloneDeep(this.store.categories)
      for (const category of this.categories) {
        for (const skill of category.skills) {
          skill['assessment'] = this.getSkillRating(skill.uuid)
        }
      }
    },

    onAssessmentSelect($event, skill) {
      const index = this.employeeStore.employee.skills.findIndex(item => item.uuid === skill.uuid)
      skill.assessment = $event
      if (index >= 0) {
        this.employeeStore.employee.skills[index]['assessment'] = $event
      } else {
        this.employeeStore.employee.skills.push(skill)
      }
    }
  },

  mounted() {
    if (this.store.categories.length > 0) {
      this.formatCategories()

      if (this.categories.length > 0) {
        this.category = this.categories[0]
      }
    } else {
      this.loading = true
      this.store.getCategories().then(async () => {
        await this.formatCategories()

        if (this.categories.length > 0) {
          this.category = this.categories[0]
        }
        this.loading = false
      })
    }
    if (this.store.assessments.length < 1) {
      this.store.getSkillAssessments()
    }
  }
}
</script>

<style lang="scss">
.category-wrapper {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}
</style>
