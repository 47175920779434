<template>
  <div>
    <validation-observer ref="observer"
                         v-slot="{ reset }">
      <b-form ref="form"
              class="mass-registration-form"
              @submit.prevent="onSubmit"
              @reset.prevent="reset(onReset)">
        <div class="w-100"
             v-for="(group, index) in groups"
             :key="index">
          <div v-if="massCreation">
            <div class="mb-2">
              <div class="custom-label w-25"># {{ index + 1 }} </div>
            </div>
            <hr/>
          </div>
          <b-row>
            <b-col sm="12"
                   md="12"
                   v-if="store.groups.length > 0 && (group.order >= 1 || !team)">
              <div class="mb-2">
                <div class="custom-label w-25">Parent Team * </div>
              </div>
              <b-form-group>
                <validation-provider name="group"
                                     rules="required"
                                     vid="group"
                                     v-slot="{ classes, errors }">
                  <GroupSelector aria-describedby="group-live-feedback"
                                 v-model="group.parent"
                                 :excluded-group="group"
                                 :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                 @onSelect="onGroupSelect($event, index)"></GroupSelector>
                  <b-form-invalid-feedback id="group-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="12">
              <div class="mb-2">
                <div class="custom-label w-25">Title * </div>
              </div>
              <b-form-group>
                <validation-provider name="title"
                                     rules="required"
                                     vid="title"
                                     v-slot="{ classes, errors }">
                  <b-form-input type="text"
                                placeholder="Title or name of the team"
                                aria-describedby="title-live-feedback"
                                v-model="group.name"
                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                  <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12">
              <div class="mb-2">
                <div class="custom-label w-25">Color </div>
              </div>
              <b-form-group>
                  <v-swatches aria-describedby="color-live-feedback"
                              show-fallback
                              fallback-input-type="color"
                              popover-x="right"
                              popover-y="top"
                              swatches="text-advanced"
                              v-model="group.color"></v-swatches>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12"
                   md="12"
                   v-if="massCreation">
              <hr/>
              <div class="d-flex justify-content-end">
                <b-link type="button"
                        class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white mr-2"
                        v-if="groups.length === 1 || index === (groups.length - 1)"
                        :disabled="loading"
                        @click="onAddFields">
                  <font-awesome-icon class="mr-2" icon="plus" /> Add
                </b-link>
                <b-link type="button"
                        class="btn-shadow d-inline-flex align-items-center btn btn-danger text-white"
                        v-if="groups.length > 1"
                        :disabled="loading"
                        @click="onRemoveFields(index)">
                  <font-awesome-icon class="mr-2" icon="times" /> Remove
                </b-link>
              </div>
            </b-col>
          </b-row>
          <div v-if="showFormButtons">
            <hr/>
            <div class="d-block text-right">
              <button type="button"
                      class="mr-2 btn btn-link btn-sm"
                      :disabled="loading"
                      @click="onCancel">Cancel</button>
              <button type="button"
                      class="btn btn-success btn-sm"
                      :disabled="loading"
                      @click="onValidate">
                <b-spinner label="Loading..."
                           small
                           v-if="loading"></b-spinner>
                {{ loading ? 'Saving data...' : 'Save' }}
              </button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import GroupSelector from '@/components/selectors/groups-selector'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useGroupStore} from '@/stores/groups'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'GroupEntryForm',

  components: {
    FontAwesomeIcon,
    GroupSelector,
    VSwatches
  },

  props: {
    massCreation: {
      type: Boolean,
      default: false
    },
    team: {
      type: [null, Object]
    },
    parent: {
      type: [null, Object]
    },
    showFormButtons: {
      type: Boolean,
      default: true
    },
    submitAfterValidate: {
      type: Boolean,
      default: true
    }
  },

  setup(){
    const store = useGroupStore()
    return {
      store
    }
  },

  data: () => ({
    loading: false,
    searchText: '',
    abortController : null,
    groups: [
      {
        name: null,
        parent: null,
        order: null,
        color: '#A463BF'
      }
    ],
    colors: {
      hex: '#194d33',
      hex8: '#194D33A8',
      hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
      hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
      rgba: { r: 25, g: 77, b: 51, a: 1 },
      a: 1
    }
  }),

  methods: {
    onAddFields(){
      this.groups.push({
        name: null,
      })
    },
    onRemoveFields(index){
      this.groups.splice(index, 1)
    },
    async onValidate () {
      this.$refs.observer.validate().then(res => {
        if (res) {
          if (this.submitAfterValidate) {
            this.onSubmit()
          } else {
            this.$emit('validated', this.groups[0])
          }
        }
      })
    },
    async onSubmit(){
      const confirm = await this.$dialog.confirm({ text: 'You are about to save teams data. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        if (this.team) {
          this.handleUpdate()
        } else {
          this.handleCreate()
        }
      }
    },
    handleCreate(){
      this.$emit('onCreate')
      this.loading = true
      this.store.massCreate({ groups: this.groups }).then(async (res) => {
        if (res.data.status === 'success') {
          //this.store.deepInsert(this.store.groups, res.data.groups[0])
          //this.store.groups = await this.store.groups.concat(res.data.groups)
          this.store.groups = res.data.groups
          this.store.chartData = res.data.chartData
          this.store.created = true
          this.$emit('onCreated', res.data.groups)
          this.$toast.success('Teams data has been saved.', {
            hideProgressBar: true,
            icon: false
          })
        }
      }).catch(e => {
        console.log(e)
        this.$emit('onCreated')
        this.$toast.error('Error while processing your request. Please try again.', {
          hideProgressBar: true,
          icon: false
        })
      }).then(() => {
        this.loading = false
      })
    },
    handleUpdate(){
      this.$emit('onCreate')
      this.loading = true
      this.store.update(this.team.uuid, {
        name: this.team.name,
        parent: this.team.parent ? this.team.parent.uuid : null,
        color: this.team.color,
      }).then(async (res) => {
        if (res.data.status === 'success') {
          this.$emit('onUpdated', { group: res.data.group, groups: res.data.groups })
          this.$toast.success('Groups data has been saved.', {
            hideProgressBar: true,
            icon: false
          })
        }
      }).catch(e => {
        console.log(e)
        this.$toast.error('Error while processing your request. Please try again.', {
          hideProgressBar: true,
          icon: false
        })
      }).then(() => {
        this.loading = false
      })
    },
    onReset(){},
    onGroupSelect(data, index) {
      this.groups[index].parent = data
    },
    onCancel(){
      this.$emit('onCancel')
    }
  },
  mounted(){
    if (this.parent) {
      this.groups[0].parent = this.parent
    }

    if (this.team) {
      this.groups = [this.team]
    }
  }
}
</script>
