<template>
  <div>
    <div class="app-inner-layout__wrapper">
      <div class="text-center mt-10" v-if="loading">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <br/>
        Please wait...
      </div>
      <div class="d-flex justify-content-around profile-content-wrapper" v-if="store.employee">
        <div class="h-sidebar w-15 padded position-relative">
          <p class="font-weight-medium">Account</p>
          <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile', params: { id: this.store.employee.uuid } }">Account Details</b-link></p>
          <p>
            <b-link class="h-sidebar-links"
                     v-if="store.employee.uuid === authStore.user.uuid || authStore.hasAdministrativeRights"
                     :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'email-addresses' } }">Email Addresses</b-link>
          </p>
          <p>
            <b-link class="h-sidebar-links"
                    v-if="store.employee.uuid === authStore.user.uuid || authStore.hasAdministrativeRights"
                    :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'security' } }">Security</b-link>
          </p>
          <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'location' } }">Location</b-link></p>
          <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'teams' } }">Teams</b-link></p>
          <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'job-roles' } }">Job Roles</b-link></p>
          <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'skills' } }">Skills</b-link></p>
          <p><b-link class="h-sidebar-links" v-if="store.employee.uuid === authStore.user.uuid"
                     :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'dream-board' } }">Dream Board</b-link>
          <div v-if="authStore.hasAdministrativeRights">
            <p class="font-weight-medium mt-10">Security</p>
            <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'roles' } }">Privileges</b-link></p>
            <p><b-link class="h-sidebar-links" :to="{ name: 'team-profile-pages', params: { id: this.store.employee.uuid, page: 'social-login' } }">Social Login</b-link></p>
          </div>

        </div>
        <div class="h-content-wrapper w-85 padded">
          <b-row>
            <b-col md="10">
              <div v-if="!$route.params.page" >
                <p class="page-title">Account Details</p>
                <AccountDetails :employee="store.employee"></AccountDetails>
              </div>
              <div v-if="$route.params.page === 'security'">
                <p class="page-title">Security</p>
                <SecurityDetails :employee="store.employee"></SecurityDetails>
              </div>
              <div v-if="$route.params.page === 'email-addresses'">
                <p class="page-title">Email Addresses</p>
                <EmailAddresses :employee="store.employee"></EmailAddresses>
              </div>
              <div v-if="$route.params.page === 'location'">
                <p class="page-title">Location</p>
                <LocationsCard></LocationsCard>
              </div>
              <div v-if="$route.params.page === 'teams'">
                <p class="page-title">Teams</p>
                <TeamsCard></TeamsCard>
              </div>
              <div v-if="$route.params.page === 'dream-board'">
                <p class="page-title">Dream Board</p>
                <div class="main-card mb-3 card">
                  <div class="card-header d-flex justify-content-between">
                    <span>Dream Board</span>

                    <b-button variant="primary"
                              size="sm"
                              class="text-white"
                              @click="onAddGoal">Add Goal</b-button>
                  </div>
                  <div class="card-body">
                    <GoalsList v-if="goals.length > 0"
                               :show-like="false"
                               :show-details="false"
                               :goals="goals"
                               @edit="onEditGoal"
                               @delete="onGoalDelete"/>
                    <div v-else class="text-center">
                      You don't have any items in your goals list or dream board yet.
                    </div>

                    <b-modal :title="(selectedGoal ? 'Edit' : 'Add') + ' Goal'"
                             hide-footer
                             hide-header-close
                             no-close-on-esc
                             no-close-on-backdrop
                             v-model="showGoalFormDialog"
                             @hide="showGoalFormDialog = false">
                      <GoalsForm :employee="store.employee"
                                 :goal="selectedGoal"
                                 @onCancel="showGoalFormDialog = false"
                                 @onUpdated="onGoalUpdated"
                                 @onCreated="onGoalCreated"/>
                    </b-modal>
                  </div>
                </div>
              </div>
              <div v-if="$route.params.page === 'skills'">
                <p class="page-title">Skills</p>
                <SkillsCard></SkillsCard>
              </div>
              <div v-if="$route.params.page === 'job-roles'">
                <p class="page-title">Job Roles</p>
                <JobRolesCard @onSave="onJobRoleSave"></JobRolesCard>
              </div>
              <div v-if="$route.params.page === 'roles'">
                <p class="page-title">Roles</p>
                <p>Privileges use to define user access & permissions</p>
                <RolesCard @onSave="onRoleSave"></RolesCard>
              </div>
              <div v-if="$route.params.page === 'social-login'">
                <p class="page-title">Social Login</p>
                <SocialLogin></SocialLogin>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountDetails from '@/components/employee/AccountDetails'
import SecurityDetails from '@/components/employee/SecurityDetails'
import EmailAddresses from '@/components/employee/EmailAddresses'
import SkillsCard from '@/components/employee/SkillsCard'
import RolesCard from '@/components/employee/RolesCard'
import LocationsCard from '@/components/employee/LocationsCard'
import JobRolesCard from '@/components/employee/JobRolesCard'
import TeamsCard from '@/components/employee/TeamsCard'

import GoalsList from '@/components/dream-board/List'
import GoalsForm from '@/components/dream-board/Form'

import {useEmployeeStore} from '@/stores/employee'
import {useAuthStore} from '@/stores/auth'
import SocialLogin from '@/components/employee/SocialLogin'

export default {
  name: 'Profile',

  components: {
    SocialLogin,
    AccountDetails,
    SkillsCard,
    RolesCard,
    GoalsList,
    GoalsForm,
    JobRolesCard,
    LocationsCard,
    TeamsCard,
    EmailAddresses,
    SecurityDetails
  },

  computed:{
    heading(){
      if (this.store.employee) {
        return this.store.employee.first_name + ' ' + this.store.employee.last_name
      }
      return ''
    },
    subheading() {
      return 'Manager'
    }
  },

  setup() {
    const store = useEmployeeStore()
    const authStore = useAuthStore()

    return {
      store,
      authStore
    }
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    showGoalFormDialog: false,
    selectedGoal: null,
    goals: [],
    isSendingInvitationLink: false,
    loading: false
  }),

  methods: {
    getEmployee(id) {
      this.loading = true
      this.store.employee = null
      return this.store.getEmployee(id).then(res => {
        this.store.employee = res.data
        if (this.store.employee && this.store.employee.metafields && this.store.employee.metafields.birthdate) {
          this.store.employee.metafields.birthdate.value = new Date(this.store.employee.metafields.birthdate.value)
        }
        this.formatGoals()
        this.loading = false
      })
    },

    onGoalDelete(goal) {
      const index = this.store.employee.goals.findIndex(item => item.uuid === goal.uuid)
      this.store.employee.goals.splice(index, 1)
      this.formatGoals()
    },
    onGoalCreated(goal) {
      this.store.employee.goals.push(goal)
      this.formatGoals()
      this.showGoalFormDialog = false
    },
    onGoalUpdated(goal) {
      const index = this.store.employee.goals.findIndex(item => item.uuid === goal.uuid)
      if (index >= 0) {
        this.store.employee.goals[index] = goal
      }
      this.formatGoals()
      this.showGoalFormDialog = false
    },
    onEditGoal(goal){
      this.selectedGoal = goal
      this.showGoalFormDialog = true
    },
    onAddGoal(){
      this.selectedGoal = null
      this.showGoalFormDialog = true
    },
    formatGoals()
    {
      if (!this.store.employee || !this.store.employee.goals) {
        this.goals = []
      }

      this.goals = this.store.employee.goals.map(item => {
        return {...item, imageUrl: item.media, thumbUrl: item.media, caption: item.description, user: this.store.employee }
      })
    },
    onRoleSave(data) {
      this.store.employee.roles = data
    },
    onJobRoleSave(data) {
      this.store.employee.job_roles = data
    },
    init() {
      if (this.authStore.user.uuid === this.$route.params.id) {
        this.store.employee = {...this.authStore.user}
      } else {
        this.getEmployee(this.$route.params.id).then(() => {
          this.formatGoals()
        })
      }
    }
  },

  mounted() {
    switch (true) {
    case !this.authStore.hasAdministrativeRights && this.$route.params.id !== this.authStore.user.uuid && !this.authStore.can(['view-employee']):
      this.$router.push({ name: 'unauthorized' })
      break
    case this.$route.params.page === 'roles' && !this.authStore.hasAdministrativeRights:
      this.$router.push({ name: 'team-profile' })
      break
    default:
      this.init()
    }
  },

  watch: {
    '$route.params.id': function(){
      this.init()
    }
  }
}
</script>

<style lang="scss">
.profile-content-wrapper {
  height: 88vh;
  overflow: hidden ;

  .h-sidebar {
    border-right: 1px solid #c5c5c5;
    background: white;
  }
  @media (max-width: 992px) {
    .h-sidebar {
      display: none;
      position: fixed;
      top: 61px;
      bottom: 0;
      overflow-y: auto;
      animation: 100ms ease-in-out 0s 1 normal none running animation-185ib2l;
      z-index: 1018;
      box-shadow: rgb(43 34 51 / 12%) 0 4px 24px;
    }
  }

  .h-content-wrapper {
    overflow: auto;
  }
}
</style>
