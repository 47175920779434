<template>
  <div>
    <VueMultiselect track-by="uuid"
                    label="name"
                    placeholder="Select location"
                    selected-label=""
                    select-label=""
                    deselect-label=""
                    v-model="location"
                    :allowEmpty="allowEmpty"
                    :options="locations"
                    :loading="loading || showLoader"
                    :disabled="loading || showLoader"
                    @input="onInput"
                    @select="onSelect">
      <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title">{{ props.option.name }} </span>
      </span>
      </template>
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import { ref } from 'vue'
import VueMultiselect from 'vue-multiselect'
import {useLocationStore} from '@/stores/location'

export default {
  name: 'location-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: Object,
      required: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    excludedLocation: {
      type: Object
    },
    showLoader: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const store = useLocationStore()
    const loading = ref(false)
    const getList = () => {
      loading.value = true
      store.getList().then(() => {
        loading.value = false
      })
    }

    return {
      store,
      loading,
      getList
    }
  },

  computed: {
    locations() {
      if (this.excludedLocation && this.excludedLocation.uuid) {
        return this.store.locations.filter(item => item.uuid !== this.excludedLocation.uuid)
      }


      return this.store.locations
    }
  },

  data () {
    return {
      location: this.value
    }
  },
  methods: {
    onSelect (data) {
      this.$emit('onSelect', data)
    },
    onInput (data) {
      this.$emit('input', data)
    }
  },
  mounted(){
    if (this.store.locations.length < 1) {
      this.getList()
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.location = this.value
      }
    }
  }
}
</script>
