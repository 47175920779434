<template>
<div>
  <div class="main-card card">
    <div class="card-header">Skills Category</div>
      <validation-observer ref="observer"
                           v-slot="{ reset, invalid }">
        <b-form ref="form"
                @submit.prevent="onValidate"
                @reset.prevent="reset(onReset)">
          <div class="card-body">
            <b-form-group label="Category Title *">
              <validation-provider name="skill's category title"
                                   rules="required"
                                   vid="category_name"
                                   v-slot="{ classes, errors }">
                <b-form-input placeholder="e.g. Web Development, Support"
                              ref="category_name"
                              v-model="category.name"
                              :disabled="loading"
                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                              aria-describedby="category_name-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="category_name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Description">
              <b-form-input
                  placeholder="Enter Description"
                  :disabled="loading"
                  v-model="category.description">
              </b-form-input>
            </b-form-group>
          </div>
          <hr/>
          <div class="card-body">
            <div v-for="(skill, index) in category.skills"
                 :key="index"
                 class="mb-4">
              <validation-provider name="skill name"
                                   rules="required"
                                   :vid="`skill_name` + index"
                                   v-slot="{ classes, errors }">
                <b-form-group label="">
                  <b-input-group :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']">
                    <template #prepend>
                      <b-input-group-text >{{ index + 1 }}</b-input-group-text>
                    </template>
                    <b-form-input placeholder="Skills"
                                  v-model="skill.name"
                                  :disabled="loading"
                                  :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                  :aria-describedby="`skill_name-`+ index +`-live-feedback`">
                    </b-form-input>

                    <b-input-group-append v-if="category.skills.length > 1">
                      <b-button variant="outline-secondary"
                                v-b-tooltip.hover
                                title="Remove"
                                :disabled="loading"
                                @click="onRemoveSkill(index)">
                        <font-awesome-icon icon="xmark" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-invalid-feedback :id="`template_question-`+ index +`-live-feedback`">{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <div class="d-flex justify-content-end">
              <button class="btn btn-success btn-sm"
                      type="button"
                      :disabled="loading"
                      @click="onAddSkill">Add Skill</button>
            </div>
          </div>

          <div class="d-block card-footer d-flex justify-content-between"
               v-if="showFooterButtons">
            <b-button class="mr-2 btn btn-link btn-sm text-white"
                      :disabled="loading"
                      @click="onCancel">Cancel</b-button>
            <div>
              <button class="mr-2 btn btn-link btn-sm"
                      type="reset"
                      :disabled="loading"
                      @click="onReset">Reset</button>
              <button class="btn btn-primary btn-sm"
                      type="submit"
                      :disabled="category.skills.length < 1 || invalid || loading">
                <b-spinner label="Loading..."
                           small
                           v-if="loading"></b-spinner>
                {{ loading ? 'Saving...' : 'Save' }}
              </button>
            </div>

          </div>
        </b-form>
      </validation-observer>
    </div>
</div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useSkillsStore} from '@/stores/skills'
import _ from 'lodash'

export default {
  name: 'Form',

  components: {
    FontAwesomeIcon
  },

  props: {
    showFooterButtons: {
      type: Boolean,
      default: true
    },
    submitAfterValidate: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    const store = useSkillsStore()

    return {
      store
    }
  },

  data() {
    return {
      heading: 'Skills Category',
      subheading:
          'Add skills category.',
      icon: 'pe-7s-helm icon-gradient bg-tempting-azure',
      loading: false,
      category: {
        name: null,
        description: null,
        skills: [
          {
            name: null,
            assessment: null
          }
        ]
      }
    }
  },

  methods: {
    onAssessmentSelect(data, index){
      this.category.skills[index].assessment = data
    },
    onRemoveSkill(index) {
      this.category.skills.splice(index, 1)
    },
    onAddSkill() {
      this.category.skills.push({
        name: null,
        assessment: null
      })
    },
    onValidate(){
      this.$refs.observer.validate().then(res => {
        if (res) {
          if (this.submitAfterValidate) {
            this.onSubmit()
          } else {
            this.$emit('validated', this.data)
          }
        }
      })
    },
    async onSubmit() {
      const confirm = await this.$dialog.confirm({ text: 'You are about to save skills & category data. Continue?', actions: ['No', 'Yes'] })
      if (confirm === 'Yes') {
        this.loading = true
        if (this.category.uuid) {
          this.handleUpdate()
        } else {
          this.handleCreate()
        }
      }
    },
    handleCreate() {
      this.store.createSkillCategory(this.category).then(res => {
        if (res.status === 'success') {
          this.$toast.success('Skills category has been added.', {
            hideProgressBar: true,
            icon: false
          })
          this.$emit('onCreated', res.category)
        } else {
          this.$toast.error('Error while saving data.', {
            hideProgressBar: true,
            icon: false
          })
        }
        this.loading = false
      }).catch (error => {
        console.log(error)
        this.$toast.error('Error while connecting to the API.', {
          hideProgressBar: true,
          icon: false
        })
      })
    },
    handleUpdate() {
      this.store.updateSkillCategory(this.category.uuid, this.category).then(res => {
        if (res.status === 'success') {
          this.$toast.success('Skills category has been added.', {
            hideProgressBar: true,
            icon: false
          })
          this.$emit('onUpdated', res.category)
        } else {
          this.$toast.error('Error while saving data.', {
            hideProgressBar: true,
            icon: false
          })
        }
        this.loading = false
      }).catch (error => {
        console.log(error)
        this.$toast.error('Error while connecting to the API.', {
          hideProgressBar: true,
          icon: false
        })
      })
    },
    onReset() {
      if (this.category.uuid) {
        this.category = _.cloneDeep(this.store.category)
      } else {
        this.category = {
          name: null,
          description: null,
          skills: [
            {
              name: null,
              assessment: null
            }
          ]
        }
      }
    },
    onCancel(){
      this.$emit('onCancel')
    }
  },

  mounted() {
    if ((this.store.category && this.$route.params.id && this.$route.params.id !== this.store.category.uuid) ||
        (this.$route.params.id && !this.store.category)) {
      this.$router.push({ name: 'skills' })
    } else {
      this.category = this.store.category ? _.cloneDeep(this.store.category) : {
        name: null,
        description: null,
        skills: [
          {
            name: null,
            assessment: null
          }
        ]
      }
    }
  }
}
</script>
