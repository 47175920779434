<template>
  <VueMultiselect track-by="id"
                  label="name"
                  placeholder="Select a template"
                  v-model="template"
                  selected-label=""
                  select-label=""
                  deselect-label="x"
                  :options="store.templates"
                  :loading="isGettingTemplate"
                  :disabled="isGettingTemplate"
                  @select="onSelect"
                  @remove="onRemove">
    <template slot="option" slot-scope="props">
      <div class="option__desc">
        <span class="option__title">{{ props.option.name }}</span>
        <br/>
        <small class="option__small mt-1">{{ props.option.description }}</small>
      </div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import { useAssessmentStore } from '@/stores/assessment'
import { onMounted } from '@vue/composition-api'

export default {
  name: 'assessment-template-selector',

  components: { VueMultiselect },

  setup () {
    const assessmentStore = useAssessmentStore()
    return {
      store: assessmentStore
    }
  },

  data () {
    return {
      template: null,
      isGettingTemplate: false
    }
  },
  methods:{
    onSelect (template) {
      this.$emit('onSelect', template)
    },
    onRemove () {
      this.$emit('onSelect', null)
    }
  },
  mounted(){
    if (this.store.templates.length < 1) {
      this.isGettingTemplate = true
      this.store.getTemplates().then(() => {
        this.isGettingTemplate = false
      })
    }
  }
}
</script>
