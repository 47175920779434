<template>
  <v-avatar :color="color"
            :size="size">
    <img
        v-if="image"
        alt="Avatar"
        :src="image"
    >
    <span v-else
          class="white--text">{{ initials }}</span>
  </v-avatar>
</template>

<script>

export default {
  name: 'AvatarComponent',

  props: {
    employee: {
      type: Object,
      required: true
    },
    size : {
      type: String,
      default: '34'
    },
    color : {
      type: String,
      default: 'blue'
    }
  },

  computed: {
    initials() {
      return (this.employee.first_name.charAt(0) + this.employee.last_name.charAt(0)).toUpperCase()
    },
    image() {
      if (!this.employee ||
          !this.employee.metafields ||
          !this.employee.metafields.profile_photo ||
          !this.employee.metafields.profile_photo.value) {
        return null
      }

      return this.employee.metafields.profile_photo.value
    }
  }
}
</script>
