import { defineStore } from 'pinia'
import { tenant } from '@/api/v1'

export const useObjectivesStore = defineStore({
  id: 'objectives',
  persist: true,
  state: () => ({
    objective: null,
    objectives: []
  }),
  getters:{

  },
  actions: {
    getList (params, signal) {
      return tenant.objectives.list({ params: params, signal: signal }).then(res => {
        this.objectives = res.data
        return res
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    create (params, signal) {
      return tenant.objectives.store(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
    update (id, params, signal) {
      return tenant.objectives.update(id, params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },

    createQuarterlyGoals (params, signal) {
      return tenant.objectives.storeQuarterlyGoals(params, signal).then(res => {
        return res.data
      }).catch(err => {
        console.log(err)
        return null
      })
    },
  }
})
