<template>
  <b-overlay class="table-more-rows-spinner"
             :show="loading"
             rounded="sm">
    <b-row>
      <div class=""></div>
      <b-col md="4">
          <b-card class="main-card mb-3 card">
            <validation-observer ref="observer"
                                 v-slot="{ reset }">
              <b-form ref="form"
                      @submit.prevent="onSubmit"
                      @reset.prevent="reset(onReset)">
                <b-row >
                  <b-col sm="12" md="12">
                    <b-form-group label="Title *">
                      <validation-provider name="title"
                                           rules="required"
                                           vid="title"
                                           v-slot="{ classes, errors }">
                        <b-form-input placeholder="e.g. Manager"
                                      ref="title"
                                      v-model="role.name"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                      aria-describedby="title-live-feedback">
                        </b-form-input>
                        <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="12">
                    <b-form-group label="Description">
                      <b-form-textarea
                          id="textarea"
                          v-model="role.description"
                          placeholder="Enter description for this job role..."
                          rows="3"
                          max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group label="Color">
                      <v-swatches show-fallback
                                  fallback-input-type="color"
                                  popover-x="right"
                                  popover-y="top"
                                  swatches="text-advanced"
                                  v-model="role.color"></v-swatches>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
      </b-col>
      <b-col md="8">
        <div class="main-card mb-3 card">
          <div class="card-header">
            Required Skills
          </div>
          <div class="card-body">
            <SkillsCard :role="role"></SkillsCard>
          </div>
        </div>
      </b-col>
    </b-row>
    <template #overlay>
      <div class="text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        <br/>
        Please wait...
      </div>
    </template>
  </b-overlay>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowLeft,
  faAngleUp,
  faDotCircle,
  faAngleDown,
  faSearch,
  faPencil
} from '@fortawesome/free-solid-svg-icons'
import { useJobRoleStore } from '@/stores/jobRole'

library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown, faSearch, faPencil)

import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'
import {useSkillsStore} from '@/stores/skills'
import _ from 'lodash'
import SkillsCard from '@/components/skills/SkillsCard'

export default {
  name: 'JobRolesEntryForm',

  components: {
    VSwatches,
    SkillsCard
  },

  setup(){
    const store = useJobRoleStore()
    const skillStore = useSkillsStore()

    return {
      store,
      skillStore,
    }
  },

  data: () => ({
    loading: false,
    selected: null,
    role: {
      name: null,
      description: null,
      color: '#F3F3F3',
      skills: []
    },
  }),

  methods: {
    onSubmit(){
      if (this.store.role) {
        this.handleUpdate()
      } else {
        this.handleCreate()
      }
    },
    onReset(){},
    onSave(){
      this.$refs.observer.validate().then(res => {
        if (res) {
          this.$emit('submitted')
          this.onSubmit()
        }
      })
    },
    onAssessmentSelect (item, skill) {
      skill['assessment'] = item
    },
    handleCreate(){
      this.loading = true
      this.store.create(this.role).then(res => {
        if (res.status === 'success') {
          this.store.roles.push(res.role)
          this.$emit('created', res.role)
          this.loading = false
        }
      }).catch(e => {
        console.log(e)
        this.loading = false
      })
    },
    handleUpdate(){
      this.loading = true
      this.store.update(this.role.uuid, this.role).then(res => {
        if (res.status === 'success') {

          const index = this.store.roles.find(item => item.uuid === res.role.uuid)
          this.store.roles[index] = res.role

          this.$emit('updated', res.role)
          this.loading = false
        }
      }).catch(e => {
        this.loading = false
        console.log(e)
      })
    }
  },

  mounted(){
    let _this = this
    if (this.$route.params.id && !this.store.role) {
      this.$router.push({ name: 'job-roles' })
    }

    if (this.store.role) {
      this.role = _.cloneDeep(this.store.role)
      this.role.skills.forEach(function(item, index) {
        let skill = item.skill
        skill['assessment'] = item.assessment
        _this.role.skills[index] = skill
      })
    }
  }
}
</script>
