<template>
  <div>
    <VueMultiselect track-by="id"
                    label="name"
                    class="group-multiselect"
                    placeholder="Select skill"
                    selected-label=""
                    select-label=""
                    deselect-label=""
                    group-values="skills"
                    group-label="name"
                    v-model="skill"
                    :group-select="false"
                    :allowEmpty="allowEmpty"
                    :options="store.categories"
                    :loading="store.isLoadingCategories"
                    :disabled="store.isLoadingCategories || disabled"
                    @select="onSelect">
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import {useSkillsStore} from '@/stores/skills'

export default {
  name: 'skill-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: [Object, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    }
  },

  setup () {
    const store = useSkillsStore()

    return {
      store
    }
  },

  data () {
    return {
      skill: this.value,
    }
  },
  methods: {
    onSelect (data) {
      this.$emit('onSelect', data)
    }
  },
  mounted(){
    if (this.store.categories.length < 1) {
      this.store.getCategories()
    }
  },
  watch: {
    'value': {
      deep: true,
      handler: function(value){
        this.skill = value
      }
    }
  }
}
</script>
