<template>
  <validation-observer ref="observer"
                       v-slot="{ reset }">
        <b-form ref="form"
                class="goal-entry-form"
                @submit.prevent="onSubmit"
                @reset.prevent="reset(onReset)">

          <div class="mb-2">
            <div class="w-100">
              <b-row>
                <b-col sm="12" md="12">
                  <ImageCropUploader :show-preview="false"
                                     :cropped-height="1000"
                                     :cropped-width="1000"
                                     :cropper-height="false"
                                     :aspect-ratio="false"
                                     class="mb-2"
                                     @fileAdded="onImageAdded"
                                     @fileSelected="onImageSelected"
                  />

                  <div class="custom-label w-25">Title * </div>
                  <b-form-group>
                    <validation-provider name="title"
                                         rules="required"
                                         vid="title"
                                         v-slot="{ classes, errors }">
                      <b-form-input type="text"
                                    placeholder="Title"
                                    aria-describedby="title-live-feedback"
                                    v-model="goal.title"
                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                      <b-form-invalid-feedback id="title-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>


                <b-col sm="12" md="12">
                  <div class="custom-label w-25">Description</div>
                  <b-form-group>
                      <b-form-input type="text"
                                    placeholder="Description"
                                    v-model="goal.description"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div  class="w-100">
              <b-row>
                <b-col sm="12" md="12">
                  <div class="custom-label w-25">Type *</div>
                  <b-form-group>
                    <validation-provider name="type"
                                         rules="required"
                                         vid="type"
                                         v-slot="{ classes, errors }">
                      <VueMultiselect track-by="key"
                                      label="label"
                                      placeholder="Select a type"
                                      selected-label=""
                                      select-label=""
                                      deselect-label="x"
                                      v-model="goal.type"
                                      :allowEmpty="false"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                      :options="types">
                        <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.label }}</span>
                        </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">{{ props.option.label }} </span>
                          </div>
                        </template>
                      </VueMultiselect>
                      <b-form-invalid-feedback id="type-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div  class="w-100">
              <b-row v-if="goal.type.key === GoalTypes.QUARTERLY">
                <b-col sm="12"
                       md="6">
                  <div class="custom-label">Quarter *</div>
                  <b-form-group>
                    <validation-provider name="duration"
                                         rules="required"
                                         vid="duration"
                                         v-slot="{ classes, errors }">
                      <VueMultiselect placeholder="Select quarter"
                                      selected-label=""
                                      select-label=""
                                      deselect-label="x"
                                      v-model="goal.duration"
                                      :allowEmpty="false"
                                      :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                      :options="[1,2,3,4]">
                      </VueMultiselect>
                      <b-form-invalid-feedback id="duration-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="12"
                       md="6">
                  <div class="custom-label">Year *</div>
                  <b-form-group>
                    <validation-provider name="start date"
                                         rules="required"
                                         vid="start_date"
                                         v-slot="{ classes, errors }">
                      <DatePicker placeholder="Select year"
                                  class="w-100"
                                  aria-describedby="start-live-feedback"
                                  format="YYYY"
                                  v-model="goal.start_date"
                                  type="year"
                                  :default-value="minDate"
                                  :editable="true"
                                  :disabled-date="(date) => date < minDate"
                                  :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                  :formatter="formatter"/>
                      <b-form-invalid-feedback id="start-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-else>
                <b-col sm="12" md="6">
                  <div class="custom-label">Duration *</div>
                  <b-form-group>
                    <validation-provider name="duration"
                                         rules="required"
                                         vid="duration"
                                         v-slot="{ classes, errors }">
                      <b-form-input type="text"
                                    placeholder="Duration"
                                    aria-describedby="duration-live-feedback"
                                    v-model="goal.duration"
                                    :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                      <b-form-invalid-feedback id="duration-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <div class="custom-label">Start Date *</div>
                  <b-form-group>
                    <validation-provider name="start date"
                                         rules="required"
                                         vid="start_date"
                                         v-slot="{ classes, errors }">
                      <DatePicker placeholder="Select date"
                                  class="w-100"
                                  aria-describedby="start-live-feedback"
                                  format="YYYY-MM-DD"
                                  v-model="goal.start_date"
                                  :type="pickerType"
                                  :show-week-number="pickerType === 'week'"
                                  :default-value="minDate"
                                  :editable="true"
                                  :disabled-date="(date) => date < minDate"
                                  :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                  :formatter="formatter"/>
                      <b-form-invalid-feedback id="start-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div  class="w-100">
              <b-row>
                <b-col sm="12" md="12">
                  <div class="custom-label">Team</div>
                  <b-form-group>
                      <GroupSelector v-model="goal.component"
                                     :allow-empty="true"
                                     @onSelect="onGroupSelect"></GroupSelector>
                      <small class="form-text text-muted">
                        Leave this blank for company's goal.
                      </small>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
          <hr>
          <div class="d-block text-right">
            <button type="button"
                    class="mr-2 btn btn-link btn-sm"
                    :disabled="loading"
                    @click="onCancel">Cancel</button>
            <button type="button"
                    class="btn btn-success btn-sm"
                    :disabled="loading"
                    @click="onSubmit">
              <b-spinner label="Loading..."
                         small
                         v-if="loading"></b-spinner>
              {{ loading ? 'Saving data...' : 'Save' }}
            </button>
          </div>
        </b-form>
      </validation-observer>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import VueMultiselect from 'vue-multiselect'
import GroupSelector from '@/components/selectors/groups-selector'
import {useObjectivesStore} from '@/stores/objectives'

import * as GoalTypes from '@/constants/goal-types'
import ImageCropUploader from '@/components/image-crop-uploader'
import JsonFormData from 'json-form-data'
export default {
  name: 'GoalEntryForm',

  components: {
    GroupSelector,
    DatePicker,
    VueMultiselect,
    ImageCropUploader
  },

  props: {
    value: {
      type: Object,
      default: null
    }
  },

  setup(){
    const store = useObjectivesStore()

    return {
      store
    }
  },

  computed: {
    pickerType() {
      switch (true) {
      case this.goal.type.key === GoalTypes.QUARTERLY:
      case this.goal.type.key === GoalTypes.YEARLY:
        return 'year'
      case this.goal.type.key === GoalTypes.WEEKLY:
        return 'week'
      case this.goal.type.key === GoalTypes.MONTHLY:
        return 'month'
      case this.goal.type.key === GoalTypes.DAILY:
      default:
        return 'date'
      }
    },

    minDate() {
      return new Date()
    },
    endDate() {
      if (!this.goal.start_date || !this.goal.duration || !this.goal.type) {
        return null
      }

      return window.moment(this.goal.start_date).add(this.goal.duration, this.goal.type.label.toLowerCase())._d
    },

    startDate() {
      if (!this.goal.start_date) {
        return null
      }

      switch (true) {
      case this.goal.type.key === GoalTypes.WEEKLY:
        // eslint-disable-next-line no-case-declarations
        let today = new Date(this.goal.start_date)
        // ✅ Get the first day of the current week (Sunday)
        // ✅ Get the last day of the current week (Saturday)
        //const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6))

        return new Date(today.setDate(today.getDate() - today.getDay()))

        //const startDate = new Date(today.getFullYear(), 0, 1)
        //const days = Math.floor((today - startDate) / (24 * 60 * 60 * 1000))
        //return Math.ceil(days / 7)
      }

      return this.goal.start_date
    }
  },

  data() {
    return {
      GoalTypes,
      goal: this.value,
      formatter: {
        stringify: (date) => {
          if (date && this.goal.type.key === GoalTypes.QUARTERLY) {
            return window.moment(date).format('YYYY')
          }

          return date ? window.moment(date).format('LL') : ''
        },
      },

      types: [
        {
          label: 'Day',
          key: GoalTypes.DAILY
        },
        {
          label: 'Week',
          key: GoalTypes.WEEKLY
        },
        {
          label: 'Month',
          key: GoalTypes.MONTHLY
        },
        {
          label: 'Year',
          key: GoalTypes.YEARLY
        }
      ],
      loading: false,
      JsonFormOptions: {
        initialFormData: new FormData(),
        showLeafArrayIndexes: true,
        includeNullValues: true,
        mapping: function(value) {
          if (typeof value === 'boolean') {
            return +value ? '1': '0'
          }
          return value
        }
      }
    }
  },

  methods: {

    onImageAdded(imageData) {
      this.goal.image = imageData

    },
    onImageSelected(imageData) {
      this.goal.image = imageData
    },

    onSubmit() {
      this.$refs.observer.validate().then(res => {
        if (res) {
          this.loading = true
          this.goal.end_date = this.endDate
          if (this.objective) {
            this.handleUpdate()
          } else {
            this.handleCreate()
          }
        }
      })
    },
    handleCreate() {
      let formData = JsonFormData(this.goal, this.JsonFormOptions)

      this.store.create(formData).then(res => {
        console.log(res)
        if (res.status === 'success') {
          this.$emit('onCreated', res.data)
        }
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleUpdate(){
      this.store.update(this.objective.uuid, this.goal).then(res => {
        if (res.status === 'success') {
          this.$emit('onUpdated', res.data)
        }
      }).then(() => {
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    onReset(){

    },
    onCancel(){
      this.$emit('onCancel')
    },
    onGroupSelect(component){
      this.goal.component = component
    }
  },

  mounted() {
    if (!this.value) {
      this.goal = {
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        duration: null,
        type: this.types[0],
        component: null,
        componentId: null,
        componentClass: null
      }
    } else {
      const data = { ...this.value }
      data.start_date = window.moment(data.start_date)._d
      if (data.data) {
        data.data = JSON.parse(data.data)
      }
      this.goal = { ...data }
    }
  },

  watch: {
    'goal.type': function() {
      this.goal.start_date = null
    }
  }
}
</script>

<style lang="scss">
.goal-entry-form {
  .ankaCropper__saveButton {
    display: none;
  }
}
</style>
