<template>
  <div class="app-footer">
    <div class="app-footer__inner">
      <div class="app-footer-left">
        <p class="mt-1">
          <FontAwesomeIcon icon="copyright" si></FontAwesomeIcon> {{ currentYear }} Made with <FontAwesomeIcon icon="heart"></FontAwesomeIcon> by
          <a href="https://extremenetworks.com.au" target="_blank">Extreme Networks</a></p>
      </div>
<!--      <div class="app-footer-right">-->
<!--        <FooterMenu />-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
//import FooterDots from './Footer/FooterDots'
// import FooterMenu from './Footer/FooterMenu'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEllipsisV, faHeart, faCopyright } from '@fortawesome/free-solid-svg-icons'

library.add(faEllipsisV, faHeart, faCopyright)
export default {
  name: 'Footer',
  components: {
    FontAwesomeIcon
    //FooterDots,
    // FooterMenu
  },

  computed: {
    currentYear() {
      return window.moment().format('YYYY')
    }
  }
}
</script>
