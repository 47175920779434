<template>
  <b-card>
    <div class="position-relative row form-group">
      <b-col md="4">
        <label for="role" class="col-form-label">
          Job Roles <br/>
          <small class="text-muted">User assignments or designation.</small>
        </label>
      </b-col>
      <b-col md="8">
        <div class="mt-2">
          <JobRoleSelector
              v-model="role"
              :disabled="loading"
              :show-loader="loading"
              @select="onSelect"/>
        </div>
      </b-col>
    </div>
  </b-card>
</template>

<script>
import {useEmployeeStore} from '@/stores/employee'
import JobRoleSelector from '@/components/selectors/job-roles-selector'
import {useAuthStore} from '@/stores/auth'
import _ from 'lodash'
import {useJobRoleStore} from '@/stores/jobRole'
export default {
  name: 'JobRolesCard',

  components: {
    JobRoleSelector
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()
    const jobRoleStore = useJobRoleStore()
    return {
      employeeStore,
      authStore,
      jobRoleStore
    }
  },

  data() {
    return {
      edit: false,
      loading: false,
      roles: null,
      role: null
    }
  },

  methods: {
    onCancel() {
      this.setValues()
      this.edit = false
    },
    onEdit() {
      this.edit = true
    },
    onSave() {

      this.$toast.info('Updating job roles...', {
        hideProgressBar: false,
        icon: true,
        timeout: 1000,
      })

      this.loading = true
      this.employeeStore.updateJobRole(this.employeeStore.employee.uuid, { role: this.role }).then(res => {
        if (res.data.status === 'success') {
          this.edit = false
          this.employeeStore.employee.job_roles = res.data.job_roles
          const index = this.employeeStore.employees.findIndex(item => item.uuid === this.employeeStore.employee.uuid)
          if (index >= 0) {
            this.employeeStore.employees[index].job_roles = res.data.job_roles
          }

          if (this.authStore.user.uuid === this.employeeStore.employee.uuid) {
            this.authStore.user.job_roles = res.data.job_roles
            this.authStore.setUser(this.authStore.user)
          }

          this.$emit('onSave', res.data.job_roles)

          this.$toast.success('Job roles has been updated.', {
            hideProgressBar: true,
            icon: false
          })
        } else {
          this.$toast.error('An error has been encountered while processing your request. Please try again later.', {
            hideProgressBar: true,
            icon: false
          })
        }
      }).then(() => {
        this.loading = false
      })
    },
    onSelect(data) {
      this.role = data
      this.onSave()
    },
    async setValues() {
      this.roles = await _.cloneDeep(this.employeeStore.employee.job_roles)
      if (this.roles && this.roles.length > 0) {
        this.role = this.roles[0]
      }
    }
  },
  mounted() {
    this.setValues()
  }
}
</script>
