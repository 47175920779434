<template>
  <div>
    <div class="main-card mb-3 card" v-if="loading">

        <div class="card-body">
          <div class="text-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <br/>
            Fetching goals data...
          </div>
        </div>
    </div>
    <div class="main-card mb-3 card" v-if="goals.length < 1 && !loading">
        <div class="card-body" >
            Unable to find goals data..
        </div>
    </div>
    <b-row v-if="goals.length > 0">
      <b-col cols="4" v-if="daily.length > 0">
        <div class="main-card mb-3 card">
        <div class="card-header d-flex justify-content-between bg-white">
          <span>Daily Goals</span>
        </div>

        <div class="card-body">
          <div v-for="(goal, index) in daily" :key="goal.uuid">
            <GoalsWidget :goal="goal"></GoalsWidget>
            <hr v-if="(index + 1) !== daily.length">
          </div>
        </div>
      </div>
      </b-col>

      <b-col cols="4" v-if="weekly.length > 0">
        <div class="main-card mb-3 card">
          <div class="card-header d-flex justify-content-between bg-white">
            <span>Weekly Goals</span>
          </div>

          <div class="card-body">
            <div v-for="(goal, index) in weekly" :key="goal.uuid">
              <GoalsWidget :goal="goal"></GoalsWidget>
              <hr v-if="(index + 1) !== weekly.length">
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="4" v-if="monthly.length > 0">
        <div class="main-card mb-3 card">
          <div class="card-header d-flex justify-content-between bg-white">
            <span>Monthly Goals</span>
          </div>

          <div class="card-body">
            <div v-for="(goal, index) in monthly" :key="goal.uuid">
              <GoalsWidget :goal="goal"></GoalsWidget>
              <hr v-if="(index + 1) !== monthly.length">
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="4" v-if="quarterly.length > 0">
        <div class="main-card mb-3 card">
          <div class="card-header d-flex justify-content-between bg-white">
            <span>Quarterly Goals</span>
          </div>

          <div class="card-body">
            <div v-for="(goal, index) in quarterly" :key="goal.uuid">
              <GoalsWidget :goal="goal"></GoalsWidget>
              <hr v-if="(index + 1) !== quarterly.length">
            </div>
          </div>
        </div>
      </b-col>

      <b-col cols="4" v-if="yearly.length > 0">
        <div class="main-card mb-3 card">
          <div class="card-header d-flex justify-content-between bg-white">
            <span>Yearly Goals</span>
          </div>

          <div class="card-body">
            <div v-for="(goal, index) in yearly" :key="goal.uuid">
              <GoalsWidget :goal="goal"></GoalsWidget>
              <hr v-if="(index + 1) !== yearly.length">
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {useObjectivesStore} from '@/stores/objectives'
import GoalsWidget from '@/components/goals/GoalsWidget'
import * as GoalTypes from '@/constants/goal-types'

export default {
  name: 'GoalsCardWidget',

  components:{
    GoalsWidget
  },

  props:{
    type: {
      type: String,
      default: 'company'
    }
  },

  setup(){
    const store = useObjectivesStore()

    return {
      store
    }
  },

  computed: {
    daily(){
      return this.goals.filter(item => parseInt(item.type) === GoalTypes.DAILY).sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
    },
    weekly(){
      return this.goals.filter(item => parseInt(item.type) === GoalTypes.WEEKLY).sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
    },
    monthly(){
      return this.goals.filter(item => parseInt(item.type) === GoalTypes.MONTHLY).sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
    },
    quarterly(){
      return this.goals.filter(item => parseInt(item.type) === GoalTypes.QUARTERLY).sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
    },
    yearly(){
      return this.goals.filter(item => parseInt(item.type) === GoalTypes.YEARLY).sort((a, b) => parseFloat(a.duration) - parseFloat(b.duration))
    }
  },

  data(){
    return {
      goals: [],
      loading: false,
      GoalTypes
    }
  },

  mounted(){
    this.loading = true
    this.store.getList({ type: this.type }).then(res => {
      this.goals = res.data
    }).then(() => {
      this.loading = false
    })
  }
}
</script>
