<template>
  <div :class="sidebarbg" class="app-sidebar sidebar-shadow"
       @mouseover="toggleSidebarHover('add','closed-sidebar-open')"
       @mouseleave="toggleSidebarHover('remove','closed-sidebar-open')">
    <div class="app-header__logo">
      <div class="logo-src" />
      <div class="header__pane ml-auto">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
          v-bind:class="{ 'is-active' : isOpen }" @click="toggleBodyClass('closed-sidebar')">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-sidebar-content">
      <VuePerfectScrollbar class="app-sidebar-scroll" v-once>
        <sidebar-menu showOneChild :menu="menu" />
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import { SidebarMenu } from 'vue-sidebar-menu'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as storage from '@/plugins/helpers/storage'
import {useAuthStore} from '@/stores/auth'

export default {
  components: {
    SidebarMenu,
    VuePerfectScrollbar
  },

  setup(){
    const authStore = useAuthStore()

    return {
      authStore
    }
  },

  computed: {
    menu: function() {
      if (this.authStore.hasAdministrativeRights) {
        this.items.push(...this.securedItems)
      }

      return this.items
    },
  },

  data () {
    return {
      isOpen: true,
      sidebarActive: false,

      items: [
        // {
        //   header: true,
        //   title: 'Main Navigation'
        // },
        {
          title: 'Dashboard',
          href: '/',
          icon: 'pe-7s-rocket',
          // child: [
          //   {
          //     href: '/',
          //     title: 'Analytics'
          //   },
          //   {
          //     href: '/dashboards/advertisement',
          //     title: 'Advertisement'
          //   },
          //   {
          //     href: '/dashboards/management',
          //     title: 'Management'
          //   },
          //   {
          //     href: '/dashboards/helpdesk',
          //     title: 'Helpdesk'
          //   },
          //   {
          //     href: '/dashboards/monitoring',
          //     title: 'Monitoring'
          //   },
          //   {
          //     href: '/dashboards/crypto',
          //     title: 'Crypto'
          //   },
          //   {
          //     href: '/dashboards/project-management',
          //     title: 'Project Management'
          //   },
          //   {
          //     href: '/dashboards/product',
          //     title: 'Product'
          //   },
          //   {
          //     href: '/dashboards/statistics',
          //     title: 'Statistics'
          //   }
          // ]
        },
        {
          header: true,
          title: 'Team'
        },
        {
          icon: 'pe-7s-menu',
          title: 'Employee Directory',
          href: '/team/directory'
        },
        // {
        //   icon: 'pe-7s-network',
        //   title: 'Org Chart',
        //   href: '/team/org-chart'
        // },
        // {
        //   icon: 'pe-7s-network',
        //   title: 'Organizational Chart',
        //   href: '/team/org-chart'
        // },
        {
          icon: 'pe-7s-display1',
          title: 'Dream Boards',
          href: '/dream-board',
          disabled: false
        },
        {
          icon: 'pe-7s-date',
          title: 'Goals',
          href: '/goals',
          disabled: this.isDisabled(['view-goals'])
        },
        // {
        //   icon: 'pe-7s-edit',
        //   title: 'Skills Matrix',
        //   href: '/skills/matrix',
        //   disabled: false// this.isDisabled(['view-skills-matrix'])
        // },
        {
          icon: 'pe-7s-flag',
          title: 'Reviews',
          href: '/team/reviews',
          disabled: true
        },
        {
          icon: 'pe-7s-glasses',
          title: 'Observations',
          href: '/team/observations',
          disabled: true
        },
        {
          header: true,
          title: 'Chat'
        },
        {
          title: 'Chat',
          href: '/chat',
          icon: 'pe-7s-chat',
          disabled: this.isDisabled(['view-assessments'])
        },
        {
          title: 'Templates',
          href: '/chat/templates',
          icon: 'pe-7s-comment',
          disabled: this.isDisabled(['view-assessment-templates']),
          // badge: {
          //   // text: 'new',
          //   class: 'pe-7s-lock',
          //   // attributes: {}
          //   element: 'i'
          // }
        },
        {
          header: true,
          title: 'Company Settings'
        },
        {
          icon: 'pe-7s-users',
          title: 'Team',
          disabled: false,
          child: [
            {
              title: 'List',
              href: '/teams',
            },
            {
              title: 'View Chart',
              href: '/chart/teams',
            }
          ]
        },
        {
          icon: 'pe-7s-map-2',
          title: 'Locations',
          disabled: false,
          child: [
            {
              title: 'List',
              href: '/locations',
            },
            {
              title: 'View Chart',
              href: '/chart/locations',
            }
          ]
        },
        // {
        //   icon: 'pe-7s-magic-wand',
        //   title: 'Skills',
        //   href: '/skills',
        //   disabled: false
        // },
        {
          icon: 'pe-7s-magic-wand',
          title: 'Skills',
          href: '/skills',
          child: [
            {
              title: 'Skills Matrix',
              href: '/skills/matrix',
            },
            {
              title: 'Skills Assessments',
              href: '/skills/assessments',
            }
          ]
        },
        // {
        //   icon: 'pe-7s-cup',
        //   title: 'Skills Assessments',
        //   href: '/skills/assessments',
        //   disabled: false
        // },
        {
          icon: 'pe-7s-piggy',
          title: 'Compensations',
          href: '/compensations',
          disabled: true
        },
        {
          icon: 'pe-7s-lintern',
          title: 'Job Roles',
          disabled: false,
          child: [
            {
              title: 'List',
              href: '/job-roles',
            },
            {
              title: 'View Details',
              href: '/chart/job-roles',
            }
          ]
        },
        {
          icon: 'pe-7s-helm',
          title: 'Onboarding',
          href: '/onboarding',
          disabled: false
        }
      ],
      securedItems: [
        {
          header: true,
          title: 'Security',
          visible: false
        },
        {
          icon: 'pe-7s-door-lock',
          title: 'Privileges',
          href: '/privileges',
          disabled: false
        },
        {
          icon: 'pe-7s-note2',
          title: 'Activity Logs',
          href: '/activities',
          disabled: false
        },
      ],
      collapsed: true,

      windowWidth: 0
    }
  },
  props: {
    sidebarbg: String
  },
  methods: {
    isDisabled(permissions){
      const user = JSON.parse(storage.local.getItem('user'))
      return user && !user.roles.includes('Owner') &&
          (user.permissions.some(r => permissions.indexOf(r) < 0) &&
              permissions.some(r => user.permissions.indexOf(r) < 0))
    },
    toggleBodyClass (className) {
      const el = document.body
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        el.classList.add(className)
      } else {
        el.classList.remove(className)
      }
    },
    toggleSidebarHover (add, className) {
      const el = document.body
      this.sidebarActive = !this.sidebarActive

      this.windowWidth = document.documentElement.clientWidth

      if (this.windowWidth > '992') {
        if (add === 'add') {
          el.classList.add(className)
        } else {
          el.classList.remove(className)
        }
      }
    },
    getWindowWidth () {
      const el = document.body

      this.windowWidth = document.documentElement.clientWidth

      if (this.windowWidth < '1350') {
        el.classList.add('closed-sidebar', 'closed-sidebar-md')
      } else {
        el.classList.remove('closed-sidebar', 'closed-sidebar-md')
      }
    }
  },
  mounted () {
    this.$nextTick(function() {
      window.addEventListener('resize', this.getWindowWidth)

      //Init
      this.getWindowWidth()

      const el = document.body
      if (this.windowWidth > '992') {
        el.classList.add('closed-sidebar')
      }
    })
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
  }
}
</script>
