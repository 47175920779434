<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  size="sm"
                  class="text-white"
                  @click="showGoalFormDialog = true">
          <font-awesome-icon class="mr-2" icon="plus" />Add Your Dream
        </b-button>
      </template>
    </page-title>

    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-overlay class="mt-2 text-center"
                   :show="loading"
                   rounded="sm">
          <b-row>
            <b-col sm="12" v-if="goals.length < 1 && !loading">
              There's not a dream created from your team yet.
            </b-col>
          </b-row>
          <List :goals="goals"
                :show-like="false"
                :show-action-buttons="false"
                v-if="goals.length > 0"></List>
          <template #overlay>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <br/>
            Fetching your team's dream list...
          </template>
        </b-overlay>

        <b-modal title="Add Your Dream"
                 hide-footer
                 hide-header-close
                 no-close-on-esc
                 no-close-on-backdrop
                 v-model="showGoalFormDialog"
                 @hide="showGoalFormDialog = false">
          <GoalsForm :employee="authStore.user"
                     @onCancel="showGoalFormDialog = false"
                     @onCreated="onGoalCreated"/>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import List from '@/components/dream-board/List'
import {useEmployeeStore} from '@/stores/employee'
import GoalsForm from '@/components/dream-board/Form'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faPlus, faEllipsisV, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {useAuthStore} from '@/stores/auth'

library.add(faStar, faPlus,faEllipsisV, faEye)
export default {
  name: 'DreamBoards',

  components: {
    PageTitle,
    List,
    FontAwesomeIcon,
    GoalsForm
  },

  setup(){
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()
    return {
      employeeStore,
      authStore
    }
  },

  computed: {
    goals(){
      if (!this.employeeStore.goals) {
        return []
      }

      return this.employeeStore.goals.map(item => {
        return {...item, imageUrl: item.media, thumbUrl: item.media, caption: item.description}
      })
    }
  },

  data: () => ({
    heading: 'Dream Board',
    subheading:
        'Our team\'s dream.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    loading: false,
    searchText: '',
    abortController : null,
    dreams: [
      {
        image: 'https://images.unsplash.com/photo-1479660656269-197ebb83b540?dpr=2&auto=compress,format&fit=crop&w=1199&h=798&q=80&cs=tinysrgb&crop=',
        title: 'Canyons',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479659929431-4342107adfc1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=',
        title: 'Beaches',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479644025832-60dabb8be2a1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=',
        title: 'Trees',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479621051492-5a6f9bd9e51a?dpr=2&auto=compress,format&fit=crop&w=1199&h=811&q=80&cs=tinysrgb&crop=',
        title: 'Lake',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479660656269-197ebb83b540?dpr=2&auto=compress,format&fit=crop&w=1199&h=798&q=80&cs=tinysrgb&crop=',
        title: 'Canyons',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479659929431-4342107adfc1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=',
        title: 'Beaches',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479644025832-60dabb8be2a1?dpr=2&auto=compress,format&fit=crop&w=1199&h=799&q=80&cs=tinysrgb&crop=',
        title: 'Trees',
        description: ''
      },
      {
        image: 'https://images.unsplash.com/photo-1479621051492-5a6f9bd9e51a?dpr=2&auto=compress,format&fit=crop&w=1199&h=811&q=80&cs=tinysrgb&crop=',
        title: 'Lake',
        description: ''
      }
    ],
    showGoalFormDialog: false
  }),

  methods: {
    onGoalCreated(data){
      this.employeeStore.goals.push(data)
      this.showGoalFormDialog = false
    }
  },

  mounted(){
    if (!this.employeeStore.goals) {
      this.loading = true
      this.employeeStore.getGoals().then(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.parallax-container {
  //padding: 40px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
