<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon"></page-title>
    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <b-row>
          <b-col sm="12" md="6" offset-md="3">
            <validation-observer ref="observer"
                                 v-slot="{ reset }">
              <b-form ref="form"
                      class="registration-form"
                      @submit.prevent="onSubmit"
                      @reset.prevent="reset(onReset)">
                <v-stepper v-model="stepper" vertical>
                  <v-stepper-step  step="1"
                                   :editable="false"
                                   :complete="stepper > 1">
                    Basic Information
                    <small>Employee Personal Data</small>
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <validation-observer ref="BasicInfoObserver">
                      <div class="mb-2">
                        <div class="w-100">
                          <div class="mb-4">
                            <div class="custom-label w-25">Name * </div>
                          </div>
                          <b-row>
                            <b-col sm="12" md="8">
                              <b-form-group>
                                <validation-provider name="firstname"
                                                     rules="required"
                                                     vid="first_name"
                                                     v-slot="{ classes, errors }">
                                  <b-form-input type="text"
                                                placeholder="First"
                                                aria-describedby="first-name-live-feedback"
                                                v-model="employee.first_name"
                                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                                  <b-form-invalid-feedback id="first-name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>

                            <b-col sm="12" md="4">
                              <b-form-group>
                                <b-form-input v-model="employee.middle_name"
                                              type="text"
                                              placeholder="Middle"/>
                              </b-form-group>
                            </b-col>

                            <b-col sm="12" md="12">
                              <b-form-group>
                                <validation-provider name="lastname"
                                                     rules="required"
                                                     vid="last_name"
                                                     v-slot="{ classes, errors }">
                                  <b-form-input aria-describedby="last-name-live-feedback"
                                                type="text"
                                                placeholder="Last"
                                                v-model="employee.last_name"
                                                :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                                  <b-form-invalid-feedback id="last-name-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="mb-4">
                          <div class="custom-label w-25">Gender *</div>
                        </div>
                        <div class="w-100">
                          <b-row>
                            <b-col sm="12" md="8">
                              <b-form-group>
                                <gender-selector v-model="gender"
                                                 @onSelect="onGenderSelect"></gender-selector>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="mb-4">
                          <div class="custom-label w-25">Birthdate *</div>
                        </div>
                        <div  class="w-100">
                          <b-row>
                            <b-col sm="12" md="8">
                              <b-form-group>
                                <validation-provider name="birthdate"
                                                     rules="required"
                                                     vid="birthdate"
                                                     v-slot="{ classes, errors }">
                                  <DatePicker placeholder="Select date"
                                              class="w-100"
                                              v-model="employee.birthdate"
                                              format="YYYY-MM-DD"
                                              type="date"
                                              aria-describedby="birthdate-live-feedback"
                                              :default-value="birthdateMaxDate"
                                              :editable="true"
                                              :disabled-date="(date) => date >= birthdateMaxDate"
                                              :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                              :formatter="formatter"/>
                                  <b-form-invalid-feedback id="birthdate-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="mb-4">
                          <div class="custom-label w-25">Email *</div>

                        </div>
                        <div class="w-100">
                          <b-row>
                            <b-col sm="12" md="12">
                              <b-form-group>
                                <validation-provider name="email"
                                                     :rules="{ required: true, email:true }"
                                                     vid="email"
                                                     v-slot="{ classes, errors }">
                                  <b-input-group :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']">
                                    <template #prepend>
                                      <b-input-group-text >
                                        <font-awesome-icon icon="envelope"></font-awesome-icon>
                                      </b-input-group-text>
                                    </template>
                                    <b-form-input type="text"
                                                  placeholder="Email Address"
                                                  aria-describedby="email-live-feedback"
                                                  v-model="employee.email"
                                                  :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"/>
                                  </b-input-group>
                                  <b-form-invalid-feedback id="email-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="mb-4">
                          <div class="custom-label w-25">Mobile </div>
                        </div>
                        <div  class="w-100">
                          <b-row>
                            <b-col sm="12" md="12">
                              <b-form-group>
                                <b-input-group>
                                  <template #prepend>
                                    <b-input-group-text >
                                      <font-awesome-icon icon="mobile"></font-awesome-icon>
                                    </b-input-group-text>
                                  </template>
                                  <b-form-input v-model="employee.mobile"
                                                type="text"
                                                placeholder="Mobile No."/>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <div class="mb-4">
                          <div class="custom-label w-25">Phone </div>
                        </div>
                        <div class="w-100">
                          <b-row>
                            <b-col sm="12" md="12">
                              <b-form-group>
                                <b-input-group>
                                  <template #prepend>
                                    <b-input-group-text >
                                      <font-awesome-icon icon="phone"></font-awesome-icon>
                                    </b-input-group-text>
                                  </template>
                                  <b-form-input v-model="employee.phone"
                                                type="text"
                                                placeholder="Phone No."/>
                                </b-input-group>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>

                        <v-btn color="primary" @click="validateBasicInfo">Continue</v-btn>
                      </div>
                    </validation-observer>
                  </v-stepper-content>

                  <v-stepper-step step="2"
                                  :editable="false"
                                  :complete="stepper > 2">
                    Work Information
                    <small>Employee Work Data</small>
                  </v-stepper-step>
                  <v-stepper-content step="2">
                    <validation-observer ref="WorkInfoObserver">
                      <div class="mb-4">
                        <div class="custom-label w-25">Job Role *</div>
                      </div>
                      <div class="w-100">
                        <b-row>
                          <b-col sm="12" md="8">
                            <b-form-group>
                              <validation-provider name="job role"
                                                   rules="required"
                                                   vid="job-role"
                                                   v-slot="{ classes, errors }">
                                <job-role-selector aria-describedby="job-role-live-feedback"
                                                   v-model="employee.job_roles"
                                                   :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                                   @onSelect="onJobRoleSelect"/>
                                <b-form-invalid-feedback id="job-role-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>

                      <div class="mb-4">
                        <div class="custom-label w-25">Team / Department *</div>
                      </div>
                      <div class="w-100">
                        <b-row>
                          <b-col sm="12" md="8">
                            <b-form-group>
                              <validation-provider name="group"
                                                   rules="required"
                                                   vid="group"
                                                   v-slot="{ classes, errors }">
                                <groups-selector aria-describedby="group-live-feedback"
                                                 v-model="employee.group"
                                                 :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                                 @onSelect="onGroupSelect"></groups-selector>
                                <b-form-invalid-feedback id="group-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>

                      <div class="mb-4">
                        <div class="custom-label w-25">Location *</div>
                      </div>
                      <div class="w-100">
                        <b-row>
                          <b-col sm="12" md="8">
                            <b-form-group>
                              <validation-provider name="location"
                                                   rules="required"
                                                   vid="location"
                                                   v-slot="{ classes, errors }">
                                <location-selector aria-describedby="group-live-feedback"
                                                 v-model="employee.location"
                                                 :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                                 @onSelect="onLocationSelect"></location-selector>
                                <b-form-invalid-feedback id="location-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>

                      <v-btn color="primary"
                             :loading="loading"
                             @click="validateWorkInfo">Continue</v-btn>
                      <v-btn text
                             class="ml-2"
                             :disabled="loading"
                             @click="stepper = 1">Back</v-btn>
                    </validation-observer>
                  </v-stepper-content>

                  <v-stepper-step step="3"
                                  :editable="false"
                                  :complete="stepper > 3">
                    Security
                    <small>Roles and Permissions</small>
                  </v-stepper-step>
                  <v-stepper-content step="3">
                    <validation-observer ref="RolesInfoObserver">
                      <div class="mb-4">
                        <div class="custom-label w-25">Role *</div>
                      </div>
                      <div class="w-100">
                        <b-row>
                          <b-col sm="12" md="8">
                            <b-form-group>
                              <validation-provider name="role"
                                                   rules="required"
                                                   vid="role"
                                                   v-slot="{ classes, errors }">
                                <role-selector aria-describedby="role-live-feedback"
                                                   v-model="employee.role"
                                                   :class="[!classes.valid && errors.length > 0 ? 'is-invalid' : '']"
                                                   @onSelect="onRoleSelect"/>
                                <b-form-invalid-feedback id="role-live-feedback">{{ errors[0] }}</b-form-invalid-feedback>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>

                      <v-btn color="primary"
                             :loading="loading"
                             @click="validateRolesInfo">Save</v-btn>
                      <v-btn text
                             class="ml-2"
                             :disabled="loading"
                             @click="stepper = 2">Back</v-btn>
                    </validation-observer>
                  </v-stepper-content>
                </v-stepper>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import GenderSelector from '@/components/selectors/gender-selector'
import JobRoleSelector from '@/components/selectors/job-roles-selector'
import GroupsSelector from '@/components/selectors/groups-selector'
import LocationSelector from '@/components/selectors/location-selector'
import RoleSelector from '@/components/selectors/role-selector'
import DatePicker from 'vue2-datepicker'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faArrowLeft,
  faAngleUp,
  faDotCircle,
  faAngleDown,
  faSearch,
  faEnvelope,
  faMobile,
  faPhone
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Gender from '@/constants/gender'
import {useEmployeeStore} from '@/stores/employee'
library.add(faArrowRight, faAngleUp, faArrowLeft, faDotCircle, faAngleDown, faSearch, faEnvelope, faMobile, faPhone)

export default {
  name: 'EmployeeRegistration',
  components: {
    DatePicker,
    FontAwesomeIcon,
    GenderSelector,
    GroupsSelector,
    JobRoleSelector,
    LocationSelector,
    RoleSelector,
    PageTitle
  },

  setup(){
    const store = useEmployeeStore()

    return {
      store
    }
  },

  data: () => ({
    heading: 'Employee Registration',
    subheading:
        'Add new employee.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    onEdit: false,
    employee: {
      first_name: null,
      middle_name: null,
      last_name: null,
      gender: Gender.Male,
      birthdate: null,
      email: null,
      mobile: null,
      phone: null,
      date_hired: null,
      job_roles: null,
      group:null,
      location: null,
      role: null,
      metafields: {}
    },
    showValueLabel: false,
    showInputs: false,
    showSkeletonLoader: false,
    loading: false,
    formatter: {
      stringify: (date) => {
        return date ? window.moment(date).format('LL') : ''
      },
    },
    stepper: 1
  }),

  computed:{
    gender(){
      return {
        id: Gender.Male,
        label: 'Male'
      }
    },
    birthdateMaxDate(){
      return window.moment().subtract(18, 'year')._d
    }
  },
  methods: {
    async onSubmit(){
      const confirm = await this.$dialog.confirm({ text: 'You are about to save a new employee data. Continue?', actions: ['No', 'Yes'] })

      if (confirm === 'Yes') {
        this.loading = true
        this.store.storeEmployee(this.employee).then((res) => {
          if (res.status === 'success') {
            this.$toast.success('New employee data has been saved.', {
              hideProgressBar: true,
              icon: false
            })

            this.store.created = true

            this.$router.push({ name: 'team' })
          }
        }).catch(() => {
          this.$toast.error('Error while processing your request. Please try again.', {
            hideProgressBar: true,
            icon: false
          })
        }).then(() => {
          this.loading = false
        })
      }
    },
    onReset(){},
    onGenderSelect (data) {
      this.employee.gender = data.id
    },
    onJobRoleSelect (jobRole) {
      this.employee.job_roles = jobRole
    },
    onRoleSelect (role) {
      this.employee.role = role
    },
    onGroupSelect (group) {
      this.employee.group = group
    },
    onLocationSelect(data){
      this.employee.location = data
    },
    validateBasicInfo () {
      this.$refs.BasicInfoObserver.validate().then(res => {
        if (res) {
          this.stepper = 2
        }
      })
    },
    async validateWorkInfo () {
      this.$refs.WorkInfoObserver.validate().then(res => {
        if (res) {
          this.stepper = 3
        }
      })
    },
    async validateRolesInfo () {
      this.$refs.RolesInfoObserver.validate().then(res => {
        if (res) {
          this.onSubmit()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.registration-form {
  .multiselect__content-wrapper {
    position: relative !important;
  }
}
</style>