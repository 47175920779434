<template>
  <div>
    <page-title :heading="heading"
                :subheading="subheading"
                :icon="icon">
      <template slot="actions">
        <b-button type="button"
                  variant="primary"
                  class="text-white"
                  size="sm"
                  :disabled="isLoading"
                  @click="showFormDialog = true">
          <font-awesome-icon class="mr-2" icon="plus" />New Location
        </b-button>
      </template>
    </page-title>


    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <v-data-table
              loading-text="Loading... Please wait"
              height="55vh"
              class="elevation-1"
              :loading="loading"
              :fixed-header="true"
              :headers="columns"
              :items="locations"
              :items-per-page="10"
              :hide-default-header="false"
              :sort-by="['name']"
              :sort-desc="[false, true]">
            <template v-slot:top>
              <div class="p-3">
                <b-row>
                  <b-col sm="12" md="6"></b-col>
                  <b-col sm="12"
                         md="6"
                         class="d-flex justify-content-end">

                    <div class="w-50">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">
                            <font-awesome-icon icon="search" />
                          </div>
                        </div>
                        <input placeholder="Search location..."
                               type="text"
                               class="form-control"
                               v-model="searchText"
                               @input="onSearchInputChange"/>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:item.name="{ item }">
              <span :style="`color:` + item.color || `#FFFF00`">{{ item.name }}</span>
            </template>
            <template v-slot:item.description="{ item }">
              {{ item.description }}
            </template>
            <template v-slot:item.address="{ item }">
              {{ item.address}}
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | moment('MMM DD, YYYY') }}
            </template>
            <template v-slot:item.actions="{ item }">
              <b-dropdown class="mx-1"
                          variant="white"
                          right
                          text="menu"
                          no-caret>
                <b-dropdown-item @click="onEdit(item)">
                  <font-awesome-icon icon="pencil" class="mr-2"/>
                  Edit
                </b-dropdown-item>
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-vertical"/>
                </template>
              </b-dropdown>
            </template>
          </v-data-table>
        <b-modal :title="(selectedLocation ? 'Edit' : 'Add') + ' Location'"
                   hide-footer
                   hide-header-close
                   no-close-on-esc
                   no-close-on-backdrop
                   v-model="showFormDialog"
                   @hide="showFormDialog = false; selectedLocation = null;">
            <LocationForm :location="selectedLocation"
                          @onCancel="showFormDialog = false"
                          @onUpdated="onLocationUpdated"
                          @onCreated="onLocationCreated"/>
          </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faPlus, faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useLocationStore } from '@/stores/location'

import LocationForm from '@/components/locations/Form'
import _ from 'lodash'

library.add(faStar, faPlus,faEllipsisV, faPencil)
export default {
  name: 'Locations',
  components: {
    PageTitle,
    FontAwesomeIcon,
    LocationForm
  },
  setup(){
    const store = useLocationStore()

    return {
      store
    }
  },
  data: () => ({
    heading: 'Locations',
    subheading:
        'Locations or offices.',
    icon: 'pe-7s-map-2 icon-gradient bg-tempting-azure',
    locations: [],
    columns: [
      // {
      //   name: 'id',
      //   label: '#',
      //   sortable: false
      // },
      {
        value: 'name',
        text: 'Name',
        sortable: false
      },
      {
        value: 'description',
        text: 'Description',
        sortable: false
      },
      {
        value: 'address',
        text: 'Address',
        sortable: false
      },
      {
        value: 'created_at',
        text: 'Date Created',
        sortable: false
      },
      {
        value: 'actions',
        text: '',
        sortable: false
      }
    ],
    loading: false,
    isLoadingMore: false,
    showViewModal: false,
    showFormDialog: false,
    selectedLocation: null,
    searchText: ''
  }),
  methods: {
    onEdit(data){
      this.selectedLocation = data
      this.showFormDialog = true
    },
    onViewModalHide(){
      this.showViewModal = false
    },
    onLocationUpdated(data){
      const index = this.store.locations.findIndex(item => item.uuid === data.uuid)
      if (index >= 0) {
        this.store.locations[index] = data
      }
      this.showFormDialog = false
      this.locations = this.store.locations
    },
    onLocationCreated(){
      this.locations = this.store.locations
      this.showFormDialog = false
    },
    onSearchInputChange: _.debounce(function (){
      this.locations = this.store.locations.filter(item =>
        item.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          (item.description && item.description.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (item.address && item.address.toLowerCase().includes(this.searchText.toLowerCase())))
    }, 1000),
  },
  mounted () {
    if (this.store.locations.length < 1) {
      this.loading = true
      this.store.getList().then(() => {
        this.locations = this.store.locations
        this.loading = false
      })
    } else {
      this.locations = this.store.locations
    }
  }
}
</script>
