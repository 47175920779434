import Router from 'vue-router'
import routes from './routes'
import store from '@/stores'
import { useAuthStore } from '@/stores/auth'
import * as storage from '@/plugins/helpers/storage'

const authStore = useAuthStore(store)

const router = new Router({
  scrollBehavior(){
    return window.scrollTo({ top: 0, behavior: 'smooth' })
  },
  mode: 'history',
  routes: routes
})

const appendAppName = ' | ' + process.env.VUE_APP_NAME

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  const user = JSON.parse(storage.local.getItem('user'))
  // if (to.meta.protected && user && !user.roles.includes('Owner') &&
  //     (user.permissions.some(r => to.meta.permissions.indexOf(r) < 0) &&
  //       to.meta.permissions.some(r => user.permissions.indexOf(r) < 0))) {
  //   router.push({ name: 'unauthorized' })
  // }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + appendAppName
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title + appendAppName
  }

  if (to.meta.protected && user && user.roles.map(i => i['name']).some(r => ['Owner', 'Administrator', 'Super Admin', 'Admin'].indexOf(r) < 0) && to.meta.permissions.some(r => user.permissions.indexOf(r) < 0)) {
    router.push({ name: 'unauthorized' })
  }

  // switch (true) {
  // case to.meta.protected:
  // case user && !user.roles.some(r => ['Owner', 'Administrator', 'Super Admin', 'Admin'].indexOf(r) >= 0):
  // case to.meta.permissions.some(r => user.permissions.indexOf(r) < 0):
  //   router.push({ name: 'unauthorized' })
  // }

  if (user && !user.email_verified_at && !['verify-email', 'login', 'logout'].includes(to.name)) {
    router.push({ name: 'verify-email' })
  }
  if (
    authStore.tenant &&
    (
      (authStore.tenant.groups && authStore.tenant.groups.length < 1) ||
      (authStore.tenant.locations && authStore.tenant.locations.length < 1)
    ) && !['company-onboard'].includes(to.name)) {
    router.push({ name: 'company-onboard' })
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    (el) => el.parentNode.removeChild(el)
  )

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))
  console.log(authStore.user.roles.includes('Owner') || authStore.user.permissions.includes(to.meta.permission))

  if (authStore.user.roles.includes('Owner') || authStore.user.permissions.includes(to.meta.permission)) {
    return next()
  } else {
    router.push({ name: 'unauthorized' })
  }

  if (to.path !== '/login' && !authStore.authenticated) {
    next('/login')
  } else {
    next('/')
  }
  next()

})

export default router
