<template>
  <div>
    <page-title :heading="heading" :subheading="subheading" :icon="icon">
      <template slot="actions">
        <b-link type="button"
                class="btn-shadow d-inline-flex align-items-center btn btn-primary text-white"
                :to="{ name: 'new-assessment' }">
          <font-awesome-icon class="mr-2" icon="plus" />New Chat
        </b-link>
      </template>
    </page-title>


    <div class="app-inner-layout__wrapper padded">
      <div class="app-inner-layout__content">
        <v-data-table
            loading-text="Loading... Please wait"
            height="55vh"
            class="elevation-1"
            :loading="loading"
            :fixed-header="true"
            :headers="columns"
            :items="assessments"
            :items-per-page="10"
            :hide-default-header="false"
            :sort-by="['last_name']"
            :sort-desc="[false, true]">
          <template v-slot:top>
            <div class="p-3">
              <b-row>
                <b-col sm="12" md="6"></b-col>
                <b-col sm="12"
                       md="6"
                       class="d-flex justify-content-end">

                  <div class="ml-2 dropdown-filter-wrapper">
                    <b-dropdown toggle-class="p-0"
                                variant="primary"
                                ref="filterDropdown"
                                size="sm"
                                block
                                no-caret right
                                :disabled="loading"
                                @show="isDropdownClosable = false"
                                @hide="handleHide">
                      <div slot="button-content">
                        <div class="icon-wrapper icon-wrapper-alt rounded-circle ml-2">
                          <font-awesome-icon icon="filter" class="mr-2"/> Filter
                        </div>
                      </div>
                      <AssessmentFilter @apply="onApply"
                                        @reset="onReset"
                                        @close="onCloseFilter"/>
                    </b-dropdown>
                  </div>
                  <b-button variant="outline-warning"
                            class="ml-2"
                            :disabled="!isFilterApplied || loading"
                            @click="onClear">
                    Clear Filter
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </template>

          <template v-slot:item.chat_by="{ item }">
            <AvatarComponent :employee="item.assessment_from"></AvatarComponent>
            {{ item.assessment_from.first_name }} {{ item.assessment_from.last_name }}
          </template>

          <template v-slot:item.chat_to="{ item }">
            <AvatarComponent :employee="item.assessment_for"></AvatarComponent>
            {{ item.assessment_for.first_name }} {{ item.assessment_for.last_name }}
          </template>

          <template v-slot:item.conducted="{ item }">
            <item-date-time :key="item.id"
                            :from-time="item.created_at"
                            :update-interval="5000" />
          </template>

          <template v-slot:item.actions="{ item }">
            <b-dropdown class="mx-1"
                        variant="white"
                        right
                        text="menu"
                        no-caret>
              <b-dropdown-item :to="{ name: 'assessment-details', params: { id: item.uuid } }" >
                <font-awesome-icon icon="eye" class="mr-2"/>
                View
              </b-dropdown-item>
              <template #button-content>
                <font-awesome-icon icon="ellipsis-vertical"/>
              </template>
            </b-dropdown>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faTags,
  faStar,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { useAssessmentStore } from '@/stores/assessment'
import ItemDateTime from '@/components/item-date-time'
import AssessmentFilter from '@/components/assessment/AssessmentFilter'

library.add(faAngleDown, faTags, faStar, faSearch)
import AvatarComponent from '@/components/employee/AvatarComponent'

export default {
  components: {
    ItemDateTime,
    PageTitle,
    'font-awesome-icon': FontAwesomeIcon,
    AssessmentFilter,
    AvatarComponent
  },

  setup () {
    const store = useAssessmentStore()
    return {
      store
    }
  },

  data: () => ({
    heading: 'Chat',
    subheading: 'Chat conversations with colleagues or team mates.',
    icon: 'pe-7s-chat icon-gradient bg-mixed-hopes',

    isMobileOpen: false,
    columns: [
      // {
      //   name: 'id',
      //   label: '#',
      //   text: '#',
      //   sortable: false
      // },
      {
        value: 'title',
        label: 'Title',
        text: 'Title',
        sortable: false
      },
      {
        value: 'description',
        label: 'Description',
        text: 'Description',
        sortable: false
      },
      {
        value: 'chat_by',
        label: 'Chat by',
        text: 'Chat by',
        sortable: false
      },
      {
        value: 'chat_to',
        label: 'Chat to',
        text: 'Chat to',
        sortable: false
      },
      {
        value: 'conducted',
        label: 'Conducted',
        text: 'Conducted',
        sortable: false
      },
      {
        value: 'actions',
        label: '',
        text: '',
        sortable: false
      }
    ],
    row: 10,
    assessments:[],
    loading: false,
    isDropdownClosable: false,
    searchText: null,
    isFilterApplied: false
  }),

  methods: {
    onSearchInputChange() {},
    onApply(filter) {
      this.loading = true
      this.isFilterApplied = true
      this.assessments = []
      this.isDropdownClosable = true
      this.$refs.filterDropdown.hide()
      this.store.getAssessments({
        chat_by: filter.chat_by ? filter.chat_by.uuid: null,
        chat_for: filter.chat_for ? filter.chat_for.uuid: null,
        conducted_last_from: filter.conductedLast ? filter.conductedLast[0] : null,
        conducted_last_to: filter.conductedLast ? filter.conductedLast[1] : null,
      }).then(res => {
        this.store.assessments = res.data
        this.assessments = this.store.assessments
        this.loading = false
      })
    },
    onCloseFilter () {
      this.isDropdownClosable = true
      this.$refs.filterDropdown.hide()
    },
    onClear() {
      this.loading = true
      this.isFilterApplied = false
      this.assessments = []
      this.isDropdownClosable = true
      this.$refs.filterDropdown.hide()
      this.store.getAssessments().then(res => {
        this.store.assessments = res.data
        this.assessments = this.store.assessments
        this.loading = false
      })
    },

    onReset() {
      if (this.isFilterApplied) {
        this.isFilterApplied = false
      }
    },

    handleHide (event){
      if (!this.isDropdownClosable) {
        event.preventDefault()
      }
    }
  },
  mounted () {
    this.loading = true
    this.store.getAssessments().then(res => {
      this.store.assessments = res.data
      this.assessments = this.store.assessments
      this.loading = false
    })
  }
}
</script>
