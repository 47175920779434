<template>
  <div>
    <page-title
        :heading="heading"
        :subheading="subheading"
        :icon="icon"
    />
    <div class="app-inner-layout__wrapper padded">
      <div
          class="app-inner-layout__content">
        <b-row>
          <b-col
              sm="12"
              lg="12"
          >
            <AssessmentWidget
                :employee="authStore.user"
            />
          </b-col>
          <b-col
              v-if="employeeStore.todaysBirthdays.length > 0"
              sm="12"
              lg="6"
          >
<!--            <TodaysBirthdayComponent></TodaysBirthdayComponent>-->
          </b-col>
          <b-col
              sm="12"
              lg="6"
          >
<!--            <BirthdaysComponent></BirthdaysComponent>-->
          </b-col>
<!--          <b-col sm="12" lg="12" v-if="authStore.can(['view-goals'])">-->
<!--            <h6 class="text-uppercase mt-5">Company Wide Goals</h6>-->
<!--            <hr>-->
<!--            <GoalsCardWidget></GoalsCardWidget>-->
<!--          </b-col>-->

<!--          <b-col sm="12"-->
<!--                 lg="12"-->
<!--                 v-if="authStore.can(['view-team-goals'])">-->
<!--            <h6 class="text-uppercase mt-4">Team Goals</h6>-->
<!--            <hr>-->
<!--            <GoalsCardWidget type="group"></GoalsCardWidget>-->
<!--          </b-col>-->

<!--          <b-col sm="12" lg="12" class="mt-2">-->
<!--            <div class="main-card mb-3 card">-->
<!--              <div class="card-header d-flex justify-content-between bg-white">-->
<!--                <span>Dream Board</span>-->
<!--              </div>-->
<!--              <b-overlay :show="loadingGoals"-->
<!--                         rounded="sm">-->
<!--                <div class="card-body">-->
<!--                  <GoalsList v-if="employeeStore.goals && employeeStore.goals.length > 0"-->
<!--                             :show-like="false"-->
<!--                             :show-details="true"-->
<!--                             :show-action-buttons="false"-->
<!--                             :goals="employeeStore.goals.slice(0, 4)"/>-->

<!--                  <div v-else-if="(!employeeStore.goals || employeeStore.goals.length < 1) && !loadingGoals"-->
<!--                       class="text-center">-->
<!--                    There's not a goal or dream created from your team yet.-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="card-footer bg-white d-flex justify-content-center">-->
<!--                  <b-link class="text-center" :to="{ name: 'dream-board' }">See more...</b-link>-->
<!--                </div>-->
<!--                <template #overlay>-->
<!--                 <div class="text-center">-->
<!--                   <b-spinner variant="primary" label="Spinning"></b-spinner>-->
<!--                   <br/>-->
<!--                   Fetching your team's dream data...-->
<!--                 </div>-->
<!--                </template>-->
<!--              </b-overlay>-->
<!--            </div>-->
<!--          </b-col>-->
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/layout/PageTitle'
import BirthdaysComponent from '@/components/widgets/BirthdaysComponent'
import TodaysBirthdayComponent from '@/components/widgets/TodaysBirthdayComponent'
import { useEmployeeStore } from '@/stores/employee'
import GoalsList from '@/components/dream-board/List'
import GoalsCardWidget from '@/components/goals/GoalsCardWidget'
import AssessmentWidget from '@/components/assessment/AssessmentWidget'

import {useAuthStore} from '@/stores/auth'

export default {
  name: 'Dashboard',

  components: {
    PageTitle,
    GoalsList,
    GoalsCardWidget,
    BirthdaysComponent,
    TodaysBirthdayComponent,
    AssessmentWidget
  },

  setup () {
    const employeeStore = useEmployeeStore()
    const authStore = useAuthStore()
    return {
      employeeStore,
      authStore
    }
  },

  data: () => ({
    heading: 'Dashboard',
    subheading:
        'Quick summary or analytics data.',
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    loadingGoals: false,
  }),

  mounted () {
    // this.loadingGoals = true
    // this.employeeStore.getGoals().then(() => {
    //   this.loadingGoals = false
    // })
  }
}
</script>
