<template>
  <div>
    <div class="main-card mb-3 card">
      <div class="card-header d-flex justify-content-between bg-white">
        <span>Chats</span>
      </div>
      <div class="card-body p-0">
        <b-alert variant="warning"
                 class="mb-0"
                 :show="hasNoAssessmentIn90Days">You don't have any chats conducted for the past 90 days!</b-alert>
      </div>
      <div class="card-body p-0">
        <b-tabs
            class="card-header-tab-animation"
            card
            v-model="tabIndex"
        >
          <b-tab title="Recent"
                 active>
            <b-overlay :show="loadingRecentChats"
                       rounded="sm">
              <VuePerfectScrollbar class="scrollbar-container">
                <div class="scrollable-area">
                  <div v-if="assessmentsFrom.length > 0">
                    <h6 class="text-muted text-uppercase font-size-md font-weight-normal">Chats for you</h6>
                    <div v-for="chat in assessmentsFrom"
                         :key="chat.uuid">
                      <b-link :to="{ name: 'assessment-details', params: { id: chat.uuid } }">{{ chat.title }} - {{ chat.assessment_for.first_name }} {{ chat.assessment_for.last_name }}
                      </b-link>
                      <small>
                        <item-date-time :key="chat.id"
                                        :from-time="chat.created_at"
                                        :update-interval="5000" />
                      </small>
                    </div>
                  </div>

                  <div v-if="assessmentsTo.length > 0">
                    <h6 class="text-muted text-uppercase font-size-md font-weight-normal">Chats you conducted</h6>
                    <div v-for="chat in assessmentsTo"
                         :key="chat.uuid">
                      <b-link :to="{ name: 'assessment-details', params: { id: chat.uuid } }">{{ chat.title }} - {{ chat.assessment_for.first_name }} {{ chat.assessment_for.last_name }}</b-link>
                      <small>
                        <item-date-time :key="chat.id"
                                        :from-time="chat.created_at"
                                        :update-interval="5000" />
                      </small>
                    </div>
                  </div>
                </div>
              </VuePerfectScrollbar>
              <div v-if="!loadingRecentChats && assessments.length < 1">
                <p>You don't have any recent chats.</p>
              </div>
              <template #overlay>
                <div class="text-center">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                  <br/>
                  Fetching recent chats...
                </div>
              </template>
            </b-overlay>
          </b-tab>

          <b-tab
              title="People by location"
              v-if="authStore.can(['create-assessment'])"
          >
            <b-overlay
                rounded="sm"
                :show="loadingPeopleFromLocations"
            >
              <VuePerfectScrollbar class="scrollbar-container">
                <div class="scrollable-area">
                  <ul
                      v-if="peopleByLocation > 0"
                      class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                  >
                    <li class="list-group-item assessment-list-group-item d-flex justify-content-between"
                        v-for="user in peopleByLocation"
                        :key="user.uuid">
                      <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left mr-3">
                            <AvatarComponent :employee="user"></AvatarComponent>
                          </div>
                          <div class="widget-content-left">
                            <div class="widget-heading">{{ user.first_name }} {{ user.last_name }}</div>
                          </div>
                        </div>
                      </div>
                      <b-button variant="outline-primary"
                                class="btn-assessment-action"
                                size="sm"
                                title="Conduct a chat"
                                @click="$router.push({ name: 'new-user-assessment', params: { id: user.uuid } })">
                        Chat
                      </b-button>
                    </li>
                  </ul>
                </div>
              </VuePerfectScrollbar>
              <div v-if="!loadingPeopleFromLocations && peopleByLocation.length < 1">
                <p>You don't have anyone you can chat for this criteria.</p>
              </div>
              <template #overlay>
                <div class="text-center">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                  <br/>
                  Fetching data...
                </div>
              </template>
            </b-overlay>
          </b-tab>

          <b-tab title="People by team" v-if="authStore.can(['create-assessment'])">
            <b-overlay :show="loadingPeopleFromGroups"
                       rounded="sm">
              <VuePerfectScrollbar class="scrollbar-container">
                <div class="scrollable-area">
                  <ul
                      v-if="peopleByGroup.length > 0"
                      class="rm-list-borders rm-list-borders-scroll list-group list-group-flush"
                  >
                    <li class="list-group-item assessment-list-group-item d-flex justify-content-between"
                        v-for="user in peopleByGroup"
                        :key="user.uuid">
                      <div class="widget-content p-0">
                        <div class="widget-content-wrapper">
                          <div class="widget-content-left mr-3">
                            <AvatarComponent :employee="user"></AvatarComponent>
                          </div>
                          <div class="widget-content-left">
                            <div class="widget-heading">{{ user.first_name }} {{ user.last_name }}</div>
                          </div>
                        </div>
                      </div>
                      <b-button variant="outline-primary"
                                size="sm"
                                title="Conduct a chat"
                                class="btn-assessment-action"
                                @click="$router.push({ name: 'new-user-assessment', params: { id: user.uuid } })">
                        Chat
                      </b-button>
                    </li>
                  </ul>
                </div>
              </VuePerfectScrollbar>
              <div v-if="!loadingPeopleFromGroups && peopleByGroup.length < 1">
                <p>You don't have anyone you can chat for this criteria.</p>
              </div>
              <template #overlay>
                <div class="text-center">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                  <br/>
                  Fetching data...
                </div>
              </template>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import {useEmployeeStore} from '@/stores/employee'
import {useAssessmentStore} from '@/stores/assessment'
import {useAuthStore} from '@/stores/auth'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import ItemDateTime from '@/components/item-date-time'
import AvatarComponent from '@/components/employee/AvatarComponent'

export default {
  name: 'AssessmentWidget',

  components: {
    ItemDateTime,
    AvatarComponent,
    VuePerfectScrollbar
  },

  props: {
    employee: {
      type: Object,
      required: true
    }
  },

  setup() {
    const employeeStore = useEmployeeStore()
    const assessmentStore = useAssessmentStore()
    const authStore = useAuthStore()
    return {
      employeeStore,
      assessmentStore,
      authStore
    }
  },

  computed: {
    assessmentsTo () {
      return this.assessments.filter(item => item.from === this.employee.uuid)
    },
    assessmentsFrom () {
      return this.assessments.filter(item => item.to === this.employee.uuid)
    },
    peopleByLocation () {
      return this.people.location.filter(item => item.uuid !== this.authStore.user.uuid)
    },
    peopleByGroup () {
      return this.people.group.filter(item => item.uuid !== this.authStore.user.uuid)
    },
    hasNoAssessmentIn90Days () {

      if (!this.authStore.can(['create-assessment'])) {
        return false
      }

      if (!this.loadingRecentChats && this.assessmentsTo.length > 0) {
        const date = new Date(this.assessmentsTo[0].created_at)
        const now = new Date()
        const diffTime = Math.abs(now - date)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        return diffDays >= 90
      }

      return !this.loadingRecentChats && this.assessmentsTo.length < 1
    }
  },

  data() {
    return {
      tabIndex: 0,
      openedTabIndex: [],
      activeTab: 'recent',
      loadingRecentChats: false,
      loadingPeopleFromGroups: false,
      loadingPeopleFromLocations: false,
      assessments: [],
      people: {
        group: [],
        location: []
      }
    }
  },

  watch: {
    tabIndex (value) {
      switch (true) {
        case value === 1 && this.authStore.can(['create-assessment']):
          if (!this.openedTabIndex.includes(value)) {
            this.getPeopleByLocation()
            this.openedTabIndex.push(value)
          }
          break;
        case value === 2 && this.authStore.can(['create-assessment']):
          if (!this.openedTabIndex.includes(value)) {
            this.getPeopleByGroup()
            this.openedTabIndex.push(value)
          }
        break;
      }
    }
  },

  methods: {
    fetchAssessments() {
      this.loadingRecentChats = true
      this.employeeStore.getAssessments(this.employee.uuid).then(res => {
        this.assessments = res.data
        this.loadingRecentChats = false
      })
    },
    getPeopleByLocation(){
      this.loadingPeopleFromLocations = true
      this.assessmentStore.getAssessmentableByLocation(this.employee.uuid).then(res => {
        this.people.location = res.data
        this.loadingPeopleFromLocations = false
      })
    },
    getPeopleByGroup(){
      this.loadingPeopleFromGroups = true
      this.assessmentStore.getAssessmentableByGroup(this.employee.uuid).then(res => {
        this.people.group = res.data
        this.loadingPeopleFromGroups = false
      })
    }
  },

  mounted() {
    if (this.assessments.length < 1) {
      this.fetchAssessments()
    }
  }
}
</script>

<style lang="scss" scoped>
  .scrollable-area {
    height: auto;
    max-height: 345px;
    min-height: 40px;
  }

  .assessment-list-group-item {
    .btn-assessment-action {
      opacity: 0.5;
    }

    &:hover {
      .btn-assessment-action {
        opacity: 1;
      }
    }
  }


</style>
