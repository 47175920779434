<template>
  <div>
    <VueMultiselect track-by="id"
                    label="name"
                    class="group-multiselect"
                    placeholder="Select assessment level"
                    selected-label=""
                    select-label=""
                    deselect-label=""
                    v-model="assessment"
                    :preselectFirst="preselectFirst"
                    :group-select="false"
                    :allowEmpty="false"
                    :options="store.assessments"
                    :loading="store.isLoadingAssessments"
                    :disabled="store.isLoadingAssessments || disabled"
                    @select="onSelect">
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
          <br v-if="showDescription"/>
          <p v-if="showDescription" class="option__small">{{ props.option.description }}</p>
        </div>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'
import {useSkillsStore} from '@/stores/skills'

export default {
  name: 'skill-assessment-selector',
  components: { VueMultiselect },

  props:{
    value: {
      type: [Object, null]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    autoFetch: {
      type: Boolean,
      default: true
    },
    preselectFirst: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
  },

  setup () {
    const store = useSkillsStore()

    return {
      store
    }
  },

  data () {
    return {
      assessment: this.value,
    }
  },
  methods: {
    onSelect (data) {
      this.$emit('select', data)
    }
  },
  mounted() {
    console.log('hello')
    if (this.store.assessments.length < 1) {
      this.store.getSkillAssessments().then(() => {
        //this.assessment = this.store.assessments[0]
      })
    }

    // if (this.store.assessments.length > 0 && !this.value) {
    //   this.assessment = this.store.assessments[0]
    // }
  },
  watch: {
    value: {
      deep: true,
      handler: function(value){
        this.assessment = value
      }
    },
    'store.assessments': {
      deep: true,
      handler: function(){
        if (!this.value) {
          //this.assessment = this.store.assessments[0]
        }
      }
    }
  }
}
</script>

<style scoped>
  p.option__small {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 0;
    word-break: break-word;
    width: 225px;
    white-space: initial;
  }

  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
</style>

