<template>
  <div>
    <vue-anka-cropper
        :options="options"
        @cropper-saved="onSave($event)"
        @cropper-file-selected="onSelected($event)"
        @cropper-cancelled="onCancel">
    </vue-anka-cropper>
  </div>
</template>

<script>
import vueAnkaCropper from 'vue-anka-cropper'

export default {
  name: 'image-crop-uploader',

  components: {
    vueAnkaCropper
  },

  props: {
    closeOnSave: {
      type: Boolean,
      default: true
    },
    showPreview: {
      type: Boolean,
      default: true
    },
    cropArea: {
      type: String,
      default: 'box'
    },
    croppedHeight: {
      type: Number,
      default: 400
    },
    croppedWidth: {
      type: Number,
      default: 400
    },
    cropperHeight: {
      type: [Number, Boolean],
      default: 500
    },
    aspectRatio: {
      type: [Number, Boolean],
      default: 1
    },
  },

  data() {
    return {
      imgSrc: null,
      options: {
        aspectRatio: this.aspectRatio,
        closeOnSave: this.closeOnSave,
        cropArea: this.cropArea,
        croppedHeight: this.croppedHeight,
        croppedWidth: this.croppedWidth,
        cropperHeight: this.cropperHeight,
        dropareaMessage: 'Drop file here or use the button below.',
        frameLineDash: [5,3],
        frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
        handleFillColor: 'rgba(255, 255, 255, 0.2)',
        handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
        handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
        handleSize: 10,
        handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
        layoutBreakpoint: 850,
        maxCropperHeight: 768,
        maxFileSize: 5000000,
        overlayFill: 'rgba(0, 0, 0, 0.5)',
        previewOnDrag: true,
        previewQuality: 0.65,
        resultQuality: 0.8,
        resultMimeType: 'image/jpeg',
        selectButtonLabel: 'Select image',
        showPreview: this.showPreview,
        skin: 'light',
        uploadData: {},
        uploadTo: false
      }
    }
  },

  methods: {
    onSave(event) {
      this.$emit('fileAdded', event.croppedFile)
    },
    onSelected(event) {
      this.$emit('fileSelected', event)
    },
    onCancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style>
.ankaCropper {
  background:#e3eaf0;
  border-radius:3px;
  -webkit-box-shadow:0 0 20px rgba(0,0,0,.1),3px 3px 5px rgba(0,0,0,.3);
  box-shadow:0 0 20px rgba(0,0,0,.1),3px 3px 5px rgba(0,0,0,.3);
  position:relative;
  overflow:hidden;
}

.ankaCropper a { text-decoration:none }
.ankaCropper__droparea {
  text-align:center;
  margin:2em;
  padding:2em
}

.ankaCropper__selectButton{
  border:none;
  padding:10px 20px;
  margin:10px;
  border-radius:3px;
  cursor:pointer;
  -webkit-transition:all .3s;
  transition:all .3s
}

.ankaCropper__navigation{
  padding:12px;
  border-radius:3px;
  -webkit-box-shadow:0 2px 3px rgba(0,0,0,.15);
  box-shadow:0 2px 3px rgba(0,0,0,.15)
}

.ankaCropper__navButton{
  padding:8px;
  width:32px;
  margin-right:10px
}

.ankaCropper__navButton,.ankaCropper__saveButton{
  border-radius:3px;
  display:inline-block;
  height:38px;
  -webkit-box-shadow:0 0 4px rgba(0,0,0,.15),1px 1px 2px rgba(0,0,0,.25);
  box-shadow:0 0 4px rgba(0,0,0,.15),1px 1px 2px rgba(0,0,0,.25)
}

.ankaCropper__saveButton{
  float:right;
  padding:8px 20px;
  font-size:14px
}
.ankaCropper__saveButton svg{
  vertical-align:bottom
}
.ankaCropper__mainArea{
  margin:12px;
  -webkit-box-sizing:border-box;
  box-sizing:border-box;
  overflow:hidden;
  text-align:center
}
.ankaCropper__previewArea{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center;
  -webkit-box-align:center;
  -ms-flex-align:center;align-items:center
}
.ankaCropper.light{
  background:#f7f7f7;
  color:#0f1114
}
.ankaCropper.light .ankaCropper__droparea{
  border:2px dashed #367bb7
}

.ankaCropper.light .ankaCropper__saveButton,.ankaCropper.light .ankaCropper__selectButton {
  background:#1c6bd6;
  color:#fff
}
.ankaCropper.light .ankaCropper__saveButton:hover,.ankaCropper.light .ankaCropper__selectButton:hover{
  background:#1b5bb2
}

.ankaCropper.light .ankaCropper__navigation{
  background:#e8f2fa
}
.ankaCropper.light .ankaCropper__navButton svg{
  stroke:#0f1114
}

.ankaCropper.light .ankaCropper__navButton:hover {
  background:#fff

}.ankaCropper.dark{
   background:#0f1114;
   color:#eee
 }

.ankaCropper.dark .ankaCropper__droparea{
  border:2px dashed #3e424b
}

.ankaCropper.dark .ankaCropper__saveButton,.ankaCropper.dark .ankaCropper__selectButton{
  background:#334f90;
  color:#fff

}

.ankaCropper.dark .ankaCropper__saveButton:hover,.ankaCropper.dark .ankaCropper__selectButton:hover{
  background:#335dbe
}

.ankaCropper.dark .ankaCropper__navigation{
  background:#1d2227
}

.ankaCropper.dark .ankaCropper__navButton{
  background:#272c31
}

.ankaCropper.dark .ankaCropper__navButton svg{
  stroke:#eee
}

.ankaCropper.dark .ankaCropper__navButton:hover{
  background:#000
}
</style>
