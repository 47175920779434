<template>
  <b-card img-height="250"
          img-width="250"
          img-alt="Image"
          img-top
          tag="article"
          class="mb-2 goals-board-card"
          :title="showDetails && goal.user ? goal.user.first_name + ' '+ goal.user.last_name : ''"
          :img-src="goal.thumbUrl"
          @click="onSelect">
    <b-overlay :show="loading"
               rounded="sm">
      <div>
        <div class="avatar-icon goals-board">
          <AvatarComponent :employee="goal.user"></AvatarComponent>
        </div>

        <b-card-text class="font-italic">
          <font-awesome-icon icon="quote-left"/> {{ goal.caption }} <font-awesome-icon icon="quote-right"/>
        </b-card-text>

        <b-button v-if="showLike"
                  href="#"
                  size="sm"
                  variant="btn-link outline-primary"
                  class="d-inline-flex"
                  @click="onLike">
          <span>1</span> <i class="pe-7s-like fsize-2 ml-2"></i>
        </b-button>
        <div v-if="showActionButtons">
          <b-button href="#"
                    size="sm"
                    variant="btn-link outline-primary"
                    class="d-inline-flex"
                    @click="onEdit(goal, $event)">
            <i class="pe-7s-pen fsize-2 mr-2"></i> Edit
          </b-button>

          <b-button href="#"
                    size="sm"
                    variant="btn-link outline-primary"
                    class="d-inline-flex"
                    @click="onDelete(goal.id, $event)">
            <i class="pe-7s-trash fsize-2 mr-2"></i> Remove
          </b-button>
        </div>
      </div>
      <template #overlay>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import {ProfilePhotoId} from '@/constants/user-metadata'
import Avatar from '@/assets/images/avatars/default.png'
import {tenant} from '@/api/v1'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import AvatarComponent from '@/components/employee/AvatarComponent'

export default {
  name: 'card-component',

  components: {
    FontAwesomeIcon,
    AvatarComponent
  },

  props: {
    goal: {
      type: Object,
      required: true
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    showLike: {
      type: Boolean,
      default: true
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    avatar(){
      if (this.goal.user && this.goal.user.metadata.length > 0) {
        const avatarData = this.goal.user.metadata.find(item => item.metadata_id === ProfilePhotoId)

        if (avatarData) {
          return avatarData.values
        }
      }
      return Avatar
    }
  },

  data(){
    return {
      loading: false
    }
  },

  methods: {
    onSelect() {
      this.$emit('select', this.goal.uuid)
    },
    onEdit (goal, e){
      e.stopImmediatePropagation()
      this.$emit('edit', goal)
    },
    async onDelete (goal, e){
      e.stopImmediatePropagation()
      const confirm = await this.$dialog.confirm({ text: 'Do you wish to remove this goal?', actions: ['No', 'Yes'] })
      if (confirm === 'Yes') {
        this.loading = true
        tenant.team.goals.delete(this.goal.uuid).then(res => {
          if (res.data.status === 'success') {
            this.$emit('delete', this.goal)
            this.loading = false
          }
        }).then(() => {
          this.loading = false
        })
      }
    },
    onLike(e) {
      e.stopImmediatePropagation()
    }
  }
}
</script>
